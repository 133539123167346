import React, {useEffect, useState} from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {dateToYYYYMMDD,	fadingMessage, getTimeFormat, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import Modal from "../../../../../globals/components/Modal"
import MobileInputDate from "../../../../shared/input/text/MobileInputDate"
import MobileTimeField from "../../../calendar/MobileTimeField"
import MobileInputText from "../../../../shared/input/text/MobileInputText"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
import CloseIncompleteOrderWithoutCapture from "../buttons/CloseIncompleteOrderWithoutCapture"
import RequestFullPayment from "../buttons/RequestFullPayment"
import RequestPartialPayment from "../buttons/RequestPartialPayment"
import MobileOrderAdjustPriceCard from "../../MobileOrderAdjustPriceCard"
import MobileInputPercent from "../../../../shared/input/text/MobileInputPercent"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {string} props.order.dueDate
 * @param {{date: string, time: string}} props.dueDate
 * @param {Account} props.account
 * @param {boolean} props.showIncompleteOrderModal
 * @param {function} props.setShowIncompleteOrderModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const IncompleteOrderModal = props => {
	// TODO: incomplete & cancel
	// TODO: incomplete & capture full funds (send request to customer, customer must accept before capture)
	// TODO: incomplete & capture partial funds (send request to customer, customer must accept before capture)
	/* TODO: explanation
		*	Orders which have not been completed must be marked incomplete. Doing otherwise could be interpreted as fraud.
		* Once an order if marked as incomplete, the provider is given the following options:
		* 	1. close the order without payment
		* 	2. request full payment
		* 	3. request partial payment, for a quantity specified by the provider
		* When payment is requested on an order marked as incomplete, the customer must accept the request before
		* funds are captured.
	 */
	const [showPriceAdjustModal, setShowPriceAdjustModal] = useState(false)
	const [disableButtons, setDisableButtons] = useState(false)
	const [percent, setPercent] = useState(0)
	const [newAmount, setNewAmount] = useState(props.order.charges.total.items - props.order.charges.items.reduce((sum, i) => sum + i.fee), 0)
	useEffect(() => {
		const originalAmount = props.order.charges.total.items - props.order.charges.items.reduce((sum, i) => sum + i.fee, 0)
		console.log("originalAmount", originalAmount)
		setNewAmount(Math.round(originalAmount*(100-percent)/100))
		console.log("Math.round(originalAmount*(100-percent)/100)", Math.round(originalAmount*(100-percent)/100))
	}, [percent, props.order.charges])
	console.log("newAmount", newAmount)
	console.log("newAmount", newAmount)
	console.log("props.order.charges.total.items", props.order.charges.total.items)
	console.log("props.order.charges.items", props.order.charges.items)
	console.log("props.order.charges.items.reduce((sum, i) => sum + i.fee, 0)", props.order.charges.items.reduce((sum, i) => {return sum + i.fee}), 0)
	console.log("percent", percent)
	const order = props.order
	const onClick = async () => {
		try {
			const confirmed = window.confirm(translate(DICTIONARY.CONFIRM_REQUEST_PARTIAL_PAYMENT.X))
			if (confirmed) {
				setDisableButtons(true)
				// const message = document.getElementById(`order-${order.id}-partial-payment-reason-input`).value
				// const note = document.getElementById(`order-${order.id}-partial-payment-reason-input`).value
				const response = await editOrder(order.id, ORDER_BUTTON.INCOMPLETE_AND_REQUEST_PARTIAL_PAYMENT,
					{
						amount: newAmount,
						// message: message,
						// note: note
					})
				// console.log(response)
				setDisableButtons(false)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refreshOrders()
					props.setShowIncompleteOrderModal(false)
					setShowPriceAdjustModal(false)
					fadingMessage("propose-partial-payment-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	// <MobileFontAwesome id={"due-date"} icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.NEXT_DUE_DATE.X)}
	// 							style={{paddingLeft: "1vh"}} onClick={() => newMessage("nextDueDate",
	// 							translate(DICTIONARY.NEXT_DUE_DATE.X), translate(DICTIONARY.DUE_DATE_MESSAGE.X),
	// 							"w3-pale-blue", ICONS.INFO_CIRCLE)}/>
	const priceAdjustModal = !showPriceAdjustModal ? <></> :
		<Modal id={`order-${order.id}-partial-payment-modal`} padding={"0vh"} icon={ICONS.BALLOT_CHECK}
			title={translate(DICTIONARY.REQUEST_PARTIAL_PAYMENT.X)}
			onClick={() => setShowPriceAdjustModal(false)}
			body={
			// TODO: note to self
			// TODO: note to customer
				<div style={{padding: "2vh"}}>
					<div className={"paralian-theme-level-2"} style={{padding: "2vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
						<MobileInputPercent id={`order-${order.id}-partial-payment-percent-discount`} autoFocus={true}
							label={translate(DICTIONARY.PERCENT_DISCOUNT.X)} form={"incomplete-order-modal"}
							defaultValue={percent} showLabel={true} className={"paralian-theme-level-1"}
							name={"incomplete-order-modal-percent-discount"} required={true}
							placeholder={translate(DICTIONARY.PERCENT_DISCOUNT.X)} style={{borderRadius: "1vh"}}
							onChange={v => setPercent(v)} bottomRadius={"1vh"} topRadius={"1vh"}/>
						<div style={{padding: "2vh 0vh 1vh 0vh"}}>
							<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
								{translate(DICTIONARY.PAYMENT_REQUEST_AMOUNT_EXCL_TAXES_PARALIAN_FEE.X)}
							</div>
							<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
								{props.currency.asText(newAmount)}
							</div>
						</div>
					</div>
					{/*<div className={"paralian-theme-level-2"} style={{padding: "2vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
						<MobileInputText id={`order-${order.id}-partial-payment-reason`} label={translate(DICTIONARY.EXPLANATION.X)}
							pattern={"([a-zA-ZÀ-ž0-9-_\.]){2,128}"} showLabel={true}
							className={"paralian-theme-level-1"}
							required={true} placeholder={translate(DICTIONARY.EXPLANATION.X)}
							style={{borderRadius: "1vh"}}/>
					</div>*/}
					<MobileButtonGeneric id={"submit-request-partial-payment"} type={"button"} padding={"1vh"}
						className={"paralian-theme-level-1-flipped"} style={{width: "100%", borderRadius: "1vh"}}
						text={{content: translate(DICTIONARY.SUBMIT.X)}} onClick={onClick} disabled={disableButtons}/>
				</div>
			}/>
	return props.showIncompleteOrderModal ?
		<Modal id={`order-${order.id}-incomplete-modal`} padding={"0vh"} icon={ICONS.CALENDAR_ALT}
			title={translate(DICTIONARY.MARK_INCOMPLETE.X)}
			onClick={() => props.setShowIncompleteOrderModal(false)}
			body={
				<div style={{padding: "2vh"}}>
					<div className={"paralian-theme-level-2"} style={{padding: "1vh",	margin: "1vh", borderRadius: "1vh"}}>
						<CloseIncompleteOrderWithoutCapture order={order}	refreshOrders={props.refreshOrders}
							disabled={disableButtons}/>
						<RequestPartialPayment order={order} refreshOrders={props.refreshOrders}
							setShowPriceAdjustModal={setShowPriceAdjustModal} disabled={disableButtons}/>
						<RequestFullPayment order={order}	refreshOrders={props.refreshOrders} disabled={disableButtons}/>
						{priceAdjustModal}
					</div>
				</div>}/> : <></>
	}
export default IncompleteOrderModal