import React, {useState} from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.cancel
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const CloseIncompleteOrderWithoutCapture = props => {
	const [disableButtons, setDisableButtons] = useState(false)
	return props.order.buttons.incomplete ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"cancel"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.DO_NOT_CHARGE_CUSTOMER.X)}}	left={{icon: ICONS.GIFT}}
				 className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}} disabled={disableButtons}
				 onClick={async () => {
					 try {
						 const confirmed = window.confirm(translate(DICTIONARY.CONFIRM_DO_NOT_CHARGE_CUSTOMER.X))
						 if (confirmed) {
							 setDisableButtons(true)
							 const cancelRecurrenceResponse = await editOrder(props.order.id, ORDER_BUTTON.INCOMPLETE_AND_CANCEL)
							 // console.log(response)
							 setDisableButtons(false)
							 if (cancelRecurrenceResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
								 props.refreshOrders()
								 fadingMessage("cancel-recurrence-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
							 }
						 }
					 } catch (e) {
						 console.log(e)
						 setDisableButtons(false)
					 }
					 // console.log("cancel")
				 }}/>
		</div> : <></>
}

export default CloseIncompleteOrderWithoutCapture