import React, {useEffect} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileConnectNewAccount from "../../../main/MobileConnectNewAccount"

const MobileLoginOrgCreateStripeAccount = props => {
	useEffect(() => {
		if (props.message) {
			newMessage("org-create-stripe-account", translate(DICTIONARY.ORGANIZATION_STEP_4_HEADER.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}, [props.message])
	const content =	<MobileConnectNewAccount id={"connect-new"} text={translate(DICTIONARY.CONNECT_WITH_STRIPE.X)}
		className={"paralian-theme-level-1-flipped"} style={{padding: "2.5vh", border: "none", borderRadius: "1vh"}}/>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back}/>
}

export default MobileLoginOrgCreateStripeAccount