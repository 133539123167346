import React, {useEffect, useState} from "react"
import DICTIONARY from "../../../globals/constants/shared/dictionary"
import {CardElement} from "@stripe/react-stripe-js"
import {setToStorage, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileBillingAddressDropdown from "./MobileBillingAddressDropdown"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputAddressFields from "../address/MobileInputAddressFields"
import Address from "../../../globals/classes/shared/Address"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.id} props.id
 * @param {Account|Organization} props.parentObject
 * @param {React.HTMLAttributes.className} props.className
 * @param {function} props.setError
 * @param {function} props.setCardComplete
 * @param {function} props.setBillingDetails
 * @param {function} props.setBillingAddress
 * @param {function} [props.setSavePaymentMethod]
 * @param {boolean} props.isOrganization
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputCreditCard = props => {
	/* TODO: update credit card font size on screen rotate */
	const [billingDetails, setBillingDetails] = useState({name: props.parentObject ? props.isOrganization ?
			props.parentObject.name.display :	props.parentObject.contact.name.given : null})
	const [billingAddress, setBillingAddress] = useState(props.parentObject ?
		props.parentObject.addresses.asArray.length > 0 ?
			props.parentObject.addresses.asArray.find(address => address.isBilling) ||  new Address({id: -1}) :
			new Address({id: -1}) : new Address({id: -1}))
	useEffect(() => {
		try {
			const ok = props.isOrganization ? props.parentObject.name.display : props.parentObject.contact.name.given
			setBillingDetails({name: ok ? ok : null})
		} catch (e) {
			setBillingDetails({name: null})
		}
	}, [props.parentObject, props.isOrganization])
	useEffect(() => {
		try {
			const ok = props.parentObject.addresses.asArray.length > 0
			setBillingAddress(ok ? props.parentObject.addresses.asArray.find(address => address.isBilling) ||
				new Address({id: -1}) : new Address({id: -1}))
		} catch (e) {
			setBillingAddress(new Address({id: -1}))
		}
	}, [props.parentObject])
	const className = props.className || "paralian-theme-level-1-flipped"
	const addressFields = billingAddress.id === -1 ?
		<div style={{margin: "2vh"}}>
			<MobileInputAddressFields id={"cc"} form={props.form || `${props.id}-form`} showLabel={false}
				autoComplete={false} showLine3={false} hidePostCode={true} className={className}
				address={billingAddress.id === -1 ? new Address({country: {code: "USA"}, state: {code: "US-PR"}}) : billingAddress}/>
		</div>  : <></>
	// console.log(billingAddress)
	const addressText = billingAddress.id !== -1 ?
		<div className={className} style={{margin: "2vh", padding: "2vh"}}>
			<div>{billingAddress.line1}</div>
			<div>{billingAddress.line2}</div>
			<div>{billingAddress.city} {billingAddress.state.name} </div>
			<div>{billingAddress.country.translatedName} {billingAddress.postCode}</div>
		</div> : <></>
	const save = props.setSavePaymentMethod ?
		<div style={{width: "100%", fontSize: "2vh", paddingLeft: "1vh"}}>
			<div className="w3-display-container" style={{height: "3vh", marginBottom: "1vh"}}>
				<MobileInputSlider id={`${props.id}-save`} label={translate(DICTIONARY.SAVE.X)}
					height={"3vh"} width={"8vh"} className={"w3-display-middle"} defaultChecked={true}
					onChange={element => {props.setSavePaymentMethod(element.getAttribute("data-is-checked") !== "true")}}
					padding={"0.25vh 0vh 0.25vh 2vh"} labelStyle={{width: "max-content"}} form={`${props.id}-form`}/>
			</div>
		</div> : <></>
	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				iconColor: "rgb(31,59,80)",
				color: "rgb(31,59,80)",
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: "antialiased",
				fontSize: `${window.screen.availHeight*0.02}px`,
				"::placeholder": {
					color: "rgb(31,59,80)",
				},
			},
			complete: {
				fontWeight: "bold"
			},
			empty: {
				color: "rgba(255,255,255,1.0)",
				iconColor: "white"
			},
			invalid: {
				color: "red",
				iconColor: "red",
			},
		},
	}
	// console.log(billingDetails)
	// console.log("form", props.form)
	return <>
		<div className={"paralian-text-blue"} style={{padding: "2vh 0vw", margin: "2vh", borderRadius: "2vh",
			backgroundColor: "rgba(255,255,255,0.98)"}}>
			{save}
			<div style={{margin: "2vh 0vw", backgroundColor: "rgba(191,191,191,0.5)", width: "100%", height: "3vh"}}> </div>
			<div style={{margin: "0vh 2vh"}}>
				<CardElement options={CARD_ELEMENT_OPTIONS} onChange={event => {
					if (event.complete) {
						// console.log(event)
						setToStorage("postCode", event.value.postalCode, "session")
					}
					props.setError(event.error)
					props.setCardComplete(event.complete)
				}} />
			</div>
			<div style={{margin: "0vh 2vh"}}>
				<MobileInputText className={""} label={translate(DICTIONARY.FULL_NAME.X)} showLabel={false}
					defaultValue={billingDetails.name ? billingDetails.name.toUpperCase() : null} required={true} autoComplete={"name"}
					autoFocus={false} form={props.form || `${props.id}-form`} bottomRadius={"1vh"} topRadius={"1vh"}
					placeholder={translate(DICTIONARY.FULL_NAME.X)}
					name={"name"} id={`${props.id}-name-on-card`} maxLength={"128"} pattern={"([a-zA-Z0-9-_\. ]){2,128}"}
					onChange={e => {
						const bd_ = {...billingDetails}
						bd_.name = e.target.value
						setBillingDetails(bd_)
						props.setBillingDetails(bd_)
					}} />
			</div>
		</div>
		<div style={{margin: "2vh"}}>
			<MobileBillingAddressDropdown id={`${props.id}-dropdown`} defaultValue={billingAddress.id}
				form={props.form || `${props.id}-form`} showLabel={true} addresses={props.parentObject ?
					props.parentObject.addresses.asArray.filter(address => address.isBilling) : []}
					className={className} onChange={address => {
						setBillingAddress(address)
						props.setBillingAddress(address)
					}}/>
		</div>
		{addressText}
		{addressFields}
	</>
}

export default MobileInputCreditCard