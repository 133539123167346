const Dictionary = {
	ITEMS: {
		X: "ITEMS",
		es: "Artículos",
		en: "Items",
		fr: "Articles"
	},
	SERVICE_TYPE: {
		X: "SERVICE_TYPE",
		es: "Tipo de Servicio",
		en: "Service Type",
		fr: "Type de Service"
	},
	ADD_VESSEL_AS_CUSTOMER_QUESTION: {
		X: "ADD_VESSEL_AS_CUSTOMER_QUESTION",
		es: "¿Le gustaría agregar esta embarcación como cliente activo?",
		en: "Would you like to add this vessel as an active customer?",
		fr: "Souhaitez-vous ajouter ce navire en tant que client actif?"
	},
	SUBSCRIPTION_FEES_MAY_APPLY: {
		X: "SUBSCRIPTION_FEES_MAY_APPLY",
		es: "Es posible que se apliquen tarifas de suscripción",
		en: "Subscription fees may apply",
		fr: "Des frais d'abonnement peuvent s'appliquer"
	},
	I_DONT_OWN_THIS_VESSEL: {
		X: "I_DONT_OWN_THIS_VESSEL",
		es: "No soy el dueño de esta embarcación",
		en: "I don't own this vessel",
		fr: "Je ne possède pas ce navire"
	},
	YES_ADD_AS_TEMPORARY_CUSTOMER: {
		X: "YES_ADD_AS_TEMPORARY_CUSTOMER",
		es: "Sí, añadir como cliente temporal",
		en: "Yes, add as a temporary customer",
		fr: "Oui, ajouter en tant que client temporaire"
	},
	YES_ADD_AS_PERMANENT_CUSTOMER: {
		X: "YES_ADD_AS_PERMANENT_CUSTOMER",
		es: "Sí, añadir como cliente permanente",
		en: "Yes, add as a permanent customer",
		fr: "Oui, ajouter en tant que client permanent"
	},
	NO_JUST_NOTIFY_THE_OWNER: {
		X: "NO_JUST_NOTIFY_THE_OWNER",
		es: "No, solo notifique al propietario",
		en: "No, just notify the owner",
		fr: "Non, informez simplement le propriétaire"
	},
	OWNER_CONTACT_INFO: {
		X: "OWNER_CONTACT_INFO",
		es: "Información de Contacto del Propietario",
		en: "Owner Contact Info",
		fr: "Coordonnées du Propriétaire"
	},
	ADDITIONAL_INFO: {
		X: "ADDITIONAL_INFO",
		es: "Información Adicional",
		en: "Additional Info",
		fr: "Information Additionnelle"
	},
	INVITE_CUSTOMER_ADDITONAL_INFO_MESSAGE: {
		X: "INVITE_CUSTOMER_ADDITONAL_INFO_MESSAGE",
		es: "Antes de crear un nuevo perfil de embarcación, necesitamos saber si la embarcación le pertenece " +
			"a una organización, el nombre del propietario/organización y si desea agregar esta embarcación como " +
			"cliente o simplemente enviar una invitación al propietario para agregar la embarcación a su perfil.",
		en: "Before creating a new vessel profile, we need you to know if the vessel is owned by an organization, " +
			"the name of the owner/organization and if you would like to add this vessel as a customer or simply send " +
			"the owner an invitation to add the vessel to their profile.",
		fr: "Avant de créer un nouveau profil de navire, nous avons besoin que vous sachiez si le navire appartient " +
			"à une organisation, le nom du propriétaire/organisation et si vous souhaitez ajouter ce navire en tant que " +
			"client ou simplement envoyer au propriétaire une invitation à ajouter le navire à leur profil."
	},
	ADD_AS_PERMANENT_CUSTOMER: {
		X: "ADD_AS_PERMANENT_CUSTOMER",
		es: "Agregar como Cliente Temporal",
		en: "Add as Permanent Customer",
		fr: "Ajouter en tant que Client Temporaire"
	},
	ADD_AS_TEMPORARY_CUSTOMER: {
		X: "ADD_AS_TEMPORARY_CUSTOMER",
		es: "No soy el dueño de esta embarcación.",
		en: "Add as Temporary Customer",
		fr: "Je ne possède pas ce vaisseau."
	},
	SEND_INVITE_ONLY: {
		X: "SEND_INVITE_ONLY",
		es: "Enviar Invitación Únicamente",
		en: "Send Invite Only",
		fr: "Envoyer Invitation Uniquement"
	},
	THIS_VESSEL_OWNED_BY_ORGANIZATION: {
		X: "THIS_VESSEL_OWNED_BY_ORGANIZATION",
		es: "Esta embarcación le pertence a una organización",
		en: "This vessel is owned by an organization",
		fr: "Ce navire appartient à une organisation"
	},
	ORGANIZATION_NAME: {
		X: "ORGANIZATION_NAME",
		es: "Nobre de la Organización",
		en: "Organization Name",
		fr: "Nom de l'Organisation"
	},
	OWNERS_NAME: {
		X: "OWNERS_NAME",
		es: "Nombre del Propietario",
		en: "Owner's Name",
		fr: "Nom du propriétaire"
	},
	SELECT_VESSELS: {
		X: "SELECT_VESSELS",
		es: "Selección de Embarcaciones",
		en: "Select Vessels",
		fr: "Sélectionnez les navires"
	},
	CUSTOMER_INVITE_SELECT_VESSELS_MESSAGE: {
		X: "SELECT_VESSELS",
		es: "Seleccione las embarcaciones que desea agregar. Si la embarcación que desea agregar no aparece en la " +
			"lista, puede continuar sin seleccionar una para luego crear un nuevo perfil de embarcación o regresar para " +
			"volver a ingresar la información.",
		en: "Please select the vessels you wish to add. If the vessel you wish to add is not listed, you may continue" +
			" without selecting any to create a new vessel profile or go back to re-enter the information.",
		fr: "Veuillez sélectionner les navires que vous souhaitez ajouter. Si le navire que vous souhaitez ajouter " +
			"n'est pas répertorié, vous pouvez continuer sans en sélectionner pour créer un nouveau profil de navire " +
			"ou revenir en arrière pour saisir à nouveau les informations."
	},
	VESSEL_IS_ACTIVE_CUSTOMER_MESSAGE: {
		X: "VESSEL_IS_ACTIVE_CUSTOMER_MESSAGE",
		es: "Esta embarcación ya figura como cliente activo de esta organización.",
		en: "This vessel is already listed as an active customer for this organization.",
		fr: "Ce navire est déjà répertorié comme client actif pour cette organisation."
	},
	ACTIVE_CUSTOMER: {
		X: "ACTIVE_CUSTOMER",
		es: "Cliente Activo",
		en: "Active Customer",
		fr: "Client Activ"
	},
	VESSEL_INVITE_NO_RECORDS_FOUND_MESSAGE: {
		X: "VESSEL_INVITE_NO_RECORDS_FOUND_MESSAGE",
		es: "No se han encontrado registros de embarcaciones que coincidan con la información proporcionada. " +
			"Continúe creando un nuevo perfil de embarcación o regrese para volver a ingresar la información.",
		en: "No vessel records have been found matching the information provided. Continue to create a new vessel " +
			"profile or go back to re-enter the information.",
		fr: "Je ne possède pas ce vaisseau."
	},
	VESSEL_INVITE_IDENTIFIERS_MESSAGE: {
		X: "VESSEL_INVITE_IDENTIFIERS_MESSAGE",
		es: "Verifiquemos si Paralian ya tiene un registro de la embarcación. Introduzca al menos un identificador.",
		en: "Let's check if Paralian already has a record of the vessel. Please enter at least one identifier.",
		fr: "Vérifions si Paralian a déjà une trace du vaisseau. Veuillez entrer au moins un identifiant."
	},
	CONFIRM_OWNERSHIP_PROMPT: {
		X: "CONFIRM_OWNERSHIP_PROMPT",
		es: "Presiona OK para confirmar titularidad",
		en: "Press OK to confirm ownership",
		fr: "Appuyez sur OK pour confirmer la propriété"
	},
	CONFIRM_OWNERSHIP: {
		X: "CONFIRM_OWNERSHIP",
		es: "Confirmar Titularidad",
		en: "Confirm Ownership",
		fr: "Confirmer la Propriété."
	},
	DECLINE_OWNERSHIP_PROMPT: {
		X: "DECLINE_OWNERSHIP_PROMPT",
		es: "Presiona OK para rechazar la titularidad",
		en: "Press OK to decline ownership",
		fr: "Appuyez sur OK pour refuser la propriété"
	},
	DECLINE_OWNERSHIP: {
		X: "DECLINE_OWNERSHIP",
		es: "Rechazar Titularidad",
		en: "Decline Ownership",
		fr: "Refuser la Propriété"
	},
	CHANGE: {
		X: "CHANGE",
		es: "Cambiar",
		en: "Change",
		fr: "Modifier"
	},
	NONE_AVAILABLE: {
		X: "NONE_AVAILABLE",
		es: "Ninguno Disponible",
		en: "None Available",
		fr: "Aucun disponible"
	},
	PRESS_ADD_NEW_ABOVE: {
		X: "PRESS_ADD_NEW_ABOVE",
		es: "Presione el botón \"Agregar Nuevo\" arriba",
		en: "Press the \"Add New\" button above",
		fr: "Appuyez sur le bouton \"Ajouter Nouveau\" ci-dessus"
	},
	VESSEL_PROVISIONING: {
		X: "VESSEL_PROVISIONING",
		es: "Aprovisionamiento de Embarcaciones",
		en: "Vessel Provisioning",
		fr: "Avitaillement des Navires"
	},
	VESSEL_PROVISIONING_SEND_MAIL_PROMPT: {
		X: "VESSEL_PROVISIONING_SEND_MAIL_PROMPT",
		es: "¡Hola! Esta marina aún no se ha registrado para ofrecer servicios de aprovisionamiento " +
			"con Paralian. ¡Cuéntales sobre Paralian y tráelos a bordo!",
		en: "Hi! This marina is has not yet signed up to offer provisioning services " +
			"with Paralian. Tell them about Paralian and bring them onboard!",
		fr: "Salut! Cette marina n'a pas encore signé pour offrir des services d'approvionnement " +
			"avec Paralian. Parlez-leur de Paralian et amenez-les à bord!"
	},
	VESSEL_PROVISIONING_SEND_MAIL_SUBJECT: {
		X: "VESSEL_PROVISIONING_SEND_MAIL_SUBJECT",
		es: "Me gustaría usar Paralian para ordenar de ustedes artículos como hielo y otras provisiones.",
		en: "I'd like to use Paralian to order items such as ice and other provisions from you.",
		fr: "J'aimerais utiliser Paralian pour commander des articles tels que de la glace et d'autres " +
			"provisions auprès de vous."
	},
	VESSEL_PROVISIONING_SEND_MAIL_MESSAGE: {
		X: "VESSEL_PROVISIONING_SEND_MAIL_MESSAGE",
		es: "¡Hola!\n\nUso la aplicación de Paralian para realizar pedidos con proveedores de servicios que " +
			"atienden a la comunidad náutica recreativa. Después de intentar realizar un pedido de aprovisionamiento  " +
			"con ustedes, noté que esto no era posible porque aún no ofrecen este servicio a través de Paralian. " +
			"Hacer pedidos con Paralian es rápido y conveniente. Visite https://www.paralian.io o " +
			"contáctalos en mailto:hello@paralian.io\n\nSaludos,\n\n",
		en: "Hi!\n\nI use Paralian's app to place orders with service providers who cater to " +
			"the recreational boating community. After attempting to place a provisioning order with you, " +
			"I noticed this was not possible because you do not yet offer this service through Paralian. " +
			"Placing orders with Paralian is fast and convenient. Check them out at https://www.paralian.io or " +
			"contact them at mailto:hello@paralian.io\n\nRegards,\n\n",
		fr: "Bonjour!\n\nJ'utilise l'application de Paralian pour passer des commandes auprès de fournisseurs " +
			"de services qui s'adressent à la communauté de la navigation de plaisance. Après avoir tenté de passer " +
			"une commande d'approvionnement auprès de vous, j'ai remarqué que ce n'était pas possible car vous n'offrez " +
			"pas encore ce service via Paralian. Passer des commandes avec Paralian est rapide et pratique. Consultez-les " +
			"sur https://www.paralian.io ou contactez-les à l'adresse mailto:hello@paralian.io\n\nCordialement,\n\n"
	},
	REJECT_ORDER_PROMPT: {
		X: "REJECT_ORDER_PROMPT",
		es: "Presiona OK para rechazar este pedido",
		en: "Press OK to reject this order",
		fr: "Appuyez sur OK pour rejeter cette commande"
	},
	SHUTTLE_SEND_MAIL_PROMPT: {
		X: "SHUTTLE_SEND_MAIL_PROMPT",
		es: "¡Hola! Esta marina aún no se ha registrado para ofrecer servicios de transporte \"shuttle\" " +
			"con Paralian. ¡Cuéntales sobre Paralian y tráelos a bordo!",
		en: "Hi! This marina is has not yet signed up to offer shuttle services " +
			"with Paralian. Tell them about Paralian and bring them onboard!",
		fr: "Salut! Cette marina n'a pas encore signé pour offrir des services de navette " +
			"avec Paralian. Parlez-leur de Paralian et amenez-les à bord!"
	},
	SHUTTLE_SEND_MAIL_SUBJECT: {
		X: "SHUTTLE_SEND_MAIL_SUBJECT",
		es: "Me gustaría usar Paralian para realizar pedidos de servicio de transporte \"shuttle\" con usted",
		en: "I'd like to use Paralian to place shuttle service orders with you",
		fr: "J'aimerais utiliser Paralian pour passer des commandes de service de navette avec vous"
	},
	SHUTTLE_SEND_MAIL_MESSAGE: {
		X: "SHUTTLE_SEND_MAIL_MESSAGE",
		es: "¡Hola!\n\nUso la aplicación de Paralian para realizar pedidos con proveedores de servicios que " +
			"atienden a la comunidad náutica recreativa. Después de intentar realizar un pedido de transporte \"shuttle\" " +
			"con ustedes, noté que esto no era posible porque aún no ofrecen este servicio a través de Paralian. " +
			"Hacer pedidos con Paralian es rápido y conveniente. Visite https://www.paralian.io o " +
			"contáctalos en mailto:hello@paralian.io\n\nSaludos,\n\n",
		en: "Hi!\n\nI use Paralian's app to place orders with service providers who cater to " +
			"the recreational boating community. After attempting to place a shuttle service order with you, I noticed " +
			"this was not possible because you do not yet offer this service through Paralian. Placing orders with " +
			"Paralian is fast and convenient. Check them out at https://www.paralian.io or contact them at " +
			"mailto:hello@paralian.io\n\nRegards,\n\n",
		fr: "Bonjour!\n\nJ'utilise l'application de Paralian pour passer des commandes auprès de fournisseurs " +
			"de services qui s'adressent à la communauté de la navigation de plaisance. Après avoir tenté de passer " +
			"une commande de service de navette auprès de vous, j'ai remarqué que ce n'était pas possible car vous n'offrez " +
			"pas encore ce service via Paralian. Passer des commandes avec Paralian est rapide et pratique. Consultez-les " +
			"sur https://www.paralian.io ou contactez-les à l'adresse mailto:hello@paralian.io\n\nCordialement,\n\n"
	},
	START_DATE_MESSAGE: {
		X: "START_DATE_MESSAGE",
		es: "Fecha y hora más tempranas en las que la embarcación estará disponible para realizar el trabajo " +
			"y/o entregar los bienes.",
		en: "Earliest date and time at which the vessel will be available for work to be conducted and/or " +
			"goods to be delivered.",
		fr: "Date et heure les plus rapprochées auxquelles le navire sera disponible pour les travaux à " +
			"effectuer et/ou les marchandises à livrer."
	},
	START_DATE_ERROR: {
		X: "START_DATE_ERROR",
		es: "La fecha de inicio debe ser anterior a la fecha de vencimiento",
		en: "Start date must precede due date",
		fr: "La date de début doit précéder la date d'échéance"
	},
	VESSEL_STORAGE_SEND_MAIL_PROMPT: {
		X: "VESSEL_STORAGE_SEND_MAIL_PROMPT",
		es: "¡Hola! Esta marina aún no se ha registrado para ofrecer servicios de reserva de almacenamiento de " +
			"embarcaciones con Paralian. ¡Cuéntales sobre Paralian y tráelos a bordo!",
		en: "Hi! This marina is has not yet signed up to offer vessel storage reservation " +
			"services with Paralian. Tell them about Paralian and bring them onboard!",
		fr: "Salut! Cette marina n'est pas encore inscrite pour offrir des services de réservation de stockage de " +
			"navires avec Paralian. Parlez-leur de Paralian et amenez-les à bord!"
	},
	VESSEL_STORAGE_SEND_MAIL_SUBJECT: {
		X: "VESSEL_STORAGE_SEND_MAIL_SUBJECT",
		es: "Me gustaría usar Paralian para reservar muelle y espacio de almacenamiento seco con ustedes",
		en: "I'd like to use Paralian to reserve slips and dry storage space with you",
		fr: "J'aimerais utiliser Paralian pour réserver des bordereaux et un espace de stockage au sec avec vous"
	},
	VESSEL_STORAGE_SEND_MAIL_MESSAGE: {
		X: "VESSEL_STORAGE_SEND_MAIL_MESSAGE",
		es: "¡Hola!\n\nUso la aplicación de Paralian para realizar pedidos con proveedores de servicios que " +
			"atienden a la comunidad náutica recreativa. Después de intentar reservar un espcacio para mi embarcación " +
			"con ustedes, noté que esto no era posible porque aún no ofrecen este servicio a través de Paralian. " +
			"Hacer pedidos con Paralian es rápido y conveniente. Visite https://www.paralian.io o " +
			"contáctalos en mailto:hello@paralian.io\n\nSaludos,\n\n",
		en: "Hi!\n\nI use Paralian's app to place orders with service providers who cater to " +
			"the recreational boating community. After attempting to reserve a spot for my vessel with you, " +
			"I noticed this was not possible because you do not yet offer this service through Paralian. " +
			"Placing orders with Paralian is fast and convenient. Check them out at https://www.paralian.io or " +
			"contact them at mailto:hello@paralian.io\n\nRegards,\n\n",
		fr: "Bonjour!\n\nJ'utilise l'application de Paralian pour passer des commandes auprès de fournisseurs " +
			"de services qui s'adressent à la communauté de la navigation de plaisance. Après avoir tenté de réserver " +
			"une place pour mon navire avec vous, j'ai remarqué que ce n'était pas possible car vous n'offrez " +
			"pas encore ce service via Paralian. Passer des commandes avec Paralian est rapide et pratique. Consultez-les " +
			"sur https://www.paralian.io ou contactez-les à l'adresse mailto:hello@paralian.io\n\nCordialement,\n\n"
	},
	VESSEL_LOCATION_DEFINE: {
		X: "VESSEL_LOCATION_DEFINE",
		es: "La ubicación de su embarcación donde se entregarán los bienes o servicios.",
		en: "The location of your vessel where the goods or services are to be delivered.",
		fr: "L'emplacement de votre navire où les biens ou les services doivent être livrés."
	},
	EXTREMELY_DULL: {
		X: "EXTREMELY_DULL",
		es: "Extremadamente Opaca | Oxidada | Manchada",
		en: "Extremely Dull | Oxidized | Stained",
		fr: "Extrêmement Mate | Oxydée | Tachée"
	},
	WAX_DURATION: {
		X: "WAX_DURATION",
		es: "Por lo general, dura de 3 a 6 meses",
		en: "Typically lasts 3 to 6 months",
		fr: "Dure généralement 3 à 6 mois"
	},
	SEALANT_DURATION: {
		X: "SEALANT_DURATION",
		es: "Por lo general, dura de 4 a 8 meses",
		en: "Typically lasts 4 to 8 months",
		fr: "Dure généralement 4 à 8 mois"
	},
	CERAMIC_1_DURATION: {
		X: "CERAMIC_1_DURATION",
		es: "Por lo general, dura alrededor de 1 año",
		en: "Typically lasts around 1 year",
		fr: "Dure généralement environ 1 an"
	},
	CERAMIC_2_DURATION: {
		X: "CERAMIC_2_DURATION",
		es: "Por lo general, dura alrededor de 3 años",
		en: "Typically lasts around 3 years",
		fr: "Dure généralement environ 3 ans"
	},
	CERAMIC_3_DURATION: {
		X: "CERAMIC_3_DURATION",
		es: "Por lo general, dura alrededor de 5 años",
		en: "Typically lasts around 5 years",
		fr: "Dure généralement environ 5 ans"
	},
	CURRENT_SURFACE_STATE: {
		X: "CURRENT_SURFACE_STATE",
		es: "Estado Actual de la Superficie",
		en: "Current Surface State",
		fr: "État de Surface Actuel"
	},
	EXTREMELY_DULL_OXIDIZED_SURFACE: {
		X: "EXTREMELY_DULL_OXIDIZED_SURFACE",
		es: "Extremadamente Opaca | Oxidada",
		en: "Extremely Dull | Oxidized",
		fr: "Extrêmement Mate | Oxydée"
	},
	MOST_COMMON: {
		X: "MOST_COMMON",
		es: "más común",
		en: "most common",
		fr: "plus commun"
	},
	VERY_DULL: {
		X: "VERY_DULL",
		es: "Muy Opaca y/o Rayada",
		en: "Very Dull and/or Scratched",
		fr: "Très Mate et/ou Rayée"
	},
	DULL_BUFFER_TRAILS: {
		X: "DULL_BUFFER_TRAILS",
		es: "Opaca y/o tiene Marcas de Pulidora",
		en: "Dull and/or has Buffer Trails",
		fr: "Mate et/ou a Marques de Tourbillon"
	},
	BUFFER_TRAILS: {
		X: "BUFFER_TRAILS",
		es: "Marcas de Pulidora",
		en: "Buffer Trails",
		fr: "Marques de Tourbillon"
	},
	SHINY_SMOOTH: {
		X: "SHINY_SMOOTH",
		es: "Brilloso y Liso | Desgaste Mínimo",
		en: "Shiny and Smooth | Minimal Wear",
		fr: "Brillant et Lisse | Usure Minimale"
	},
	DESIRED_SURFACE_PROTECTION: {
		X: "DESIRED_SURFACE_PROTECTION",
		es: "Protección de Superficie Deseada",
		en: "Desired Surface Protection",
		fr: "Protection de Surface Souhaitée"
	},
	SURFACE_PROTECTION_DURATION_DISCLAIMER: {
		X: "SURFACE_PROTECTION_DURATION_DISCLAIMER",
		es: "Las estimaciones de duración asumen un mantenimiento adecuado de la superficie.",
		en: "Duration estimates assume proper surface maintenance.",
		fr: "Les estimations de durée supposent un entretien de surface approprié."
	},
	THIS_IS_DUPLICATE_PROFILE: {
		X: "THIS_IS_DUPLICATE_PROFILE",
		es: "Este es un perfil duplicado.",
		en: "This is a duplicate profile.",
		fr: "Ceci est un profil en double."
	},
	SEND_INVITE: {
		X: "SEND_INVITE",
		es: "Enviar Invitación",
		en: "Send Invite",
		fr: "Envoyer Invitation"
	},
	JOIN_PARALIAN_EXCLAMATION: {
		X: "JOIN_PARALIAN_EXCLAMATION",
		es: "¡Únete a Paralián!",
		en: "Join Paralian!",
		fr: "Rejoignez Paralian!"
	},
	PROVIDER_INVITE_MESSAGE: {
		X: "PROVIDER_INVITE_MESSAGE",
		es: "¡Hola! Quería hacer un pedido contigo a través de Paralian, pero no pude encontrarte. ¡Únete ahora!",
		en: "Hi! I wanted to place an order with you through Paralian but couldn't find you. Join now!",
		fr: "Salut! Je voulais vous passer une commande via Paralian mais je ne vous ai pas trouvé. Inscrivez-vous maintenant!"
	},
	SPECIAL_INSTRUCTIONS: {
		X: "SPECIAL_INSTRUCTIONS",
		es: "Instrucciones Especiales",
		en: "Special Instructions",
		fr: "Instructions Spéciales"
	},
	SPECIAL_INSTRUCTIONS_VESSEL_CLEANING_PLACEHOLDER: {
		X: "SPECIAL_INSTRUCTIONS_VESSEL_CLEANING_PLACEHOLDER",
		es: "Incluya aquí cualquier información que el proveedor deba conocer como: su información de contacto o " +
			"la del cuidador de la embarcación, requisitos de acceso a la marina, áreas específicas que necesitan " +
			"atención, ubicación de llaves, códigos de acceso, preferencias de horario de trabajo, preferencias de " +
			"productos, etc.",
		en: "Include here any information the provider should know such as: your contact info or that of the boat's " +
			"caretaker, marina access requirements, specific areas which need attention, location of keys, access codes, " +
			"work schedule preferences, product preferences, etc.",
		fr: "Incluez ici toutes les informations que le fournisseur devrait connaître telles que: vos coordonnées " +
			"ou celles du gardien du bateau, les exigences d'accès à la marina, les zones spécifiques qui nécessitent " +
			"une attention particulière, l'emplacement des clés, les codes d'accès, les préférences d'horaire de " +
			"travail, les préférences de produit, etc."
	},
	ORDER_ENTERED_EXCLAMATION: {
		X: "ORDER_ENTERED_EXCLAMATION",
		es: "¡Orden Ingresada!",
		en: "Order Entered!",
		fr: "Commande Saisie!"
	},
	I_HAVE_CONFIRMATION_CODE: {
		X: "I_HAVE_CONFIRMATION_CODE",
		es: "Tengo un código de confirmación",
		en: "I have a confirmation code",
		fr: "J'ai un code de confirmation"
	},
	LAUNCH_MOVE_TRANSPORT: {
		X: "LAUNCH_MOVE_TRANSPORT",
		es: "Lanzar | Mover | Transportar",
		en: "Launch | Move | Transport",
		fr: "Lancer | Déplacer | Transporter"
	},
	FUEL_WATER_PUMP_OUT: {
		X: "FUEL_WATER_PUMP_OUT",
		es: "Combustible | Agua | Bombeo de Desperdicios",
		en: "Fuel | Water | Pump-Out",
		fr: "Carburant | Eau | Pomper"
	},
	RESERVE_SLIP_MOORING: {
		X: "RESERVE_SLIP_MOORING",
		es: "Reservar Muelle | Amarre | Cama",
		en: "Reserve Slip | Mooring | Rack",
		fr: "Réserver Quai | Amarrage | Étagère"
	},
	ICE_FOOD_PROVISIONS: {
		X: "ICE_FOOD_PROVISIONS",
		es: "Hielo | Comida | Provisiones",
		en: "Ice | Food | Provisions",
		fr: "Glace | Nourriture | Provisions"
	},
	FINANCING_INSURANCE_SURVEY: {
		X: "FINANCING_INSURANCE_SURVEY",
		es: "Financiación | Seguros | Inspecciones",
		en: "Financing | Insurance | Survey",
		fr: "Financement | Assurance | Enquête"
	},
	LISTINGS_MARKETPLACE: {
		X: "LISTINGS_MARKETPLACE",
		es: "Listados | Mercado",
		en: "Listings | Marketplace",
		fr: "Annonces | Marché"
	},
	VIEW_LISTINGS: {
		X: "VIEW_LISTINGS",
		es: "Ver Listados",
		en: "View Listings",
		fr: "Voir les Annonces"
	},
	REPAIR_INSTALL_MAINTAIN: {
		X: "REPAIR_INSTALL_MAINTAIN",
		es: "Reparar | Instalar | Mantener",
		en: "Repair | Install | Maintain",
		fr: "Réparation | Installer | Maintenir"
	},
	WASH_DETAIL: {
		X: "WASH_DETAIL",
		es: "Limpieza | Detallado",
		en: "Wash | Detail",
		fr: "Nettoyage | Détail"
	},
	CONCIERGE: {
		X: "CONCIERGE",
		es: "Concierge",
		en: "Concierge",
		fr: "Concierge"
	},
	ACCESS_START_DATE: {
		X: "ACCESS_START_DATE",
		es: "Fecha de Inicio de Acceso",
		en: "Access Start Date",
		fr: "Date de Début d'Accès"
	},
	CUSTOMER_NOTES: {
		X: "CUSTOMER_NOTES",
		es: "Notas del Clientes",
		en: "Customer Notes",
		fr: "Remarques Client"
	},
	EMAIL_MARINA: {
		X: "EMAIL_MARINA",
		es: "Enviar Correo Electrónico a la Marina",
		en: "Email Marina",
		fr: "Courriel Marina"
	},
	UNAVAILABLE: {
		X: "UNAVAILABLE",
		es: "Indisponible",
		en: "Unavailable",
		fr: "Indisponible"
	},
	SEND_EMAIL: {
		X: "SEND_EMAIL",
		es: "Enviar Correo Electrónico",
		en: "Send eMail",
		fr: "Envoyer eMail"
	},
	VESSEL_FUELING_SEND_MAIL_PROMPT: {
		X: "VESSEL_FUELING_SEND_MAIL_PROMPT",
		es: "¡Hola! Esta marina aún no se ha registrado para ofrecer servicios de abastecimiento de combustible o " +
			"bombeo de desperdicios con Paralian. ¡Cuéntales sobre Paralian y tráelos a bordo!",
		en: "Hi! This marina is has not yet signed up to offer vessel fueling or pump-out " +
			"services with Paralian. Tell them about Paralian and bring them onboard!",
		fr: "Salut! Cette marina n'a pas encore signé pour offrir des services de ravitaillement ou de vidange de " +
			"navire avec Paralian. Parlez-leur de Paralian et amenez-les à bord!"
	},
	VESSEL_FUELING_SEND_MAIL_SUBJECT: {
		X: "VESSEL_FUELING_SEND_MAIL_SUBJECT",
		es: "Me gustaría usar Paralian para realizar pedidos de repostaje y bombeo con ustedes",
		en: "I'd like to use Paralian to place fueling and pump-out orders with you",
		fr: "J'aimerais utiliser Paralian pour passer des commandes de ravitaillement et de vidange avec vous"
	},
	VESSEL_FUELING_SEND_MAIL_MESSAGE: {
		X: "VESSEL_FUELING_SEND_MAIL_MESSAGE",
		es: "¡Hola!\n\nUso la aplicación de Paralian para realizar pedidos con proveedores de servicios que " +
			"atienden a la comunidad náutica recreativa. Después de intentar realizar un pedido de combustible " +
			"con ustedes, noté que esto no era posible porque aún no ofrecen este servicio a través de Paralian. " +
			"Hacer pedidos con Paralian es rápido y conveniente. Visite https://www.paralian.io o " +
			"contáctalos en mailto:hello@paralian.io\n\nSaludos,\n\n",
		en: "Hi!\n\nI use Paralian's app to place orders with service providers who cater to " +
			"the recreational boating community. After attempting to place a fueling order with you, " +
			"I noticed this was not possible because you do not yet offer this service through Paralian. " +
			"Placing orders with Paralian is fast and convenient. Check them out at https://www.paralian.io or " +
			"contact them at mailto:hello@paralian.io\n\nRegards,\n\n",
		fr: "Bonjour!\n\nJ'utilise l'application de Paralian pour passer des commandes auprès de fournisseurs " +
			"de services qui s'adressent à la communauté de la navigation de plaisance. Après avoir tenté de passer " +
			"une commande de ravitaillement auprès de vous, j'ai remarqué que ce n'était pas possible car vous n'offrez " +
			"pas encore ce service via Paralian. Passer des commandes avec Paralian est rapide et pratique. Consultez-les " +
			"sur https://www.paralian.io ou contactez-les à l'adresse mailto:hello@paralian.io\n\nCordialement,\n\n"
	},
	VESSEL_TRANSPORT_SEND_MAIL_PROMPT: {
		X: "VESSEL_TRANSPORT_SEND_MAIL_PROMPT",
		es: "¡Hola! Esta marina aún no se ha registrado para ofrecer servicios de transporte de embarcaciones con " +
			"Paralian. ¡Háblales de Paralian y tráelos a bordo!",
		en: "Hi! This marina is has not yet signed up to offer vessel transport services with Paralian. Tell them " +
			"about Paralian and bring them onboard!",
		fr: "Salut! Cette marina n'est pas encore inscrite pour offrir des services de transport de navires avec " +
			"Paralian. Parlez-leur de Paralian et amenez-les à bord!"
	},
	VESSEL_TRANSPORT_SEND_MAIL_SUBJECT: {
		X: "VESSEL_TRANSPORT_SEND_MAIL_SUBJECT",
		es: "Me gustaría usar Paralian para realizar pedidos de lanzamiento y transporte de embarcaciones con ustedes",
		en: "I'd like to use Paralian to place vessel launch and haul-out orders with you",
		fr: "J'aimerais utiliser Paralian pour passer des commandes de lancement et de transport de navires avec vous"
	},
	VESSEL_TRANSPORT_SEND_MAIL_MESSAGE: {
		X: "VESSEL_TRANSPORT_SEND_MAIL_MESSAGE",
		es: "¡Hola!\n\nUso la aplicación de Paralian para realizar pedidos con proveedores de servicios que " +
			"atienden a la comunidad náutica recreativa. Después de intentar realizar un pedido de lanzamiento de embarcaciones " +
			"con ustedes, noté que esto no era posible porque aún no ofrecen este servicio a través de Paralian. " +
			"Hacer pedidos con Paralian es rápido y conveniente. Visite https://www.paralian.io o " +
			"contáctalos en mailto:hello@paralian.io\n\nSaludos,\n\n",
		en: "Hi!\n\nI use Paralian's app to place orders with service providers who cater to " +
			"the recreational boating community. After attempting to place a vessel launch service order with you, " +
			"I noticed this was not possible because you do not yet offer this service through Paralian. " +
			"Placing orders with Paralian is fast and convenient. Check them out at https://www.paralian.io or " +
			"contact them at mailto:hello@paralian.io\n\nRegards,\n\n",
		fr: "Bonjour!\n\nJ'utilise l'application de Paralian pour passer des commandes auprès de fournisseurs " +
			"de services qui s'adressent à la communauté de la navigation de plaisance. Après avoir tenté de passer " +
			"une commande de service de lancement de navire auprès de vous, j'ai remarqué que ce n'était pas possible car vous n'offrez " +
			"pas encore ce service via Paralian. Passer des commandes avec Paralian est rapide et pratique. Consultez-les " +
			"sur https://www.paralian.io ou contactez-les à l'adresse mailto:hello@paralian.io\n\nCordialement,\n\n"
	},
	DETAILING_SHINE: {
		X: "DETAILING_SHINE",
		es: "Detallado | Brillo",
		en: "Detailing | Shine",
		fr: "Détails | Briller"
	},
	BOTTOM_SCRUB_BARNACLE_REMOVAL: {
		X: "BOTTOM_SCRUB_BARNACLE_REMOVAL",
		es: "Limpieza de Fondo | Eliminación de Percebes",
		en: "Bottom Scrub | Barnacle Removal",
		fr: "Gommage du Fond | Enlèvement des Balanes"
	},
	IGNORE_CONSTRAINTS: {
		X: "IGNORE_CONSTRAINTS",
		es: "Ignorar Restricciones",
		en: "Ignore Constraints",
		fr: "Ignorer les Contraintes"
	},
	ADVANCED_SELECTION: {
		X: "ADVANCED_SELECTION",
		es: "Selección Avanzada",
		en: "Advanced Selection",
		fr: "Sélection Avancée"
	},
	INCLUDE_FLUSH_QUESTION: {
		X: "INCLUDE_FLUSH_QUESTION",
		es: "¿Incluir enjuague de motor con agua dulce?",
		en: "Include fresh water engine flush?",
		fr: "Inclure le rinçage du moteur à l'eau douce?"
	},
	DUE_DATE_MESSAGE: {
		X: "DUE_DATE_MESSAGE",
		es: "Fecha y hora en que debe completarse el servicio y/o entregarse los bienes. Tenga en cuenta que es " +
			"más probable que las fechas de vencimiento flexibles (aquellas más en el futuro) resulten en solicitudes " +
			"aceptadas. Podrá anotar sus preferencias de horario de trabajo en la sección de Instrucciones " +
			"Especiales antes de enviar su pedido.",
		en: "Date and time by which the service must be completed and/or goods delivered. Note that flexible due " +
			"dates (those further in the future) are more likely to result in accepted requests. You will be able " +
			"to note your work schedule preferences in the Special Instructions section before submitting your order.",
		fr: "Date et heure auxquelles la prestation doit être réalisée et/ou les biens livrés. Notez que les dates " +
			"d'échéance flexibles (ceux plus éloignés dans le futur) sont plus susceptibles d'aboutir à des demandes " +
			"acceptées. Vous pourrez noter vos préférences d'horaire de travail dans la section Instructions Spéciales " +
			"avant de soumettre votre commande."
	},
	REQUEST: {
		X: "REQUEST",
		es: "Solicitud",
		en: "Request",
		fr: "Demande"
	},
	REQUEST_MESSAGE: {
		X: "REQUEST_MESSAGE",
		es: "¿Cómo podemos ayudarte? Dinos qué necesitas. Nos pondremos en contacto contigo lo antes posible.",
		en: "How can we help you? Tell us what you need. We'll get in touch with you as soon as possible.",
		fr: "Comment pouvons-nous vous aider ? Dites-nous ce dont vous avez besoin. Nous vous contacterons dans les plus brefs délais."
	},
	REQUEST_SENT_EXCLAMATION: {
		X: "REQUEST_SENT_EXCLAMATION",
		es: "¡Solicitud Enviada!",
		en: "Request Sent!",
		fr: "Demande Envoyée!"
	},
	CONCIERGE_IN_A_HURRY: {
		X: "CONCIERGE_IN_A_HURRY",
		es: "¿Tiene prisa? Llámenos o envíenos un mensaje.",
		en: "In a hurry? Call or message us.",
		fr: "Vous êtes pressé? Appelez-nous ou envoyez-nous un message."
	},
	CALL_VERB: {
		X: "CALL_VERB",
		es: "Llamar",
		en: "Call",
		fr: "Appelez"
	},
	MESSAGE_VERB: {
		X: "MESSAGE_VERB",
		es: "Enviar Mensaje",
		en: "Message",
		fr: "Envoyez Message"
	},
	EDIT_VERIFY: {
		X: "EDIT_VERIFY",
		es: "Editar | Verificar",
		en: "Edit | Verify",
		fr: "Éditer | Vérifier"
	},
	SELECT_MARINA: {
		X: "SELECT_MARINA",
		es: "Elija Marina",
		en: "Select Marina",
		fr: "Sélectionner le Marina"
	},
	SELECT_VESSEL: {
		X: "SELECT_VESSEL",
		es: "Elija Embarcación",
		en: "Select Vessel",
		fr: "Sélectionner le Navire"
	},
	SELECT_LOCATION: {
		X: "SELECT_LOCATION",
		es: "Elija Ubicación",
		en: "Select Location",
		fr: "Sélectionnez l'Emplacement"
	},
	WET_SAND: {
		X: "WET_SAND",
		es: "Lija Mojada | Compuesto de Corte Pesado",
		en: "Wet Sand | Heavy Cut Compound",
		fr: "Ponçage Humide | Composé de Coupe Lourde"
	},
	SYNTHETIC_SEALANT: {
		X: "SYNTHETIC_SEALANT",
		es: "Sellador Sintético",
		en: "Synthetic Sealant",
		fr: "Scellant Synthétique"
	},
	NAME_ON_CARD: {
		X: "NAME_ON_CARD",
		es: "Nombre en la Tarjeta",
		en: "Name on Card",
		fr: "Nom sur la Carte"
	},
	PNEUMATIC_SYSTEMS: {
		X: "PNEUMATIC_SYSTEMS",
		es: "Sistemas Neumáticos",
		en: "Pneumatic Systems",
		fr: "Systèmes Pneumatiques"
	},
	SEND_INVITATION: {
		X: "SEND_INVITATION",
		es: "Enviar Invitacion",
		en: "Send Invitation",
		fr: "Envoyer une Invitation"
	},
	SMS_TEXT: {
		X: "SMS_TEXT",
		es: "SMS (mensaje de texto)",
		en: "SMS (text)",
		fr: "SMS (texte)"
	},
	PHYSICAL_ADDRESS_MISSING: {
		X: "PHYSICAL_ADDRESS_MISSING",
		es: "Falta Dirección Física",
		en: "Physical Address Missing",
		fr: "Adresse Physique Manquante"
	},
	PHYSICAL_ADDRESS_MISSING_MESSAGE: {
		X: "PHYSICAL_ADDRESS_MISSING_MESSAGE",
		es: "Añada la dirección física. Esto es muy importante ya que se utiliza al calcular los impuestos sobre las ventas.",
		en: "Please add a physical address. This is very important as it is used when calculating sales taxes.",
		fr: "Veuillez ajouter une adresse physique. Ceci est très important car il est utilisé lors du calcul des taxes de vente."
	},
	MISSING_INFORMATION_MESSAGE: {
		X: "MISSING_INFORMATION_MESSAGE",
		es: "Las solicitudes de servicio de conserjería requieren un correo electrónico, un número de teléfono y un " +
			"método de pago validados. Por favor, inclúyalos en su perfil y vuelva a intentarlo.",
		en: "Concierge service requests require a validated email, phone number, and payment method. Please include these " +
			"in your profile and try again.",
		fr: "Les demandes de service de conciergerie nécessitent une adresse e-mail, un numéro de téléphone et un mode de " +
			"paiement validés. Veuillez les inclure dans votre profil et réessayer."
	},
	MISSING_INFORMATION: {
		X: "MISSING_INFORMATION",
		es: "Información Requerida",
		en: "Missing Information",
		fr: "Information Manquante"
	},
	PREFERRED_CONTACT_METHOD: {
		X: "PREFERRED_CONTACT_METHOD",
		es: "Método de Contacto Preferido",
		en: "Preferred Contact Method",
		fr: "Méthode de contact préférée"
	},
	METHOD: {
		X: "METHOD",
		es: "Método",
		en: "Method",
		fr: "Méthode"
	},
	VOICE_CALL: {
		X: "VOICE_CALL",
		es: "Llamada de Voz",
		en: "Voice Call",
		fr: "Appel Vocal"
	},
	TEXT_MESSAGE: {
		X: "TEXT_MESSAGE",
		es: "Mensaje de Texto",
		en: "Text Message",
		fr: "Message Texte"
	},
	CONCIERGE_DESCRIPTION_PLACEHOLDER: {
		X: "CONCIERGE_DESCRIPTION_PLACEHOLDER",
		es: "Por favor, describa su solicitud. Cuanto mayor sea el detalle, más rápido podremos cumplir con su solicitud.",
		en: "Please describe your request. The greater the detail, the faster we can fulfill your request.",
		fr: "Veuillez décrire votre demande. Plus le détail est important, plus nous pouvons répondre rapidement à votre demande."
	},
	IS_PAUSED_PROMPT: {
		X: "IS_PAUSED_PROMPT",
		es: "¿Está seguro de que desea dejar de recibir solicitudes de pedido?",
		en: "Are you sure you wish to stop receiving order requests?",
		fr: "Voulez-vous vraiment ne plus recevoir de demandes de commande?"
	},
	IS_EXEMPT_GOODS_PROMPT: {
		X: "IS_EXEMPT_GOODS_PROMPT",
		es: "¿Está seguro de que desea dejar de recaudar impuestos sobre las ventas de bienes que brinda?",
		en: "Are you sure you wish to stop collecting sales tax on goods you provide?",
		fr: "Êtes-vous sûr de vouloir arrêter de percevoir la taxe de vente sur les biens que vous fournissez?",
	},
	IS_EXEMPT_SERVICES_PROMPT: {
		X: "IS_EXEMPT_SERVICES_PROMPT",
		es: "¿Está seguro de que desea dejar de recaudar impuestos sobre las ventas en los servicios que brinda?",
		en: "Are you sure you wish to stop collecting sales tax on services you provide?",
		fr: "Êtes-vous sûr de vouloir cesser de percevoir la taxe de vente sur les services que vous fournissez?"
	},
	COLLECT_SALES_TAX_ON_SERVICES_MESSAGE: {
		X: "COLLECT_SALES_TAX_ON_SERVICES_MESSAGE",
		es: "Paralian calcula automáticamente los impuestos sobre las ventas en todos los pedidos. Deshabilitar esta " +
			"opción establecerá el impuesto sobre las ventas en todos los artículos de servicio a cero para todos los " +
			"pedidos entrantes nuevos.",
		en: "Paralian automatically calculates sales taxes on all orders. Disabling this option will set the sales tax " +
			"on all service items to zero for all new incoming orders.",
		fr: "Paralian calcule automatiquement les taxes de vente sur toutes les commandes. La désactivation de cette " +
			"option mettra la taxe de vente sur tous les articles de service à zéro pour toutes les nouvelle commandes " +
			"entrantes."
	},
	COLLECT_SALES_TAX_ON_SERVICES: {
		X: "COLLECT_SALES_TAX_ON_SERVICES",
		es: "Recaudar impuestos sobre las ventas de servicios",
		en: "Collect Sales Tax on Services",
		fr: "Percevoir la taxe de vente sur les services"
	},
	COLLECT_SALES_TAX_ON_GOODS_MESSAGE: {
		X: "COLLECT_SALES_TAX_ON_GOODS_MESSAGE",
		es: "Paralian calcula automáticamente los impuestos sobre las ventas en todos los pedidos. Deshabilitar esta " +
			"opción establecerá el impuesto sobre las ventas en todos los bienes a cero para todos los " +
			"pedidos entrantes nuevos.",
		en: "Paralian automatically calculates sales taxes on all orders. Disabling this option will set the sales tax " +
			"on all goods to zero for all new incoming orders.",
		fr: "Paralian calcule automatiquement les taxes de vente sur toutes les commandes. La désactivation de cette " +
			"option mettra la taxe de vente sur tous les marchandises à zéro pour toutes les nouvelle commandes " +
			"entrantes."
	},
	COLLECT_SALES_TAX_ON_GOODS: {
		X: "COLLECT_SALES_TAX_ON_GOODS",
		es: "Recaudar impuestos sobre las ventas de bienes",
		en: "Collect Sales Tax on Goods",
		fr: "Percevoir la taxe de vente sur les marchandises"
	},
	RETAIN_TEN_PERCENT_ON_PARALIAN_FEES_MESSAGE: {
		X: "RETAIN_TEN_PERCENT_ON_PARALIAN_FEES_MESSAGE",
		es: "Paralian calcula automáticamente los impuestos sobre las ventas en todos los pedidos. Deshabilitar esta " +
			"opción establecerá el impuesto sobre las ventas en todos los bienes a cero para todos los " +
			"pedidos entrantes nuevos.",
		en: "Paralian automatically calculates sales taxes on all orders. Disabling this option will set the sales tax " +
			"on all goods to zero for all new incoming orders.",
		fr: "Paralian calcule automatiquement les taxes de vente sur toutes les commandes. La désactivation de cette " +
			"option mettra la taxe de vente sur tous les marchandises à zéro pour toutes les nouvelle commandes " +
			"entrantes."
	},
	RETAIN_TEN_PERCENT_ON_PARALIAN_FEES: {
		X: "RETAIN_TEN_PERCENT_ON_PARALIAN_FEES",
		es: "Retener el diez porciento (10%) de los cargos por servicio de Paralian",
		en: "Retain ten percent (10%) of Paralian service charges",
		fr: "Conserver dix pour cent (10%) des frais de service Paralian"
	},
	PAUSE_SERVICE_LISTINGS_MESSAGE: {
		X: "PAUSE_SERVICE_LISTINGS_MESSAGE",
		es: "Habilite esta opción para dejar de aceptar todas las solicitudes de pedidos nuevos. Deshabilite para " +
			"reanudar la aceptación de nuevas solicitudes de pedido. Para limitar las solicitudes por artículo, vaya " +
			"a la sección Servicios y habilítelos o desactívelos individualmente. Dependiendo de su producto Paralian, " +
			"también puede limitar las solicitudes estableciendo límites en su suscripción.",
		en: "Enable this option to stop accepting all new order requests. Disable to resume accepting new order requests. " +
			"To limit requests by item, go to the Services section and enable or disable these individually. Depending on " +
			"your Paralian Prouct, you may also limit requests by setting limits on your subscription.",
		fr: "Activez cette option pour arrêter d'accepter toutes les nouvelles demandes de commande. Désactiver pour " +
			"reprendre l'acceptation de nouvelles demandes de commande. Pour limiter les demandes par article, " +
			"rendez-vous dans la section Services et activez ou désactivez-les individuellement. En fonction de " +
			"votre produit Paralian, vous pouvez également limiter les demandes en fixant des limites à votre abonnement."
	},
	PAUSE_SERVICE_LISTINGS: {
		X: "PAUSE_SERVICE_LISTINGS",
		es: "Suspender todos los Listados de Servicios",
		en: "Pause all Service Listings",
		fr: "Suspendre toutes les Listes de Services"
	},
	CHANGE_OFFICIAL_COMPANY_INFORMATION_MESSAGE: {
		X: "CHANGE_OFFICIAL_COMPANY_INFORMATION_MESSAGE",
		es: "¿Ha cambiado el nombre oficial, nombre de presentación, tipo de negocio o la estructura comercial " +
			"de la organización? Si es así, envíenos un correo electrónico.",
		en: "Has the official name, display name, business type or business structure of the organization changed? If so, " +
			"send us an eMail.",
		fr: "Le nom officiel, le nom d'affichage, le type d'entreprise ou la structure commerciale de l'organisation " +
			"ont-ils changé? Si oui, envoyez-nous un e-mail."
	},
	CHANGE_OFFICIAL_COMPANY_INFORMATION: {
		X: "CHANGE_OFFICIAL_COMPANY_INFORMATION",
		es: "Cambiar Información Oficial de la Empresa",
		en: "Change Official Company Information",
		fr: "Modifier les Informations Officielles de l'Entreprise"
	},
	PARALIAN_INVOICES: {
		X: "PARALIAN_INVOICES",
		es: "Facturas de Paralian",
		en: "Paralian Invoices",
		fr: "Paralian Invoices"
	},
	MARINE_SURVEYOR: {
		X: "MARINE_SURVEYOR",
		es: "Inspector Naval",
		en: "Marine Surveyor",
		fr: "Expert Maritime"
	},
	HULL_REPAIR: {
		X: "HULL_REPAIR",
		es: "Reparación de Casco",
		en: "Hull Repair",
		fr: "Réparation de Coque"
	},
	PEST_CONTROL: {
		X: "PEST_CONTROL",
		es: "Control de Plagas",
		en: "Pest Control",
		fr: "Antiparasitaire"
	},
	MATERIALS: {
		X: "MATERIALS",
		es: "Materiales",
		en: "Materials",
		fr: "Matériaux"
	},
	CREW: {
		X: "CREW",
		es: "Tripulación",
		en: "Crew",
		fr: "Équipage"
	},
	CAPTAIN: {
		X: "CAPTAIN",
		es: "Capitán",
		en: "Captain",
		fr: "Capitaine"
	},
	MATE: {
		X: "MATE",
		es: "Oficial",
		en: "Mate",
		fr: "Lieutenant"
	},
	DECKHAND: {
		X: "DECKHAND",
		es: "Marinero",
		en: "Deckhand",
		fr: "Matelot"
	},
	CHEF: {
		X: "CHEF",
		es: "Chef",
		en: "Chef",
		fr: "Chef"
	},
	MISC: {
		X: "MISC",
		es: "Misceláneo",
		en: "Miscellaneous",
		fr: "Divers"
	},
	POWERTRAIN: {
		X: "POWERTRAIN",
		es: "Tren Motriz",
		en: "Powertrain",
		fr: "Groupe Motopropulseur"
	},
	ENGINE_ROOM: {
		X: "ENGINE_ROOM",
		es: "Sala de Máquinas",
		en: "Engine Room",
		fr: "La Salle des Machines"
	},
	MAINTENANCE_AND_REPAIRS: {
		X: "MAINTENANCE_AND_REPAIRS",
		es: "Mantenimiento y Reparaciones",
		en: "Maintenance and Repairs",
		fr: "Entretien et Réparations"
	},
	PHYSICAL_EVALUATION: {
		X: "PHYSICAL_EVALUATION",
		es: "Evaluación Física",
		en: "Physical Evaluation",
		fr: "Évaluation Physique"
	},
	LABOR: {
		X: "LABOR",
		es: "Mano de Obra",
		en: "Labor",
		fr: "Main d'Oeuvre"
	},
	PARTS: {
		X: "PARTS",
		es: "Piezas",
		en: "Parts",
		fr: "Pièces"
	},
	TOTAL_DUE: {
		X: "TOTAL_DUE",
		es: "Total a Pagar",
		en: "Total Due",
		fr: "Total Due"
	},
	DELETE_ENGINE_CONFIRM_QUERY: {
		X: "DELETE_ENGINE_CONFIRM_QUERY",
		es: "¿Está seguro de que desea eliminar este motor de forma permanente?",
		en: "Are you sure you want to permanently delete this engine?",
		fr: "Are you sure you want to permanently delete this engine?"
	},
	DELETE_ADMIN_ACCOUNT_CONFIRM_QUERY: {
		X: "DELETE_ADMIN_ACCOUNT_CONFIRM_QUERY",
		es: "¿Está seguro de que desea eliminar de forma permanente la cuenta de administrador de ",
		en: "Are you sure you want to permanently delete the admin account for ",
		fr: "Are you sure you want to permanently delete the admin account for "
	},
	DELETE_DOCUMENT_CONFIRM_QUERY: {
		X: "DELETE_DOCUMENT_CONFIRM_QUERY",
		es: "¿Está seguro de que desea eliminar este documento?",
		en: "Are you sure you wish to delete this document?",
		fr: "Are you sure you wish to delete this document?"
	},
	DELETE_DOCUMENT_REQUIREMENT_CONFIRM_QUERY: {
		X: "DELETE_DOCUMENT_REQUIREMENT_CONFIRM_QUERY",
		es: "¿Está seguro de que desea eliminar este requisito de documento?",
		en: "Are you sure you wish to delete this document requirement?",
		fr: "Are you sure you wish to delete this document requirement?"
	},
	DELETE_HOLIDAY_CONFIRM_QUERY: {
		X: "DELETE_HOLIDAY_CONFIRM_QUERY",
		es: "Are you sure you want to permanently delete this holiday?",
		en: "Are you sure you want to permanently delete this holiday?",
		fr: "Are you sure you want to permanently delete this holiday?"
	},
	ALL_SERVICES_DEACTIVATED: {
		X: "ALL_SERVICES_DEACTIVATED",
		es: "No hay Servicios Activos",
		en: "All Services are Inactive",
		fr: "All Services are Inactive"
	},
	ALL_SERVICES_DEACTIVATED_MESSAGE: {
		X: "ALL_SERVICES_DEACTIVATED_MESSAGE",
		es: "Active sus servicios en la sección de \"Servicios\" más abajo.",
		en: "Activate your services by going to the \"Services\" section below.",
		fr: "Activate your services by going to the \"Services\" section below."
	},
	INACTIVE_SUBSCRIPTION_MESSAGE: {
		X: "INACTIVE_SUBSCRIPTION_MESSAGE",
		es: "Active su suscripción en la sección de \"Productos Paralian\" más abajo.",
		en: "Activate your subscription by going to the \"Paralian Products\" section below.",
		fr: "Activate your subscription by going to the \"Paralian Products\" section below.",
	},
	INACTIVE_SUBSCRIPTION: {
		X: "INACTIVE_SUBSCRIPTION",
		es: "Susscripción Inactiva",
		en: "Inactive Subscription",
		fr: "Inactive Subscription"
	},
	NO_CARD_ON_FILE: {
		X: "NO_CARD_ON_FILE",
		es: "Ninguna Tarjeta Guardada",
		en: "No Card on File",
		fr: "No Card on File"
	},
	STRIPE_SETUP_LINK_MESSAGE: {
		X: "STRIPE_SETUP_LINK_MESSAGE",
		es: "Paralian usa Stripe para procesar pagos y hacerle llegar su dinero. Seleccione \"Aceptar\" para " +
			"completar su configuración de Stripe.",
		en: "Paralian uses Stripe to process payments and get you paid. Select OK to complete your Stripe setup.",
		fr: "Paralian uses Stripe to process payments and get you paid. Select OK to complete your Stripe setup.",
	},
	STRIPE_LINK_MESSAGE: {
		X: "STRIPE_LINK_MESSAGE",
		es: "Presione OK para administrar tu cuenta de Stripe.",
		en: "Press OK to manage your Stripe account.",
		fr: "Press OK to manage your Stripe account."
	},
	SET_CUSTOMER_VESSEL_STATE: {
		X: "SET_CUSTOMER_VESSEL_STATE",
		es: "Establecer el Estado de la Embarcación del Cliente",
		en: "Set Customer Vessel State",
		fr: "Set Customer Vessel State"
	},
	CUSTOMER_VESSEL_STATE: {
		X: "CUSTOMER_VESSEL_STATE",
		es: "Estado de la Embarcación del Cliente",
		en: "Customer Vessel State",
		fr: "Customer Vessel State"
	},
	PERMANENT: {
		X: "PERMANENT",
		es: "Permanente",
		en: "Permanent",
		fr: "Permanent"
	},
	TEMPORARY: {
		X: "TEMPORARY",
		es: "Temporal",
		en: "Temporary",
		fr: "Temporary"
	},
	CUSTOMER_VESSEL_STATE_INFO: {
		X: "CUSTOMER_VESSEL_STATE_INFO",
		es: "Los clientes permanentes se transfieren a través de los ciclos de suscripción. El acceso a sus perfiles " +
			"continuará incluso cuando no haya pedidos abiertos con el cliente. Los clientes temporales no se " +
			"transfieren a través de los ciclos de suscripción. El acceso a sus perfiles se detendrá al final del " +
			"ciclo de suscripción actual o del próximo ciclo de suscripción cuando se cierren todas las órdenes " +
			"abiertas para el cliente.",
		en: "Permanent customers are carried through across subscription cycles. Access to their profiles will continue " +
			"even when there are no open orders with the customer." +
			" Temporary customers are not carried through across subscription cycles. Access to their profiles will stop " +
			"at the either end of the current subscription cycle or the next subscription cycle when all open orders for " +
			"the customer are closed.",
		fr: "Permanent customers are carried through across subscription cycles. Access to their profiles will continue " +
			"even when there are no open orders with the customer." +
			" Temporary customers are not carried through across subscription cycles. Access to their profiles will stop " +
			"at the either end of the current subscription cycle or the next subscription cycle when all open orders for " +
			"the customer are closed."
	},
	AUTO_RENEW_PERSONAL_PREMIUM_INFO: {
		X: "AUTO_RENEW_PERSONAL_PREMIUM_INFO",
		es: "Mientras la renovación automática esté habilitada, su suscripción se renueva automáticamente en la " +
			"fecha en que vence el plazo de la suscripción activa. En esta fecha se intentará realizar un cargo " +
			"por el importe correspondiente al coste total de la suscripción. En el caso de que el pago no se " +
			"procese con éxito dentro de los 7 días, la suscripción se cancelará automáticamente, lo que provocará " +
			"la pérdida de los servicios vinculados a la misma. Si está desactivada, la suscripción no se renovará " +
			"al final del plazo de suscripción y la cuenta perderá el acceso a los servicios vinculados a ella una " +
			"vez que expire la suscripción actual. ",
		en: "While auto-renew is enabled, your subscription will automatically renews on the date the active subscription " +
			"term expires. On this date, a charge for the amount pertaining to the total cost of the subscription will be " +
			"attempted. In the case where the payment is not successfully processed within 7 days, the subscription will " +
			"be automatically cancelled, resulting in the loss of services tied to it. If disabled, the " +
			"subscription will not be renewed at the end of the subscription term and the account " +
			"will lose access to the services tied to it once the current subscription expires. ",
		fr: "While auto-renew is enabled, your subscription will automatically renew on the date the active subscription " +
			"term expires. On this date, a charge for the amount pertaining to the total cost of the subscription will be " +
			"attempted. In the case where the payment is not successfully processed within 7 days, the subscription will " +
			"be automatically cancelled, resulting in the loss of services tied to it. If disabled, the " +
			"subscription will not be renewed at the end of the subscription term and the account " +
			"will lose access to the services tied to it once the current subscription expires. "
	},
	CANCEL_PERSONAL_PREMIUM_QUERY: {
		X: "CANCEL_PERSONAL_PREMIUM_QUERY",
		es: "Esta acción irreversible cancelará la facturación automática de su suscripción. Si decide " +
			"confirmar, perderá el acceso a los beneficios de su suscripción en su fecha de vencimiento. " +
			"¿Está seguro de que desea cancelar su suscripción?",
		en: "This irreversible action will cancel automatic billing to your subscription. If you decide to " +
			"confirm, you will loose access to the benefits of your subscription on its expiration date. Are you sure " +
			"you want to cancel your subscription?",
		fr: "This irreversible action will cancel automatic billing to your subscription. If you decide to " +
			"confirm, you will loose access to the benefits of your subscription on its expiration date. Are you sure " +
			"you want to cancel your subscription?",
	},
	CANCEL_SUBSCRIPTION_QUERY: {
		X: "CANCEL_SUBSCRIPTION_QUERY",
		es: "Esta acción desactivará la cuenta. Se le enviará una factura y se le cobrará al final del período de " +
			"suscripción actual. Recomendamos otras alternativas: pausar la cuenta desde la sección Control de cuentas; " +
			"establecer límites en la suscripción; desactivar servicios individualmente.",
		en: "This action will deactivate the account. An invoice will be sent and billed at the end of the current " +
			"subscription term. Recommended alternatives include: pause the account from Account Control section; set limits " +
			"on the subscription; deactivate services individually.",
		fr: "This action will deactivate the account. An invoice will be sent and billed at the end of the current " +
			"subscription term. Recommended alternatives include: pause the account from Account Control section; set limits " +
			"on the subscription; deactivate services individually."
	},
	ACTIVATE: {
		X: "ACTIVATE",
		es: "Activar",
		en: "Activate",
		fr: "Activate"
	},
	ACTIVE_CUSTOMERS: {
		X: "ACTIVE_CUSTOMERS",
		es: "Clientes Activos",
		en: "Active Customers",
		fr: "Active Customers"
	},
	ACTIVE_CUSTOMERS_MESSAGE: {
		X: "ACTIVE_CUSTOMERS_MESSAGE",
		es: "Número total de clientes activos en lo que va de este ciclo de facturación.",
		en: "Total number of active customers so far this billing cycle.",
		fr: "Total number of active customers so far this billing cycle.",
	},
	ORDERS_IGNORED_BILLING_CYCLE_MESSAGE: {
		X: "ORDERS_IGNORED_BILLING_CYCLE_MESSAGE",
		es: "Nuestros clientes esperan servicios excepcionales. Esto incluye respuestas rápidas a sus solicitudes. " +
			"Si no se responde a la solicitud de un cliente dentro de las 48 horas, se cancela automáticamente el " +
			"pedido y, como resultado, se pueden imponer sanciones al proveedor. Las sanciones incluyen clasificaciones " +
			"de búsqueda más bajas y una tarifa de $1 por cada ocurrencia en un ciclo de facturación determinado, " +
			"excluyendo la primera ocurrencia. Más de 10 (o 2 sin suscripción activa) ocurrencias dentro de un ciclo de " +
			"facturación determinado resultarán en una exclusión temporal de los resultados de búsqueda.",
		en: "Our customers expect exceptional services. This includes prompt responses to their requests. Failing to " +
			"respond to a customer's request within 48 hours results in an automatic cancellation of the order and " +
			"penalties may be imposed on the provider as a result. Penalties include lower search rankings and a $1 fee " +
			"for each occurrence on a given billing cycle, excluding the first occurrence. More than 10 " +
			"(or 2 pre-activation) occurrences within " +
			"a given billing cycle will result in a temporary exclusion from search results.",
		fr: "Our customers expect exceptional services. This includes prompt responses to their requests. Failing to " +
			"respond to a customer's request within 48 hours results in an automatic cancellation of the order and " +
			"penalties may be imposed on the provider as a result. Penalties include lower search rankings and a $1 fee " +
			"for each occurrence on a given billing cycle, excluding the first occurrence. More than 10 " +
			"(or 2 pre-activation) occurrences within " +
			"a given billing cycle will result in a temporary exclusion from search results."
	},
	ORDERS_IGNORED_BILLING_CYCLE: {
		X: "ORDERS_IGNORED_BILLING_CYCLE",
		es: "Pedidos ignorados hasta el momento en este ciclo de facturación",
		en: "Orders ignored so far this billing cycle",
		fr: "Orders ignored so far this billing cycle"
	},
	ORDERS_IGNORED: {
		X: "ORDERS_IGNORED",
		es: "Órdenes Ignoradas",
		en: "Orders Ignored",
		fr: "Orders Ignored"
	},
	ORDER_IGNORE_PENALTY: {
		X: "ORDER_IGNORE_PENALTY",
		es: "Penalidad por Ignorar Órdenes",
		en: "Order Ignore Penalty",
		fr: "Order Ignore Penalty"
	},
	PROVIDER_SUBSCRIPTION: {
		X: "PROVIDER_SUBSCRIPTION",
		es: "Suscripción de Proveedor",
		en: "Provider Subscription",
		fr: "Provider Subscription"
	},
	MARINA_SUBSCRIPTION: {
		X: "MARINA_SUBSCRIPTION",
		es: "Suscripción de Marina",
		en: "Marina Subscription",
		fr: "Marina Subscription",
	},
	TRIAL_SUBSCRIPTION: {
		X: "TRIAL_SUBSCRIPTION",
		es: "Suscripción de Prueba",
		en: "Trial Subscription",
		fr: "Trial Subscription",
	},
	BUSINESS_SUBSCRIPTION: {
		X: "BUSINESS_SUBSCRIPTION",
		es: "Suscripción de Negocio",
		en: "Business Subscription",
		fr: "Business Subscription",
	},
	STARTUP_SUBSCRIPTION: {
		X: "STARTUP_SUBSCRIPTION",
		es: "Suscripción Startup",
		en: "Startup Subscription",
		fr: "Startup Subscription",
	},
	STANDARD_SUBSCRIPTION: {
		X: "STANDARD_SUBSCRIPTION",
		es: "Suscripción Estándar",
		en: "Standard Subscription",
		fr: "Standard Subscription",
	},
	PARTNER_SUBSCRIPTION: {
		X: "PARTNER_SUBSCRIPTION",
		es: "Suscripción de Colaborador",
		en: "Partner Subscription",
		fr: "Partner Subscription",
	},
	CUSTOMER_TOKEN_MESSAGE_1: {
		X: "CUSTOMER_TOKEN_MESSAGE_1",
		es: "Un cliente, según se define a efectos del Crédito del Cliente, se refiere a la embarcación, no el usuario. ",
		en: "A customer - as defined for the purposes of the Customer Credit - is the vessel, not the user",
		fr: "A customer - as defined for the purposes of the Customer Credit - is the vessel, not the user",
	},
	CUSTOMER_TOKEN_MESSAGE_2: {
		X: "CUSTOMER_TOKEN_MESSAGE_2",
		es: "El período - Un período de suscripción comienza cuando se contrata un plan y dura 1 mes. ",
		en: "The term - A subscription term begins when you sign up for a plan and lasts 1 month. ",
		fr: "The term - A subscription term begins when you sign up for a plan and lasts 1 month. "
	},
	CUSTOMER_TOKEN_MESSAGE_3: {
		X: "CUSTOMER_TOKEN_MESSAGE_3",
		es: "Cuando se acepta un pedido de un cliente que no es un cliente activo, la cantidad de suscripciones de " +
			"clientes activos para su plan aumenta por uno. Durante el resto del período, su organización tendrá acceso " +
			"a la información del cliente en lo que respecta a su organización.",
		en: "When an order from an customer which is not an active customer " +
			"is accepted, the active customer subscription count for your plan is increased by one. " +
			"For the rest of the term, your organization will have access to the customer's information as it pertains " +
			"to your organization.",
		fr: "When an order from an customer which is not an active customer " +
			"is accepted, the active customer subscription count for your plan is increased by one. " +
			"For the rest of the term, your organization will have access to the customer's information as it pertains " +
			"to your organization."
	},
	CUSTOMER_TOKEN_MESSAGE_4: {
		X: "CUSTOMER_TOKEN_MESSAGE_4",
		es: "Al final de cada ciclo de facturación, se facturará a la organización por la cantidad total de clientes " +
			"activos acumulados durante el período de suscripción actual.",
		en: "The organization will be billed for the total number of active customers accumulated during the current " +
			"subscription term at the end of each billing cycle.",
		fr: "The organization will be billed for the total number of active customers accumulated during the current " +
			"subscription term at the end of each billing cycle."
	},
	CUSTOMER_TOKEN_MESSAGE_5: {
		X: "CUSTOMER_TOKEN_MESSAGE_5",
		es: "Por ejemplo, ACME Corp se inscribe en el plan de Créditos del Cliente el 1 de febrero de 2022. Acepta 10 " +
			"pedidos asignados a 5 embarcaciones diferentes antes de final de mes. La factura del mes de febrero " +
			"reflejará un cargo por 5 clientes activos.",
		en: "For example, ACME Corp signs up for the Customer Credits plan on February 1, 2022. It accepts 10 orders " +
			"assigned to 5 different vessels before the end of the month. The bill for the month of February will reflect" +
			"a charge for 5 active customers.",
		fr: "For example, ACME Corp signs up for the Customer Credits plan on February 1, 2022. It accepts 10 orders " +
			"assigned to 5 different vessels before the end of the month. The bill for the month of February will reflect" +
			"a charge for 5 active customers."
	},
	PREMIUM_MEMBERSHIP_MESSAGE_1: {
		X: "PREMIUM_MEMBERSHIP_MESSAGE_1",
		es: "Las Membresías Premium permiten que la cuenta suscriptora vea y descargue los registros de mantenimiento " +
			"de las embarcaciones a las que puede acceder, según el nivel de acceso. En el caso de las embarcaciones en " +
			"las que la cuenta está identificada como propietaria, también permite que la cuenta suscriptora cargue los " +
			"registros de mantenimiento de la embarcación.",
		en: "Premium Memberships allow the subscribing account to view and download " +
			"vessel maintenance records for all vessels to which the account has access, depending on " +
			"the level of access. For vessels where the account is identified as the owner, it also allows the " +
			"subscribing account to upload vessel maintenance records.",
		fr: "Premium Memberships allow the subscribing account to view and download " +
			"vessel maintenance records for all vessels to which the account has access, depending on " +
			"the level of access. For vessels where the account is identified as the owner, it also allows the " +
			"subscribing account to upload vessel maintenance records."
	},
	PREMIUM_MEMBERSHIP_MESSAGE_2: {
		X: "PREMIUM_MEMBERSHIP_MESSAGE_2",
		es: "El período de suscripción de las Membresías Premium comienza el día en que se recibe el pago y dura un " +
			"año. De forma predeterminada, las membresías se renuevan automáticamente al final del período de suscripción. ",
		en: "The subscription term of Premium Memberships begins on the day payment is received and lasts for " +
			"one year. By default, memberships are set to automatically renew at the end of the subscription term. ",
		fr: "The subscription term of Premium Memberships begins on the day payment is received and lasts for " +
			"one year. By default, memberships are set to automatically renew at the end of the subscription term. "
	},
	PREMIUM_MEMBERSHIP_MESSAGE_3: {
		X: "PREMIUM_MEMBERSHIP_MESSAGE_3",
		es: "Las Membresías Premium no son reembolsables.",
		en: "Premium Memberships are non-refundable.",
		fr: "Premium Memberships are non-refundable."
	},
	PLEASE_REFER_TO: {
		X: "PLEASE_REFER_TO",
		es: "Consulte ",
		en: "Please refer to ",
		fr: "Please refer to "
	},
	OUR_WEBSITE: {
		X: "OUR_WEBSITE",
		es: "nuestro sitio web",
		en: "our website",
		fr: "our website"
	},
	FOR_PRICING_INFORMATION: {
		X: "FOR_PRICING_INFORMATION",
		es: " para obtener información sobre precios.",
		en: " for pricing information.",
		fr: " for pricing information."
	},
	PRICE_OF_PREMIUM_MEMBERSHIP_IS: {
		X: "PRICE_OF_PREMIUM_MEMBERSHIP_IS",
		es: "La Membresía Premium cuesta ",
		en: "The price of a Premium Membership is ",
		fr: "The price of a Premium Membership is "
	},
	PLUS_TAXES: {
		X: "PLUS_TAXES",
		es: " + los impuestos correspondientes.",
		en: " + applicable taxes.",
		fr: " + applicable taxes."
	},
	AUTOMATIC_BILLING_MESSAGE_1: {
		X: "AUTOMATIC_BILLING_MESSAGE_1",
		es: "Cuando la renovación automática está activada, se intentará cobrar un cargo por el monto correspondiente " +
			"al costo total de la suscripción el día en que venza el período de suscripción. El incumplimiento del pago " +
			"dentro de un plazo de 7 días dará lugar a la cancelación automática de la suscripción y a la pérdida de los " +
			"servicios relacionados. De ser desactivada, la suscripción se cancelará automáticamente al finalizar el " +
			"período de suscripción y la cuenta perderá el acceso a los servicios vinculados a ella. ",
		en: "When auto renew is enabled, a " +
			"charge for the amount pertaining to the total cost of the subscription will be attempted on the day " +
			"the subscription term expires. Non-payment within 7 days will result in an automatic cancellation of " +
			"the subscription and the loss of services tied to it. If disabled, the " +
			"subscription will be automatically cancelled at the end of the subscription term and the account " +
			"will lose access to the services tied to it. ",
		fr: "When auto renew is enabled, a " +
			"charge for the amount pertaining to the total cost of the subscription will be attempted on the day " +
			"the subscription term expires. Non-payment within 7 days will result in an automatic cancellation of " +
			"the subscription and the loss of services tied to it. If disabled, the " +
			"subscription will be automatically cancelled at the end of the subscription term and the account " +
			"will lose access to the services tied to it. "
	},
	AUTOMATIC_BILLING_MESSAGE_2: {
		X: "AUTOMATIC_BILLING_MESSAGE_2",
		es: "El día de cierre, se intentará cobrar un cargo por el monto correspondiente a la cantidad de créditos " +
			"consumidos durante el periodo de facturación. El incumplimiento del pago dentro de un plazo de 7 días dará " +
			"lugar a que todas las cuentas de la organización pierdan acceso a la aplicación. Tenga en " +
			"cuenta que, si una suscripción se cancela y se reactiva dentro del mismo plazo de suscripción, la cantidad " +
			"de créditos consumidos hasta el momento no cambiará, ya que se acumulan durante todo el período de suscripción.",
		en: "A charge for the amount pertaining to the number of credits consumed during the closing billing term will be attempted " +
			"on its closing day. Non-payment within 7 days may result in loss of access by any and all of the " +
			"organization's accounts to the app. Note that if a subscription is cancelled and then re-activated " +
			"within the same subscription term, the number of credits consumed so far will not change as these " +
			"accumulate throughout the subscription term.",
		fr: "A charge for the amount pertaining to the number of credits consumed during the closing billing term will be attempted " +
			"on its closing day. Non-payment within 7 days may result in loss of access by any and all of the " +
			"organization's accounts to the app. Note that if a subscription is cancelled and then re-activated " +
			"within the same subscription term, the number of credits consumed so far will not change as these " +
			"accumulate throughout the subscription term."
	},
	ACTIVE_CUSTOMER_SUBSCRIPTIONS: {
		X: "ACTIVE_CUSTOMER_SUBSCRIPTIONS",
		es: "Suscripciones de clientes activos en lo que va de ciclo de facturación. Según evaluado en: ",
		en: "Active customer subscriptions so far this billing cycle. Valid as of: ",
		fr: "Active customer subscriptions so far this billing cycle. Valid as of: "
	},
	BILLING_CYCLE_LIMIT: {
		X: "BILLING_CYCLE_LIMIT",
		es: "Límite de uso de crédito del ciclo de facturación.",
		en: "Billing cycle credit usage limit.",
		fr: "Billing cycle credit usage limit."
	},
	BILLING_CYCLE_LIMIT_MESSAGE: {
		X: "BILLING_CYCLE_LIMIT_MESSAGE",
		es: "Su organización desaparecerá de los resultados de búsqueda de nuevos pedidos de posibles clientes durante " +
			"el resto del ciclo de facturación cuando la cantidad de clientes activos para su suscripción en ese ciclo " +
			"coincida con este límite. Su organización seguirá apareciendo en los resultados de búsqueda de los clientes " +
			"actuales (aquellos a los que se ha asignado un crédito para el ciclo de suscripción). Si establece el límite " +
			"en un número inferior a la cantidad utilizada actualmente, no se añadirán nuevos clientes a su suscripción " +
			"durante el resto del ciclo de suscripción actual y el límite se aplicará a todos los ciclos de suscripción " +
			"posteriores. En el caso de que no se aplique ningún límite, la cantidad de clientes activos de su suscripción " +
			"puede superar la de su plan actual. En este caso, pasaría automáticamente al plan que corresponde a la nueva " +
			"cantidad.",
		en: "Your organization will disappear from new order " +
			"search results of prospective customers for the remainder of the billing cycle when the number " +
			"of active customers for your subscription on that cycle matches this limit. Your organization will continue to appear in " +
			"the search results of current customers (those to which a credit has been assigned for the " +
			"subscription cycle). If you set the limit to a number which is less than the current used quantity, " +
			"no new customers will be added to your subscription during the remainder of the current subscription cycle and the limit will " +
			"apply to all future subscription cycles. In the case where no limit is applied, the number of active customers " +
			"for your subscription may exceed that of your current plan. In this case, you would be automatically migrated to " +
			"the plan that applies to the new quantity.",
		fr: "Your organization will disappear from new order " +
			"search results of prospective customers for the remainder of the billing cycle when the number " +
			"of active customers for your subscription on that cycle matches this limit. Your organization will continue to appear in " +
			"the search results of current customers (those to which a credit has been assigned for the " +
			"subscription cycle). If you set the limit to a number which is less than the current used quantity, " +
			"no new customers will be added to your subscription during the remainder of the current subscription cycle and the limit will " +
			"apply to all future subscription cycles. In the case where no limit is applied, the number of active customers " +
			"for your subscription may exceed that of your current plan. In this case, you would be automatically migrated to " +
			"the plan that applies to the new quantity.",
	},
	ERROR_GRANTING_ACCESS: {
		X: "ERROR_GRANTING_ACCESS",
		es: "Error al Otorgar Acceso",
		en: "Error Granting Access",
		fr: "Error Granting Access"
	},
	VALUE_MUST_BE_LESS_THAN: {
		X: "VALUE_MUST_BE_LESS_THAN",
		es: "El valor debe ser menor a ",
		en: "The value must be less than ",
		fr: "The value must be less than "
	},
	VALUE_MUST_BE_GREATER_THAN: {
		X: "VALUE_MUST_BE_GREATER_THAN",
		es: "El valor debe ser mayor a ",
		en: "The value must be greater than ",
		fr: "The value must be greater than "
	},
	VALUE_MUST_BE_MULTIPLE_OF: {
		X: "VALUE_MUST_BE_MULTIPLE_OF",
		es: "El valor debe ser múltiplo de ",
		en: "The value must be a multiple of ",
		fr: "The value must be a multiple of "
	},
	VALUE_TOO_LOW: {
		X: "VALUE_TOO_LOW",
		es: "Valor Muy Bajo",
		en: "Value Too Low",
		fr: "Value Too Low"
	},
	VALUE_TOO_HIGH: {
		X: "VALUE_TOO_HIGH",
		es: "Valor Muy Alto",
		en: "Value Too High",
		fr: "Value Too High"
	},
	VALUE_NOT_ALLOWED: {
		X: "VALUE_NOT_ALLOWED",
		es: "Valor no Permitido",
		en: "Value Not Allowed",
		fr: "Value Not Allowed"
	},
	PLEASE_SELECT_A_FILE_TO_UPLOAD: {
		X: "PLEASE_SELECT_A_FILE_TO_UPLOAD",
		es: "Seleccione un archivo para cargar",
		en: "Please select a file to upload",
		fr: "Please select a file to upload"
	},
	NO_FILE_UPLOADED: {
		X: "NO_FILE_UPLOADED",
		es: "Ningún Archivo Cargado",
		en: "No File Uploaded",
		fr: "No File Uploaded"
	},
	OVERNIGHT_HOURS: {
		X: "OVERNIGHT_HOURS",
		es: "Horario Nocturno",
		en: "Overnight Hours",
		fr: "Overnight Hours"
	},
	PAY: {
		X: "PAY",
		es: "Pagar",
		en: "Pay",
		fr: "Pay"
	},
	LOG_OUT: {
		X: "LOG_OUT",
		es: "Cerrar Sesión",
		en: "Log Out",
		fr: "Log Out"
	},
	IMAGE_UPLOAD_MESSAGE: {
		X: "IMAGE_UPLOAD_MESSAGE",
		es: "Las fotos de perfil claras y precisas nos ayudan a generar confianza en nuestra comunidad. " +
			"Recomendamos que sean de una sola persona, de rostro completo, enfocadas y sin alteraciones.",
		en: "Clear and accurate profile pictures help us build trust within our community. " +
			"We recommend these to be single-person, full-face, in-focus, and un-altered.",
		fr: "Clear and accurate profile pictures help us build trust within our community. " +
			"We recommend these to be single-person, full-face, in-focus, and un-altered."
	},
	SELECT_ONE_SERVICE_MESSAGE: {
		X: "SELECT_ONE_SERVICE_MESSAGE",
		es: "Debe seleccionar al menos un servicio antes de continuar.",
		en: "You must select at least one service in order to continue.",
		fr: "You must select at least one service in order to continue."
	},
	INPUT_REQUIRED: {
		X: "INPUT_REQUIRED",
		es: "Datos Requeridos",
		en: "Input Required",
		fr: "Input Required"
	},
	CANCEL_SELECTION: {
		X: "CANCEL_SELECTION",
		es: "Cancelar Selección",
		en: "Cancel Selection",
		fr: "Cancel Selection"
	},
	APPLY_SELECTION: {
		X: "APPLY_SELECTION",
		es: "Aplicar Selección",
		en: "Apply Selection",
		fr: "Apply Selection"
	},
	RESET_ALL_SELECTIONS: {
		X: "RESET_ALL_SELECTIONS",
		es: "Restablecer Todas las Selecciones",
		en: "Reset All Selections",
		fr: "Reset All Selections"
	},
	SELECTIONS_REQUIRE_CHANGES: {
		X: "SELECTIONS_REQUIRE_CHANGES",
		es: "Sus selecciones actuales requieren algunos cambios. Seleccione una de las siguientes opciones.",
		en: "Your current selections require some changes. Please select from the options below.",
		fr: "Your current selections require some changes. Please select from the options below."
	},
	DELETE_MARINA_QUERY: {
		X: "DELETE_MARINA_QUERY",
		es: "¿Está seguro de que desea eliminar definitivamente esta marina? " +
			"Su empresa dejará de aparecer como proveedor de servicios que se realizan en ella.",
		en: "Are you sure you want to permanently delete this marina? " +
			"Your business would no longer show up as a service provider at this marina.",
		fr: "Are you sure you want to permanently delete this marina? " +
			"Your business would no longer show up as a service provider at this marina.",
	},
	PREMIUM_MEMBERSHIP_REQUIRED: {
		X: "PREMIUM_MEMBERSHIP_REQUIRED",
		es: "Membresía Premium Requerida",
		en: "Premium Membership Required",
		fr: "Premium Membership Required"
	},
	UPDATE_AVAILABLE: {
		X: "UPDATE_AVAILABLE",
		es: "Actualización Disponible",
		en: "Update Available",
		fr: "Mise à Jour Disponible"
	},
	UPDATE_APP_MESSAGE: {
		X: "UPDATE_APP_MESSAGE",
		es: "Ya está disponible una versión nueva y mejorada de esta aplicación. Presione el botón de Actualizar o actualice su navegador para comenzar a utilizar la última versión",
		en: "A new and improved version of this app is ready for use. Press the Update button or refresh your browser to start using the latest version.",
		fr: "Une nouvelle version améliorée de cette application est prête à être utilisée. Appuyez sur Mettre à jour ou actualisez votre navigateur pour commencer à utiliser la dernière version."
	},
	PREMIUM_MEMBERSHIP_REQUIRED_MESSAGE: {
		X: "PREMIUM_MEMBERSHIP_REQUIRED_MESSAGE",
		es: "Se requiere una suscripción a una membresía premium para desbloquear esta función.",
		en: "A premium membership subscription is required to unlock this feature.",
		fr: "A premium membership subscription is required to unlock this feature."
	},
	RECOMMENDED_PROVIDER: {
		X: "RECOMMENDED_PROVIDER",
		es: "Proveedor Recomendado",
		en: "Recommended Provider",
		fr: "Recommended Provider"
	},
	RECOMMENDED_PROVIDER_MESSAGE: {
		X: "RECOMMENDED_PROVIDER_MESSAGE",
		es: " recomienda este proveedor.",
		en: " recommends this provider.",
		fr: " recommends this provider."
	},
	FAVORITE_PROVIDER: {
		X: "FAVORITE_PROVIDER",
		es: "Proveedor Favorito",
		en: "Favorite Provider",
		fr: "Favorite Provider"
	},
	FAVORITE_PROVIDER_MESSAGE: {
		X: "FAVORITE_PROVIDER_MESSAGE",
		es: "Este proveedor está en su lista de favoritos",
		en: "This provider is in your list of favorites",
		fr: "This provider is in your list of favorites"
	},
	TOP_TIER_PROVIDER: {
		X: "TOP_TIER_PROVIDER",
		es: "Proveedor de Primera Categoría",
		en: "Top Tier Provider",
		fr: "Top Tier Provider"
	},
	TOP_TIER_PROVIDER_MESSAGE: {
		X: "TOP_TIER_PROVIDER_MESSAGE",
		es: "Este proveedor ha sido reconocido por Paralian como proveedor de primera categoría",
		en: "This provider has been recognized by Paralian as a Top Tier Provider",
		fr: "This provider has been recognized by Paralian as a Top Tier Provider"
	},
	AVERAGE_RESPONSE_TIME: {
		X: "AVERAGE_RESPONSE_TIME",
		es: "Tiempo Promedio en Responder",
		en: "Average Response Time",
		fr: "Average Response Time"
	},
	AVERAGE_RESPONSE_TIME_MESSAGE: {
		X: "AVERAGE_RESPONSE_TIME_MESSAGE",
		es: "El tiempo promedio que este proveedor demora en responder a una solicitud de servicio.",
		en: "The average amount of time this provider takes to respond to a request for service.",
		fr: "The average amount of time this provider takes to respond to a request for service."
	},
	MANAGE_COOKIE_PREFERENCES: {
		X: "MANAGE_COOKIE_PREFERENCES",
		es: "Administrar Preferencias de Cookies",
		en: "Manage Cookie Preferences",
		fr: "Manage Cookie Preferences"
	},
	NO_VERIFICATION_REQUESTS_MESSAGE: {
		X: "NO_VERIFICATION_REQUESTS_MESSAGE",
		es: "No tiene solicitudes de verificación de identidad pendientes o finalizadas",
		en: "You have no pending or completed identity verification requests",
		fr: "You have no pending or completed identity verification requests"
	},
	OPENING_ELLIPSIS: {
		X: "OPENING_ELLIPSIS",
		es: "Abriendo...",
		en: "Opening...",
		fr: "Opening..."
	},
	SOMETHING_WENT_WRONG: {
		X: "SOMETHING_WENT_WRONG",
		es: "Algo salió mal.",
		en: "Something went wrong.",
		fr: "Something went wrong."
	},
	SET_AS_CURRENT: {
		X: "SET_AS_CURRENT",
		es: "Establecer como Actual",
		en: "Set as Current",
		fr: "Set as Current"
	},
	REMOVE_STORAGE_UNIT_QUERY: {
		X: "REMOVE_STORAGE_UNIT_QUERY",
		es: "¿Está seguro de que desea eliminar esta unidad de almacenamiento de su lista?",
		en: "Are you sure you would like to remove this storage unit from your list?",
		fr: "Are you sure you would like to remove this storage unit from your list?"
	},
	MARINA_LIST_EMPTY_MESSAGE: {
		X: "MARINA_LIST_EMPTY_MESSAGE",
		es: "Su lista de marinas está vacía. Agregue una marina mediante el menú de marinas y seleccione \"Mis marinas\" -> \"Agregar marina\"",
		en: "Your list of marinas is empty. Please add a marina by navigating to the marinas menu and selecting \"My Marinas\" -> \"Add Marina\"",
		fr: "Your list of marinas is empty. Please add a marina by navigating to the marinas menu and selecting \"My Marinas\" -> \"Add Marina\"",
	},
	CALL_SIGN_DESCRIPTION: {
		X: "CALL_SIGN_DESCRIPTION",
		es: "El signo de llamada tiene de 3 a 7 caracteres y solo incluye letras mayúsculas y números.",
		en: "The call sign is 3 to 7 characters long and includes only upper case letters and numbers.",
		fr: "The call sign is 3 to 7 characters long and includes only upper case letters and numbers."
	},
	IMO_NUMBER_DESCRIPTION: {
		X: "IMO_NUMBER_DESCRIPTION",
		es: "El número de IMO tiene 7 dígitos y solo incluye números.",
		en: "The IMO number is 7 digits long and includes only numbers.",
		fr: "The IMO number is 7 digits long and includes only numbers."
	},
	MMSI_NUMBER_DESCRIPTION: {
		X: "MMSI_NUMBER_DESCRIPTION",
		es: "El número de MMSI tiene 9 dígitos y solo incluye números.",
		en: "The MMSI number is 9 digits long and includes only numbers.",
		fr: "The MMSI number is 9 digits long and includes only numbers."
	},
	OFFICIAL_NUMBER_DESCRIPTION: {
		X: "OFFICIAL_NUMBER_DESCRIPTION",
		es: "El número oficial tiene entre 6 y 7 dígitos y solo incluye números.",
		en: "The official number is 6 to 7 digits long and includes only numbers.",
		fr: "The official number is 6 to 7 digits long and includes only numbers."
	},
	REGISTRATION_NUMBER_DESCRIPTION: {
		X: "REGISTRATION_NUMBER_DESCRIPTION",
		es: "El número de registro tiene 8 dígitos y solo incluye letras mayúsculas y números.",
		en: "The registration number is 8 digits long and includes only upper case letters and numbers.",
		fr: "The registration number is 8 digits long and includes only upper case letters and numbers."
	},
	REGISTRATION_DOCUMENT_NOT_PROVIDED: {
		X: "REGISTRATION_DOCUMENT_NOT_PROVIDED",
		es: "No se ha proporcionado el documento de registro.",
		en: "Registration document not provided.",
		fr: "Registration document not provided."
	},
	DOCUMENTATION_DOCUMENT_NOT_PROVIDED: {
		X: "DOCUMENTATION_DOCUMENT_NOT_PROVIDED",
		es: "No se ha proporcionado el documento de la documentación oficial.",
		en: "Documentation official document not provided.",
		fr: "Documentation official document not provided."
	},
	REGISTRATION_MISMATCH_MESSAGE: {
		X: "REGISTRATION_MISMATCH_MESSAGE",
		es: "El número de registro no coincide con el del documento de registro que figura en sus archivos.",
		en: "Registration number does not match that on registration document of record",
		fr: "Registration number does not match that on registration document of record"
	},
	OFFICIAL_NUMBER_MISMATCH_MESSAGE: {
		X: "OFFICIAL_NUMBER_MISMATCH_MESSAGE",
		es: "El número oficial no coincide con el de la documentación que figura en sus archivos.",
		en: "Official number does not match that on documentation document of record",
		fr: "Official number does not match that on documentation document of record",
	},
	HIN_DESCRIPTION: {
		X: "HIN_DESCRIPTION",
		es: "El número de casco tiene 12 caracteres y solo incluye letras mayúsculas y números.",
		en: "The HIN is 12 characters long and includes only upper case letters and numbers.",
		fr: "The HIN is 12 characters long and includes only upper case letters and numbers."
	},
	VESSEL_LEGAL_OWNER_MESSAGE: {
		X: "VESSEL_LEGAL_OWNER_MESSAGE",
		es: "Soy el propietario legal de esta embarcación y/o un representante legal designado de la organización " +
			"propietaria de la misma.",
		en: "I am the legal owner of this vessel and/or a designated legal representative of the " +
			"organization which owns this vessel.",
		fr: "I am the legal owner of this vessel and/or a designated legal representative of the " +
			"organization which owns this vessel."
	},
	VESSEL_INVITE_LEGAL_OWNER_MESSAGE: {
		X: "VESSEL_INVITE_LEGAL_OWNER_MESSAGE",
		es: "Soy el propietario legal de esta embarcación y/o un representante legal designado de la organización " +
			"propietaria de la misma.",
		en: "I am the legal owner of this vessel and/or a designated legal representative of the " +
			"organization which owns this vessel.",
		fr: "I am the legal owner of this vessel and/or a designated legal representative of the " +
			"organization which owns this vessel."
	},
	ADD_VESSEL_LEGAL_OWNER_MESSAGE: {
		X: "ADD_VESSEL_LEGAL_OWNER_MESSAGE",
		es: "Solo los propietarios legales pueden añadir embarcaciones. Agregar una embarcación de la " +
			"que no se es el propietario legal puede conllevar la expulsión de la plataforma Paralian. Si " +
			"usted no es propietario legal pero desea acceder a una embarcación mediante su flota, póngase " +
			"en contacto con el propietario de la embarcación y pídale que le facilite el acceso a su embarcación.",
		en: "Only legal owners are allowed to " +
			"add vessels. Adding a vessel which you do not legally own may result in expulsion from the Paralian platform. " +
			"If you are not a legal owner yet would like access to a vessel through your fleet, please " +
			"reach out to the vessel owner and ask them to provide you with access to their vessel.",
		fr: "Only legal owners are allowed to " +
			"add vessels. Adding a vessel which you do not legally own may result in expulsion from the Paralian platform. " +
			"If you are not a legal owner yet would like access to a vessel through your fleet, please " +
			"reach out to the vessel owner and ask them to provide you with access to their vessel."
	},
	PRIMARY_VESSEL: {
		X: "PRIMARY_VESSEL",
		es: "Embarcación Principal",
		en: "Primary Vessel",
		fr: "Primary Vessel"
	},
	PRIMARY_VESSEL_MESSAGE: {
		X: "PRIMARY_VESSEL_MESSAGE",
		es: "Esta es su embarcación principal.",
		en: "This is your primary vessel.",
		fr: "This is your primary vessel."
	},
	PRIMARY_VESSEL_QUERY: {
		X: "PRIMARY_VESSEL_QUERY",
		es: "¿Desea establecer esta como su embarcación principal?",
		en: "Would you like to set this as your primary vessel?",
		fr: "Would you like to set this as your primary vessel?"
	},
	UNLINK_VESSEL_QUERY: {
		X: "UNLINK_VESSEL_QUERY",
		es: "¿Está seguro de que desea desvincular esta embarcación?",
		en: "Are you sure you want to unlink this vessel?",
		fr: "Are you sure you want to unlink this vessel?"
	},
	ORGANIZATION_ACCESS: {
		X: "ORGANIZATION_ACCESS",
		es: "Acceso de Organización",
		en: "Organization Access",
		fr: "Organization Access"
	},
	ORGANIZATION_VESSEL_ACCESS_MESSAGE: {
		X: "ORGANIZATION_VESSEL_ACCESS_MESSAGE",
		es: "Las organizaciones reciben acceso automáticamente a la información relavante de la embarcacion cuando se crea " +
			"un nuevo pedido con ellas. No proporcione acceso de propietario a una organización a menos que la " +
			"organización sea la propietaria legal de la embarcación.",
		en: "Organizations automatically receive access to relevant vessel information when a new order is created with them. " +
			"Do not provide owner access to an organization unless the organization does indeed legally own the vessel.",
		fr: "Organizations automatically receive access to relevant vessel information when a new order is created with them. " +
			"Do not provide owner access to an organization unless the organization does indeed legally own the vessel.",
	},
	NEXT_STEPS: {
		X: "NEXT_STEPS",
		es: "Próximos Pasos",
		en: "Next Steps",
		fr: "Next Steps"
	},
	IVI_1: {
		X: "IVI_1",
		es: "Visite personalmente cualquiera de las marinas mencionadas durante las horas de servicio " +
			"de su oficina principal.",
		en: "Visit any one of the marinas above in person during their main office’s operating hours.",
		fr: "Visit any one of the marinas above in person during their main office’s operating hours."
	},
	IVI_2: {
		X: "IVI_2",
		es: "Asegúrese de llevar consigo un documento de identidad con fotografía emitido por el gobierno, " +
			"como el pasaporte, la licencia de conducir o una identificación estatal.",
		en: "Make sure to bring with you a government-issued photo ID such as a passport, driver’s license, or state ID.",
		fr: "Make sure to bring with you a government-issued photo ID such as a passport, driver’s license, or state ID."
	},
	IVI_3: {
		X: "IVI_3",
		es: "Diríjase a la oficina principal y solicite una Verificación de Identidad de Paralian.",
		en: "Go to the main office and request a Paralian ID Verification.",
		fr: "Go to the main office and request a Paralian ID Verification."
	},
	IVI_4: {
		X: "IVI_4",
		es: "El empleado de la marina verificará su identidad.",
		en: "The marina employee will verify your identity.",
		fr: "The marina employee will verify your identity."
	},
	IVI_5: {
		X: "IVI_5",
		es: "Facilíteles su documento de identidad con fotografía emitido por el gobierno y su código de verificación " +
			"de identidad (ver arriba) o su código QR de Paralian.",
		en: "Provide them with your government-issued photo ID and your ID verification code (above) or your Paralian QR Code.",
		fr: "Provide them with your government-issued photo ID and your ID verification code (above) or your Paralian QR Code."
	},
	IVI_6: {
		X: "IVI_6",
		es: "El código QR de Paralian y los códigos de verificación de identidad permitirán que la persona que " +
			"verifique su cuenta vea la información que debe verificar. Dicha información incluye su nombre completo y " +
			"su fecha de nacimiento.",
		en: "The Paralian QR Code and ID verification codes will allow the person verifying your account to view the information which is to be verified. This includes your full name and date of birth.",
		fr: "The Paralian QR Code and ID verification codes will allow the person verifying your account to view the information which is to be verified. This includes your full name and date of birth."
	},
	IVI_7: {
		X: "IVI_7",
		es: "¡Eso es todo!",
		en: "That’s it!",
		fr: "That’s it!"
	},
	IVI_8: {
		X: "IVI_8",
		es: "Si tiene algún problema, genere un ticket de soporte y lo atenderemos lo antes posible.",
		en: "If you have any issues, please generate a support ticket and we’ll be with you as soon as possible.",
		fr: "If you have any issues, please generate a support ticket and we’ll be with you as soon as possible."
	},
	EMPLOYEE_NO_ACCESS_MESSAGE: {
		X: "EMPLOYEE_NO_ACCESS_MESSAGE",
		es: "El empleado no tendrá acceso a los pedidos de servicio de este tipo.",
		en: "Employee will not have access to service orders of this type.",
		fr: "Employee will not have access to service orders of this type."
	},
	EMPLOYEE_ACCEPT_REJECT_MESSAGE: {
		X: "EMPLOYEE_ACCEPT_REJECT_MESSAGE",
		es: "El empleado podrá aceptar y rechazar solicitudes de servicio al cliente.",
		en: "Employee will be able to accept and reject customer service requests.",
		fr: "Employee will be able to accept and reject customer service requests."
	},
	EMPLOYEE_ASSIGN_ORDERS_MESSAGE: {
		X: "EMPLOYEE_ASSIGN_ORDERS_MESSAGE",
		es: "El empleado podrá asignar pedidos de servicio de este tipo a otros empleados.",
		en: "Employee will be able to assign service orders of this type to other employees.",
		fr: "Employee will be able to assign service orders of this type to other employees."
	},
	DONT_SEE_SERVICES_QUERY: {
		X: "DONT_SEE_SERVICES_QUERY",
		es: "¿Ninguno de sus servicios está en la lista? ",
		en: "Don't see any of your services in the list? ",
		fr: "Don't see any of your services in the list? "
	},
	SEND_US_AN_EMAIL: {
		X: "SEND_US_AN_EMAIL",
		es: "Envíenos un mensaje por correo electrónico",
		en: "Send us an eMail",
		fr: "Send us an eMail"
	},
	SELECT_SERVICE: {
		X: "SELECT_SERVICE",
		es: "Seleccione el servicio",
		en: "Select Service",
		fr: "Select Service"
	},
	SELECT_SERVICE_MESSAGE: {
		X: "SELECT_SERVICE_MESSAGE",
		es: "Debe seleccionar al menos un servicio para avanzar.",
		en: "You must select at least one service in order to proceed.",
		fr: "You must select at least one service in order to proceed."
	},
	NO_PROVIDERS: {
		X: "NO_PROVIDERS",
		es: "No hay Proveedores",
		en: "No Providers",
		fr: "No Providers"
	},
	NO_PROVIDERS_MESSAGE: {
		X: "NO_PROVIDERS_MESSAGE",
		es: "Lo lamentamos. No encontramos proveedores. Ayúdenos a regar la voz. ¡Dígale a sus proveedores favoritos " +
			"que se unan a Paralian!",
		en: "Sorry. No providers could be found. Help us spread the word. Tell your favorite providers to join Paralian!",
		fr: "Sorry. No providers could be found. Help us spread the word. Tell your favorite providers to join Paralian!"
	},
	MISSING_DATA_VESSEL: {
		X: "MISSING_DATA_VESSEL",
		es: "Faltan Datos  - Embarcación",
		en: "Missing Data - Vessel",
		fr: "Missing Data - Vessel"
	},
	MISSING_DATA_VESSEL_MESSAGE: {
		X: "MISSING_DATA_VESSEL_MESSAGE",
		es: "Uno o más proveedores no han podido calcular un precio porque falta la siguiente información en el " +
			"perfil de su embarcación: ",
		en: "One or more provider was unable to calculate a price for you because the following " +
			"information is still missing from your vessel profile: ",
		fr: "One or more provider was unable to calculate a price for you because the following " +
			"information is still missing from your vessel profile: "
	},
	MISSING_DATA_ACCOUNT: {
		X: "MISSING_DATA_ACCOUNT",
		es: "Faltan Datos - Cuenta",
		en: "Missing Data - Account",
		fr: "Missing Data - Account"
	},
	MISSING_DATA_ACCOUNT_MESSAGE: {
		X: "MISSING_DATA_ACCOUNT_MESSAGE",
		es: "Uno o más proveedores no han podido calcular un precio porque falta la siguiente información en el perfil " +
			"de su cuenta: ",
		en: "One or more provider was unable to calculate a price for you because the following " +
			"information is still missing from your account profile: ",
		fr: "One or more provider was unable to calculate a price for you because the following " +
			"information is still missing from your account profile: "
	},
	TOTAL_DUE_NOW: {
		X: "TOTAL_DUE_NOW",
		es: "Total a pagar",
		en: "Total due now",
		fr: "Total due now"
	},
	TERMS_OF_SALE_THIRD_PARTY_PART_1: {
		X: "TERMS_OF_SALE_THIRD_PARTY_PART_1",
		es: "Las transacciones de terceros se realizan entre usted y el proveedor. Por lo tanto, están sujetas a " +
			"los términos y condiciones del proveedor, además de los de Paralian. En caso de que una parte de los " +
			"términos y condiciones del proveedor entraran en conflicto con los de Paralian, la parte de los términos " +
			"y condiciones de Paralian tendrá prioridad. Paralian no se hace responsable de los daños ocasionados por " +
			"terceros proveedores, incluso cuando están relacionados con productos o servicios adquiridos a través de " +
			"Paralian. Paralian no ofrece garantías ni seguros de ningún tipo para productos o servicios de terceros.",
		en: "Third party transactions take place between you and the provider. " +
			"As such, these are subject to the provider's terms and conditions in addition to those of Paralian. " +
			"In the case where a portion of the provider's terms and conditions conflict with those of Paralian, " +
			"the portion in Paralian's terms and conditions takes precedence. Paralian is not responsible for any damages " +
			"incurred by third party providers including when these are related to items or services purchased through " +
			"Paralian. Paralian provides no guarantees or insurance of any kind for third party goods or services.",
		fr: "Third party transactions take place between you and the provider. " +
			"As such, these are subject to the provider's terms and conditions in addition to those of Paralian. " +
			"In the case where a portion of the provider's terms and conditions conflict with those of Paralian, " +
			"the portion in Paralian's terms and conditions takes precedence. Paralian is not responsible for any damages " +
			"incurred by third party providers including when these are related to items or services purchased through " +
			"Paralian. Paralian provides no guarantees or insurance of any kind for third party goods or services."
	},
	TERMS_OF_SALE_THIRD_PARTY_PART_2: {
		X: "TERMS_OF_SALE_THIRD_PARTY_PART_2",
		es: "Si paga con una tarjeta de crédito a través de Paralian, sus fondos se retendrán desde el momento en que " +
			"el proveedor proporcione la notificación de que se está completando el pedido. Puede cancelar su pedido sin " +
			"cargo en cualquier momento antes de este punto. Más allá de este punto, el pedido está sujeto a la política " +
			"de reembolso y devolución del proveedor.",
		en: "If paying with a credit card through Paralian, your funds will be held from the time the provider provides " +
			"notification that the order is being fulfilled. You may cancel your order free of charge at any time before " +
			"this point. Beyond this point, the order is subject to the provider's refund and return policy.",
		fr: "Si vous payez avec une carte de crédit via Paralian, vos fonds seront retenus à partir du moment où le " +
			"fournisseur notifie que la commande est en cours d'exécution. Vous pouvez annuler votre commande sans " +
			"frais à tout moment avant ce point. Au-delà de ce point, la commande est soumis à la politique de " +
			"remboursement et de retour du fournisseur."
	},
	STORAGE_UNIT_TYPE: {
		X: "STORAGE_UNIT_TYPE",
		es: "Tipo de unidad de almacenamiento",
		en: "Storage Unit Type",
		fr: "Storage Unit Type"
	},
	STORAGE_UNIT_TYPE_MESSAGE: {
		X: "STORAGE_UNIT_TYPE_MESSAGE",
		es: "Debe seleccionar un tipo de unidad en las instalaciones para poder pasar al siguiente paso de su reserva.",
		en: "A facilities unit type must be selected in order to move to the next step of your reservation.",
		fr: "A facilities unit type must be selected in order to move to the next step of your reservation."
	},
	STORAGE_UNITS_RESERVATION_DATES_MESSAGE: {
		X: "STORAGE_UNITS_RESERVATION_DATES_MESSAGE",
		es: "Especifique una fecha de llegada antes de continuar.",
		en: "Please specify a check-in date before proceeding.",
		fr: "Please specify a check-in date before proceeding."
	},
	STORAGE_UNITS_RESERVATION_DATES: {
		X: "STORAGE_UNITS_RESERVATION_DATES",
		es: "Fechas de reserva de unidades de almacenamiento",
		en: "Storage Unit Reservation Dates",
		fr: "Storage Unit Reservation Dates"
	},
	NO_UNITS_AVAILABLE_MESSAGE: {
		X: "NO_UNITS_AVAILABLE_MESSAGE",
		es: "Lo lamentamos, no hay unidades disponibles que cumplan con sus criterios.",
		en: "Sorry, there are no units available which meet your criteria.",
		fr: "Sorry, there are no units available which meet your criteria."
	},
	NO_UNITS_AVAILABLE: {
		X: "NO_UNITS_AVAILABLE",
		es: "No hay unidades disponibles",
		en: "No Units Available",
		fr: "No Units Available"
	},
	ERROR_PROCESSING_ORDER: {
		X: "ERROR_PROCESSING_ORDER",
		es: "Error de Procesando el Pedido",
		en: "Error Processing Order",
		fr: "Error Processing Order"
	},
	TERMS_OF_SALE_MESSAGE_1: {
		X: "TERMS_OF_SALE_MESSAGE_1",
		es: "Antes de finalizar su compra, debe leer, entender y aceptar la descripción y el precio del producto.",
		en: "Before completing your purchase, you must first read, understand and agree to the product description and pricing.",
		fr: "Before completing your purchase, you must first read, understand and agree to the product description and pricing."
	},
	TERMS_OF_SALE_MESSAGE_2: {
		X: "TERMS_OF_SALE_MESSAGE_2",
		es: "Si tiene alguna pregunta al respecto, comuníquese con nosotros a través de support@paralian.io",
		en: "If you have any questions regarding these, please contact us at support@paralian.io",
		fr: "If you have any questions regarding these, please contact us at support@paralian.io"
	},
	SUBSCRIBE: {
		X: "SUBSCRIBE",
		es: "Suscribir",
		en: "Subscribe",
		fr: "Subscribe"
	},
	NO_ACTIVE_PARALIAN_PRODUCTS: {
		X: "NO_ACTIVE_PARALIAN_PRODUCTS",
		es: "No tiene productos de Paralian activos",
		en: "You have no active Paralian products",
		fr: "You have no active Paralian products"
	},
	COMPOSITE_PRICING_QUERY: {
		X: "COMPOSITE_PRICING_QUERY",
		es: "La actualización de los precios compuestos dará lugar a una desactivación temporal del servicio hasta " +
			"que se apliquen los cambios. ¿Desea continuar?",
		en: "Updating composite pricing will result in a temporary deactivation of the service until the " +
			"changes are applied. Would you like to continue?",
		fr: "Updating composite pricing will result in a temporary deactivation of the service until the " +
			"changes are applied. Would you like to continue?"
	},
	UNABLE_TO_SAVE_PAYMENT_METHOD: {
		X: "UNABLE_TO_SAVE_PAYMENT_METHOD",
		es: "No logramos guardar el método de pago",
		en: "Unable to Save Payment Method",
		fr: "Unable to Save Payment Method"
	},
	READ_AND_AGREE_TO: {
		X: "READ_AND_AGREE_TO",
		es: "He leído y acepto l@s ",
		en: "I have read and agree to the ",
		fr: "I have read and agree to the "
	},
	READ_AND_AGREE_TERMS_OF_SALE: {
		X: "READ_AND_AGREE_TERMS_OF_SALE",
		es: "He leído y acepto las Condiciones de Venta",
		en: "I have read and agree to the Terms of Subscription",
		fr: "I have read and agree to the Terms of Subscription"
	},
	PAYMENT_METHOD_DEFAULT_MESSAGE: {
		X: "PAYMENT_METHOD_DEFAULT_MESSAGE",
		es: "Este es su método de pago predeterminado. Su método de pago predeterminado se utilizará para cualquier " +
			"suscripción que adquiera a través de Paralian.",
		en: "This is your default payment method. Your default payment method will be used for any subscriptions you " +
			"purchase through Paralian.",
		fr: "This is your default payment method. Your default payment method will be used for any subscriptions you " +
			"purchase through Paralian."
	},
	PAYMENT_METHOD_DEFAULT_QUERY: {
		X: "PAYMENT_METHOD_DEFAULT_QUERY",
		es: "¿Desea establecer este como su método de pago predeterminado?",
		en: "Would you like to set this as your default payment method?",
		fr: "Would you like to set this as your default payment method?"
	},
	PAYMENT_METHOD_DELETE_QUERY: {
		X: "PAYMENT_METHOD_DELETE_QUERY",
		es: "¿Está seguro de que desea eliminar este método de pago?",
		en: "Are you sure you want to remove this payment method?",
		fr: "Are you sure you want to remove this payment method?"
	},
	PAYMENT_DECLINED: {
		X: "PAYMENT_DECLINED",
		es: "Pago rechazado",
		en: "Payment Declined",
		fr: "Payment Declined"
	},
	PAYMENT_DECLINED_MESSAGE: {
		X: "PAYMENT_DECLINED_MESSAGE",
		es: " Verifique que la información proporcionada sea correcta e inténte nuevamente o utilice otra " +
			"tarjeta. Aceptamos las principales tarjetas de crédito. Si los problemas continúan, " +
			"comuníquese con el emisor de su tarjeta.",
		en: " Please check that the information provided is correct and try again or use a different card. " +
			"We accept all major credit cards. If issues continue, please contact your card issuer.",
		fr: " Please check that the information provided is correct and try again or use a different card. " +
			"We accept all major credit cards. If issues continue, please contact your card issuer."
	},
	PAYMENT_PROCESSED: {
		X: "PAYMENT_PROCESSED",
		es: "Su pago se ha procesado correctamente.",
		en: "Your payment has been processed successfully.",
		fr: "Your payment has been processed successfully."
	},
	CARD_INFO_INCOMPLETE: {
		X: "CARD_INFO_INCOMPLETE",
		es: "La información de la tarjeta proporcionada está incompleta. Incluya todos los datos solicitados e " +
			"inténte nuevamente.",
		en: "The card information as provided is incomplete. Please provide all requested data and try again.",
		fr: "The card information as provided is incomplete. Please provide all requested data and try again."
	},
	ERROR_RETRIEVING_ORDER: {
		X: "ERROR_RETRIEVING_ORDER",
		es: "Error Retrieving Order",
		en: "Error Recuperando Pedido.",
		fr: "Error Retrieving Order"
	},
	ERROR_RETRIEVING_ORDER_MESSAGE: {
		X: "ERROR_RETRIEVING_ORDER_MESSAGE",
		es: "No logramos obtener la información de su pedido. Presione el botón Atrás a continuación e inténtelo nuevamente.",
		en: "We were unable to gather your order information. Please press the back button below and try again.",
		fr: "We were unable to gather your order information. Please press the back button below and try again."
	},
	WHY: {
		X: "WHY",
		es: "¿Por qué?",
		en: "Why?",
		fr: "Why?"
	},
	MESSAGE_TO_CUSTOMER: {
		X: "MESSAGE_TO_CUSTOMER",
		es: "Mensaje al Cliente",
		en: "Message to Customer",
		fr: "Message to Customer"
	},
	SELECT_EMPLOYEE: {
		X: "SELECT_EMPLOYEE",
		es: "Seleccione un empleado de la lista.",
		en: "Please select an employee from the list.",
		fr: "Please select an employee from the list."
	},
	MISSING_INPUT: {
		X: "MISSING_INPUT",
		es: "Faltan Datos",
		en: "Missing Input",
		fr: "Missing Input"
	},
	PROPOSED_UNIT_PRICE_EXCL_TAXES: {
		X: "PROPOSED_UNIT_PRICE_EXCL_TAXES",
		es: "Precio Unitario Propuesto (excl. impuestos)",
		en: "Proposed Unit Price (excl. taxes)",
		fr: "Proposed Unit Price (excl. taxes)"
	},
	PROPOSED_UNIT_PRICE_EXCL_TAXES_PARALIAN_FEE: {
		X: "PROPOSED_UNIT_PRICE_EXCL_TAXES_PARALIAN_FEE",
		es: "Precio Unitario Propuesto (excl. impuestos & tarifa de Paralian)",
		en: "Proposed Unit Price (excl. taxes & Paralian fee)",
		fr: "Proposed Unit Price (excl. taxes & Paralian fee)"
	},
	PROPOSED_TOTAL_EXCL_TAXES: {
		X: "PROPOSED_TOTAL_EXCL_TAXES",
		es: "Total Propuesto (excl. impuestos)",
		en: "Proposed Total (excl. taxes)",
		fr: "Proposed Total (excl. taxes)"
	},
	PROPOSED_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE: {
		X: "PROPOSED_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE",
		es: "Subtotal Propuesto (excl. impuestos & tarifa de Paralian)",
		en: "Proposed Total (excl. taxes & Paralian fee)",
		fr: "Proposed Total (excl. taxes & Paralian fee)"
	},
	PAYMENT_REQUEST_AMOUNT_EXCL_TAXES_PARALIAN_FEE: {
		X: "PAYMENT_REQUEST_AMOUNT_EXCL_TAXES_PARALIAN_FEE",
		es: "Cantidad Solicitada (excl. impuestos & tarifa de Paralian)",
		en: "Payment Request Amount (excl. taxes & Paralian fee)",
		fr: "Montant de la demande de paiement (hors taxes et frais de Paralia)"
	},
	PROPOSED_ITEM_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE: {
		X: "PROPOSED_ITEM_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE",
		es: "Subtotal Propuesto (excl. impuestos & tarifa de Paralian)",
		en: "Proposed Subtotal Total (excl. taxes & Paralian fee)",
		fr: "Proposed Subtotal (excl. taxes & Paralian fee)"
	},
	PROPOSED_QUANTITY: {
		X: "PROPOSED_QUANTITY",
		es: "Cantidad Propuesta",
		en: "Proposed Quantity",
		fr: "Proposed Quantity"
	},
	TOTAL_EXCL_TAXES: {
		X: "TOTAL_EXCL_TAXES",
		es: "Total (sin impuestos)",
		en: "Total (excl. taxes)",
		fr: "Total (excl. taxes)"
	},
	ITEM_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE: {
		X: "ITEM_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE",
		es: "Subotal del Artículo (excl. impuestos & tarifa de Paralian)",
		en: "Item Subotal (excl. taxes & Paralian fee)",
		fr: "Item Subotal (excl. taxes & Paralian fee)"
	},
	UNIT_PRICE_EXCL_TAXES: {
		X: "UNIT_PRICE_EXCL_TAXES",
		es: "Precio Unitario (sin impuestos)",
		en: "Unit Price (excl. taxes)",
		fr: "Unit Price (excl. taxes)"
	},
	UNIT_PRICE_EXCL_TAXES_PARALIAN_FEE: {
		X: "UNIT_PRICE_EXCL_TAXES_PARALIAN_FEE",
		es: "Precio Unitario (exc. impuestos & tarifa de Paralian)",
		en: "Unit Price (excl. taxes & Paralian fee)",
		fr: "Unit Price (excl. taxes & Paralian fee)"
	},
	RATE: {
		X: "RATE",
		es: "Calificar",
		en: "Rate",
		fr: "Rate"
	},
	VALUE: {
		X: "VALUE",
		es: "Valor",
		en: "Value",
		fr: "Value"
	},
	VALUE_MESSAGE: {
		X: "VALUE_MESSAGE",
		es: "¿Le pareció justo el precio?",
		en: "Did you receive your money's worth?",
		fr: "Did you receive your money's worth?"
	},
	SERVICE_NOUN: {
		X: "SERVICE_NOUN",
		es: "Servicio",
		en: "Service",
		fr: "Service"
	},
	SERVICE_MESSAGE: {
		X: "SERVICE_MESSAGE",
		es: "¿Lo trataron con respeto? ¿Se realizó todo de manera oportuna?",
		en: "Where you treated with respect? Was everything completed in a timely manner?",
		fr: "Where you treated with respect? Was everything completed in a timely manner?"
	},
	QUALITY: {
		X: "QUALITY",
		es: "Calidad",
		en: "Quality",
		fr: "Quality"
	},
	QUALITY_MESSAGE: {
		X: "QUALITY_MESSAGE",
		es: "¿El resultado superó sus expectativas?",
		en: "Did the result exceed your expectations?",
		fr: "Did the result exceed your expectations?"
	},
	OVERALL: {
		X: "OVERALL",
		es: "En General",
		en: "Overall",
		fr: "Overall"
	},
	OVERALL_MESSAGE: {
		X: "OVERALL",
		es: "¿Qué probabilidad existe de que recomiende este proveedor por los bienes y/o servicios provistos?",
		en: "How likely are you to recommend this provider for the goods and/or services provided?",
		fr: "How likely are you to recommend this provider for the goods and/or services provided?"
	},
	ZERO_RATINGS: {
		X: "ZERO_RATINGS",
		es: "Sin calificaciones",
		en: "Zero Ratings",
		fr: "Zero Ratings"
	},
	ZERO_RATINGS_MESSAGE: {
		X: "ZERO_RATINGS_MESSAGE",
		es: "Uno o más atributos tienen una calificación de 0 estrellas. Debe calificar cada atributo con entre 1 y 5 " +
			"estrellas",
		en: "One or more attributes have a 0-star rating. You must rate each attribute between 1 and 5 stars",
		fr: "One or more attributes have a 0-star rating. You must rate each attribute between 1 and 5 stars"
	},
	CANT_FIND_MARINA: {
		X: "CANT_FIND_MARINA",
		es: "¿No encuentra su marina?",
		en: "Can't find your marina?",
		fr: "Can't find your marina?"
	},
	HELP_SPREAD_WORD: {
		X: "HELP_SPREAD_WORD",
		es: "¡Ayúdenos a correr la voz!",
		en: "Help us spread the word!",
		fr: "Help us spread the word!"
	},
	SELECT_AUTHORIZED: {
		X: "SELECT_AUTHORIZED",
		es: "Seleccione únicamente aquellas en las que su empresa esté autorizada a operar.",
		en: "Only select those where your business is authorized to operate.",
		fr: "Only select those where your business is authorized to operate."
	},
	UNLINK_ACCOUNT_QUERY: {
		X: "UNLINK_ACCOUNT_QUERY",
		es: "¿Está seguro de que desea desvincular esta cuenta?",
		en: "Are you sure you want to unlink this account?",
		fr: "Are you sure you want to unlink this account?"
	},
	PRIMARY_MARINA: {
		X: "PRIMARY_MARINA",
		es: "Marina Principal",
		en: "Primary Marina",
		fr: "Primary Marina"
	},
	PRIMARY_MARINA_MESSAGE: {
		X: "PRIMARY_MARINA_MESSAGE",
		es: "Esta es su marina principal.",
		en: "This is your primary marina.",
		fr: "This is your primary marina."
	},
	PRIMARY_MARINA_QUERY: {
		X: "PRIMARY_MARINA_QUERY",
		es: "No se han encontrado marinas o instalaciones de guardería de embarcaciones dentro del área " +
			"de servicio. Asegúrese de que su ubicación sea correcta y amplíe su área de servicio si fuera necesario.",
		en: "No marinas or vessel storage facilities found within service area. " +
			"Please make sure that your location is accurate and expand your service area if necessary.",
		fr: "No marinas or vessel storage facilities found within service area. " +
			"Please make sure that your location is accurate and expand your service area if necessary."
	},
	NO_MARINA_WITHIN_SERVICE_AREA: {
		X: "NO_MARINA_WITHIN_SERVICE_AREA",
		es: "No se han encontrado marinas o instalaciones de guardería de embarcaciones dentro del área de servicio. " +
			"Asegúrese de que su ubicación sea correcta y amplíe su área de servicio si fuera necesario.",
		en: "No marinas or vessel storage facilities found within service area. " +
			"Please make sure that your location is accurate and expand your service area if necessary.",
		fr: "No marinas or vessel storage facilities found within service area. " +
			"Please make sure that your location is accurate and expand your service area if necessary."
	},
	VIEW_MESSAGE: {
		X: "VIEW_MESSAGE",
		es: "Esta cuenta puede ver estos pedidos.",
		en: "This account has the ability to view these orders.",
		fr: "This account has the ability to view these orders."
	},
	ASSIGN_MESSAGE: {
		X: "ASSIGN_MESSAGE",
		es: "Esta cuenta puede asignar pedidos a otros empleados de la organización.",
		en: "This account has the ability to assign orders to other employees within the organization.",
		fr: "This account has the ability to assign orders to other employees within the organization."
	},
	ACCEPT_MESSAGE: {
		X: "ACCEPT_MESSAGE",
		es: "Esta cuenta puede aceptar estos pedidos en nombre de la organización.",
		en: "This account has the ability to accept these orders on behalf organization.",
		fr: "This account has the ability to accept these orders on behalf organization."
	},
	REJECT_MESSAGE: {
		X: "REJECT_MESSAGE",
		es: "Esta cuenta puede rechazar estos pedidos en nombre de la organización.",
		en: "This account has the ability to reject these orders on behalf of the organization.",
		fr: "This account has the ability to reject these orders on behalf of the organization."
	},
	DELETE_ACCOUNT_FOR_QUERY: {
		X: "DELETE_ACCOUNT_FOR_QUERY",
		es: "¿Está seguro de que desea eliminar definitivamente la cuenta de empleado de ",
		en: "Are you sure you want to permanently delete the employee account for ",
		fr: "Are you sure you want to permanently delete the employee account for "
	},
	PROVIDE_REQUIRED_DOCUMENT_SPEC_DOC: {
		X: "PROVIDE_REQUIRED_DOCUMENT_SPEC_DOC",
		es: "Proporcione un documento en formato PDF en el que se especifique el documento que se solicita. " +
			"Por ejemplo, el monto y la moneda del límite de responsabilidad personal de un contrato de seguro.",
		en: "Please provide a PDF document which provides specifics of the " +
			"document being requested. For example, the amount and currency of the personal liability limit on an " +
			"insurance contract.",
		fr: "Please provide a PDF document which provides specifics of the " +
			"document being requested. For example, the amount and currency of the personal liability limit on an " +
			"insurance contract."
	},
	REQUESTED_DOCUMENT_NOT_PROVIDED: {
		X: "REQUESTED_DOCUMENT_NOT_PROVIDED",
		es: "El documento solicitado todavía no ha sido suministrado.",
		en: "This requested document has not yet been provided.",
		fr: "This requested document has not yet been provided."
	},
	BECOME_MEMBER_UNLOCK: {
		X: "BECOME_MEMBER_UNLOCK",
		es: "Conviértase en Miembro Premium para desbloquear esta función",
		en: "Become a Premium Member to Unlock this Feature",
		fr: "Become a Premium Member to Unlock this Feature"
	},
	DOB_VERIFICATION: {
		X: "DOB_VERIFICATION",
		es: "Verificación de la fecha de nacimiento",
		en: "Date of Birth Verification",
		fr: "Date of Birth Verification"
	},
	DOB_VERIFICATION_MESSAGE: {
		X: "DOB_VERIFICATION_MESSAGE",
		es: "Su fecha de nacimiento no ha sido verificada. Desplácese hacia abajo hasta la sección de Verificación " +
			"de la identidad para obtener más información. Su fecha de nacimiento no se divulgará, excepto con el fin " +
			"de verificar su identidad y únicamente a la entidad que verifica su identidad. Algunas marinas y/o " +
			"proveedores pueden restringir sus servicios en función de la edad del usuario. En este caso, se utilizará " +
			"para determinar si es mayor de edad, pero no se divulgará.",
		en: "Your date of birth has not been verified. Please scroll down to the Identity Verification section " +
			"for more information. Your date of birth will not be shared except for the purpose of verifying your " +
			"identity and only with the entity verifying you identity. Some marinas and/or suppliers may restrict their " +
			"services depending on the user's age. In this case, it will be used to determine if you are of age, but won't " +
			"be shared.",
		fr: "Your date of birth has not been verified. Please scroll down to the Identity Verification section " +
			"for more information. Your date of birth will not be shared except for the purpose of verifying your " +
			"identity and only with the entity verifying you identity. Some marinas and/or suppliers may restrict their " +
			"services depending on the user's age. In this case, it will be used to determine if you are of age, but won't " +
			"be shared."
	},
	LOCATION_DATA_UNAVAILABLE: {
		X: "LOCATION_DATA_UNAVAILABLE",
		es: "Datos de ubicación no disponibles",
		en: "Location Data Unavailable",
		fr: "Location Data Unavailable"
	},
	DELETE_ADDRESS_PROMPT: {
		X: "DELETE_ADDRESS_PROMPT",
		es: "¿Está seguro de que desea eliminar esta dirección de forma permanente?",
		en: "Are you sure you want to permanently delete this address?",
		fr: "Are you sure you want to permanently delete this address?"
	},
	THIS_ADDRESS_VERIFIED: {
		X: "THIS_ADDRESS_VERIFIED",
		es: "Esta dirección ha sido verificada",
		en: "This address has been verified",
		fr: "This address has been verified"
	},
	THIS_ADDRESS_SHARED: {
		X: "THIS_ADDRESS_SHARED",
		es: "Esta dirección se comparte con los clientes",
		en: "This address is shared with customers",
		fr: "This address is shared with customers"
	},
	THIS_IS_MAILING_ADDRESS: {
		X: "THIS_IS_MAILING_ADDRESS",
		es: "Esta es una dirección postal",
		en: "This is a mailing address",
		fr: "This is a mailing address"
	},
	THIS_IS_PRIMARY_ADDRESS: {
		X: "THIS_IS_PRIMARY_ADDRESS",
		es: "Esta es la dirección principal",
		en: "This is the primary address",
		fr: "This is the primary address"
	},
	THIS_IS_BILLING_ADDRESS: {
		X: "THIS_IS_BILLING_ADDRESS",
		es: "Esta es una dirección de facturación",
		en: "This is a billing address",
		fr: "This is a billing address"
	},
	THIS_IS_PHYSICAL_ADDRESS: {
		X: "THIS_IS_PHYSICAL_ADDRESS",
		es: "Esta es una dirección física",
		en: "This is a physical address",
		fr: "This is a physical address"
	},
	THIS_IS_SUPPORT_ADDRESS: {
		X: "THIS_IS_SUPPORT_ADDRESS",
		es: "Esta es una dirección de respaldo",
		en: "This is a support address",
		fr: "This is a support address"
	},
	DELETE_ACCOUNT_NOTIFICATION_1: {
		X: "DELETE_ACCOUNT_NOTIFICATION_1",
		es: "Cuando elimine su cuenta de Paralian, la cuenta perderá todo el acceso a los servicios de Paralian " +
			"y ya no podrá iniciar sesión con sus credenciales.",
		en: "When you delete your paralian account, the account will lose all access to Paralian's services " +
			"and you will no longer be able to log in using its credentials.",
		fr: "When you delete your paralian account, the account will lose all access to Paralian's services " +
			"and you will no longer be able to log in using its credentials."
	},
	DELETE_ACCOUNT_NOTIFICATION_2: {
		X: "DELETE_ACCOUNT_NOTIFICATION_2",
		es: "No podrá abrir una nueva cuenta con el mismo nombre de usuario.",
		en: "You will not be able to open a new account using the same username.",
		fr: "You will not be able to open a new account using the same username."
	},
	DELETE_ACCOUNT_PROMPT_1: {
		X: "DELETE_ACCOUNT_PROMPT_1",
		es: "¿Está seguro de que desea eliminar esta cuenta? Esta acción es irreversible.",
		en: "Are you sure you wish to delete this account? This action is irreversible.",
		fr: "Are you sure you wish to delete this account? This action is irreversible."
	},
	DELETE_ACCOUNT_PROMPT_2: {
		X: "DELETE_ACCOUNT_PROMPT_2",
		es: "Solo para asegurarnos. BORRAR, ¿verdad?",
		en: "Just making sure. DELETE, right?",
		fr: "Just making sure. DELETE, right?"
	},
	FULL_NAME_GUIDELINES: {
		X: "FULL_NAME_GUIDELINES",
		es: "El nombre en su cuenta debe coincidir con su nombre completo como figura en la identificación oficial " +
			"del gobierno.",
		en: "The name on your account should match your full name as shown on official government identification.",
		fr: "The name on your account should match your full name as shown on official government identification."
	},
	NAME_CHANGE_PROMPT: {
		X: "NAME_CHANGE_PROMPT",
		es: "¿Está seguro de que desea cambiar el nombre en la cuenta? Esta acción anulará todas las verificaciones " +
			"de identidad.",
		en: "Are you sure you wish to change the name on the account? This will " +
			"void all identity verifications.",
		fr: "Are you sure you wish to change the name on the account? This will " +
			"void all identity verifications."
	},
	NAME_CHANGE_NOTICE: {
		X: "NAME_CHANGE_NOTICE",
		es: "Cambiar el nombre en su cuenta invalida cualquier verificación de identidad anterior.",
		en: "Changing the name on your account invalidates any past identity verification.",
		fr: "Changing the name on your account invalidates any past identity verification."
	},
	CHANGE_USERNAME_PROMPT: {
		X: "CHANGE_USERNAME_PROMPT",
		es: "¿Está seguro de que desea cambiar su nombre de usuario?",
		en: "Are you sure you wish to change your username?",
		fr: "Are you sure you wish to change your username?"
	},
	NO_SELECTED_MARINAS_PROMPT: {
		X: "NO_SELECTED_MARINAS_PROMPT",
		es: "No ha seleccionado una marina. ¿Desea continuar de todos modos?",
		en: "You have not selected any marinas. Would you like to proceed anyways?",
		fr: "You have not selected any marinas. Would you like to proceed anyways?"
	},
	IMAGE_UPLOADED: {
		X: "IMAGE_UPLOADED",
		es: "Imagen Cargada",
		en: "Image Uploaded",
		fr: "Image Uploaded"
	},
	NO_MARINAS: {
		X: "NO_MARINAS",
		es: "No hay marinas",
		en: "No Marinas",
		fr: "No Marinas"
	},
	NO_MARINAS_MESSAGE: {
		X: "NO_MARINAS_MESSAGE",
		es: "Las solicitudes de pedidos requieren que se especifique una marina. Agregue una marina a su perfil para " +
			"poder utilizar esta función.",
		en: "Order requests require that a marina be specified. Please add a marina to your profile in order to use " +
			"this functionality.",
		fr: "Order requests require that a marina be specified. Please add a marina to your profile in order to use " +
			"this functionality.",
	},
	NO_VESSELS: {
		X: "NO_VESSELS",
		es: "No hay embarcaciones",
		en: "No Vessels",
		fr: "No Vessels"
	},
	NO_VESSELS_MESSAGE: {
		X: "NO_VESSELS_MESSAGE",
		es: "Las solicitudes de pedidos requieren que se especifique una embarcación. Agregue una embarcación a su " +
			"perfil para poder utilizar esta función.",
		en: "Order requests require that a vessel be specified. Please add a vessel to your profile in order to use " +
			"this functionality.",
		fr: "Order requests require that a vessel be specified. Please add a vessel to your profile in order to use " +
			"this functionality.",
	},
	NO_PRODUCTS: {
		X: "NO_PRODUCTS",
		es: "No hay productos",
		en: "No Products",
		fr: "No Products"
	},
	NO_PRODUCTS_MESSAGE: {
		X: "NO_PRODUCTS_MESSAGE",
		es: "Parece que aún no se ha suscrito a un Producto Paralian. Vaya a la pestaña Productos Paralian en la " +
			"sección Organización para suscribirse a un producto. La cuenta de la organización permanecerá inactiva " +
			"hasta que se suscriba a un producto.",
		en: "It appears you still need to subscribe to a Paralian Product. Go to the Paralian Products tab in the " +
			"Organization section to subscribe to a product. The organization account will remain inactive until it has " +
			"been subscribed to a product.",
		fr: "It appears you still need to subscribe to a Paralian Product. Go to the Paralian Products tab in the " +
			"Organization section to subscribe to a product. The organization account will remain inactive until it has " +
			"been subscribed to a product."
	},
	PAYMENTS_SETUP_INCOMPLETE: {
		X: "PAYMENTS_SETUP_INCOMPLETE",
		es: "Configuración de pagos incompleta",
		en: "Payments Setup Incomplete",
		fr: "Payments Setup Incomplete"
	},
	PAYMENTS_SETUP_INCOMPLETE_MESSAGE: {
		X: "PAYMENTS_SETUP_INCOMPLETE_MESSAGE",
		es: "Parece que aún no ha completado la configuración de sus pagos. Presione el icono de Stripe en la " +
			"sección de Organización para completar su configuración. Debe titilar en rojo. La cuenta de la " +
			"organización permanecerá inactiva hasta que se complete la configuración de Stripe.",
		en: "It appears you still need  to complete your payments setup. Tap the Stripe icon in the Organization " +
			"section to complete your setup. It should be flashing red. The organization account will remain inactive " +
			"until the Stripe setup is complete.",
		fr: "It appears you still need  to complete your payments setup. Tap the Stripe icon in the Organization " +
			"section to complete your setup. It should be flashing red. The organization account will remain inactive " +
			"until the Stripe setup is complete."
	},
	INCORRECT_PASSWORD_LOGIN_MESSAGE: {
		X: "INCORRECT_PASSWORD_LOGIN_MESSAGE",
		es: "Incorrecto. La contraseña proporcionada no coincide. Inténtelo nuevamente. Si ha olvidado su contraseña, " +
			"vaya al siguiente enlace de Ayuda.",
		en: "Incorrect. The password provided does not match. Please try again. " +
			"If you have forgotten your password, please go to the Help link below.",
		fr: "Incorrect. The password provided does not match. Please try again. " +
			"If you have forgotten your password, please go to the Help link below."
	},
	INCORRECT_PASSWORD: {
		X: "INCORRECT_PASSWORD",
		es: "Contraseña incorrecta",
		en: "Incorrect Password",
		fr: "Incorrect Password"
	},
	CANT_LINK_PERSONAL_ACCOUNTS: {
		X: "CANT_LINK_PERSONAL_ACCOUNTS",
		es: "Parece que está intentando vincular una cuenta personal a otra. Las cuentas personales solo " +
			"pueden vincularse a las cuentas de los administradores de la organización o de los empleados.",
		en: "It appears you are attempting to link one personal account to another. Personal accounts may only link " +
			"to organization administrator or employee accounts.",
		fr: "It appears you are attempting to link one personal account to another. Personal accounts may only link " +
			"to organization administrator or employee accounts."
	},
	PASSWORD_RESET_SUCCESS: {
		X: "PASSWORD_RESET_SUCCESS",
		es: "Se le redirigirá a la solicitud de contraseña en breve. Inicie sesión con su nueva contraseña.",
		en: "You will be redirected to the password prompt soon. Please login using your new password.",
		fr: "You will be redirected to the password prompt soon. Please login using your new password."
	},
	FORBIDDEN: {
		X: "FORBIDDEN",
		es: "Prohibido",
		en: "Forbidden",
		fr: "Forbidden"
	},
	ERROR_403_MESSAGE: {
		X: "ERROR_403_MESSAGE",
		es: "Lo lamentamos. No tiene acceso para realizar la operación solicitada.",
		en: "Sorry. You don't have access to perform the requested operation.",
		fr: "Sorry. You don't have access to perform the requested operation."
	},
	ERROR_406_MESSAGE: {
		X: "ERROR_406_MESSAGE",
		es: "Lo lamentamos, no hemos podido cumplir con su solicitud. Avísenos si el problema persiste y nos ocuparemos " +
			"de inmediato.",
		en: "Sorry, we were unable to fulfill your request. Let us know if the problem persists and we'll get right on it.",
		fr: "Sorry, we were unable to fulfill your request. Let us know if the problem persists and we'll get right on it."
	},
	LOGIN_TIMEOUT: {
		X: "LOGIN_TIMEOUT",
		es: "Tiempo de espera para iniciar sesión agotado",
		en: "Login Timeout",
		fr: "Login Timeout"
	},
	CONNECTION_ERROR: {
		X: "CONNECTION_ERROR",
		es: "Error de conexión",
		en: "Connection Error",
		fr: "Connection Error"
	},
	CONNECTION_ERROR_MESSAGE: {
		X: "CONNECTION_ERROR_MESSAGE",
		es: "Tenemos problemas de conexión. Asegúrese de estar conectado a Internet e inténtelo nuevamente. Si el " +
			"problema persiste, póngase en contacto con support@paralian.io",
		en: "We've run into some connection issues. Please make sure you're connected to the internet and try again. " +
			"If the problem persists, please contact support@paralian.io",
		fr: "We've run into some connection issues. Please make sure you're connected to the internet and try again. " +
			"If the problem persists, please contact support@paralian.io"
	},
	CRASH_MESSAGE: {
		X: "CRASH_MESSAGE",
		es: "Hubo un error al cargar su contenido. La aplicación se actualizará automáticamente en cinco segundos.",
		en: "There was an error loading your content. The app will now automatically refresh in five seconds.",
		fr: "There was an error loading your content. The app will now automatically refresh in five seconds."
	},
	UNKNOWN_BRAND: {
		X: "UNKNOWN_BRAND",
		es: "Marca desconocida",
		en: "Unknown Brand",
		fr: "Unknown Brand"
	},
	OFFLINE: {
		X: "OFFLINE",
		es: "Offline",
		en: "Offline",
		fr: "Offline"
	},
	DEVICE_OFFLINE: {
		X: "DEVICE_OFFLINE",
		es: "Su dispositivo no está conectado a Internet.",
		en: "Your device is not connected to the internet.",
		fr: "Your device is not connected to the internet."
	},
	GEOLOCATION: {
		X: "GEOLOCATION",
		es: "Geolocalización",
		en: "Geolocation",
		fr: "Geolocation"
	},
	GEOLOCATION_PROVIDE_ACCESS: {
		X: "GEOLOCATION_PROVIDE_ACCESS",
		es: "Proporcione a Paralian acceso a su ubicación para poder utilizar esta función.",
		en: "Please provide Paralian with access to your location in order to use this feature.",
		fr: "Please provide Paralian with access to your location in order to use this feature."
	},
	LAUNCH: {
		X: "LAUNCH",
		es: "Lanzar",
		en: "Launch",
		fr: "Lancer"
	},
	STORE: {
		X: "STORE",
		es: "Almacenar",
		en: "Store",
		fr: "Stocker"
	},
	WORK_RACK: {
		X: "WORK_RACK",
		es: "Coloque en el Estante de Trabajo",
		en: "Place on Work Rack",
		fr: "Placer sur le Support de Travail"
	},
	UNCHANGED: {
		X: "UNCHANGED",
		es: "Sin Alterar",
		en: "Unchanged",
		fr: "Inchangé"
	},
	DISPUTES_MESSAGE_A: {
		X: "DISPUTES_MESSAGE_A",
		es: "En caso de disputas, comuníquese directamente con su proveedor utilizando la información de contacto a continuación.",
		en: "In case of disputes, please reach out directly to your provider using the contact information below.",
		fr: "En cas de litige, veuillez contacter directement votre fournisseur en utilisant les coordonnées ci-dessous."
	},
	DISPUTES_MESSAGE_B: {
		X: "DISPUTES_MESSAGE_B",
		es: "Si el pago relacionado con este pedido se completó con una tarjeta de crédito, también puede comunicarse con " +
			"el emisor de su tarjeta de crédito para obtener ayuda.",
		en: "If the payment related to this order was completed using a credit card, you may also contact your credit " +
			"card issuer for assistance.",
		fr: "Si le paiement lié à cette commande a été effectué par carte de crédit, vous pouvez également contacter " +
			"l'émetteur de votre carte de crédit pour obtenir de l'aide."
	},
	CURRENT_DUE_DATE: {
		X: "CURRENT_DUE_DATE",
		es: "Fecha de Engrega Actual",
		en: "Current Due Date",
		fr: "Date d'Échéance Actuelle"
	},
	PROPOSED_DUE_DATE: {
		X: "PROPOSED_DUE_DATE",
		es: "Fecha de Entrega Propuesta",
		en: "Proposed Due Date",
		fr: "Date d'Échéance Proposée"
	},
	PROPOSED: {
		X: "PROPOSED",
		es: "Propuesta",
		en: "Proposed",
		fr: "Proposée"
	},
	EXCLUDING_TAXES: {
		X: "EXCLUDING_TAXES",
		es: "Excluyendo Impuestos",
		en: "Excluding Taxes",
		fr: "Hors taxes"
	},
	ORDER_TOTAL_TAXES: {
		X: "ORDER_TOTAL_TAXES",
		es: "Total del Pedido (impuestos incluidos)",
		en: "Order Total (including taxes)",
		fr: "Commande Totale (taxes incluses)"
	},
	MESSAGE_FOR_CUSTOMER: {
		X: "MESSAGE_FOR_CUSTOMER",
		es: "Mensaje para el Cliente",
		en: "Message for Customer",
		fr: "Message pour le Client"
	},
	EXPLANATION: {
		X: "EXPLANATION",
		es: "Explicación",
		en: "Explanation",
		fr: "Explication"
	},
	ADD_PROVIDER_TO_FAVORITES: {
		X: "ADD_PROVIDER_TO_FAVORITES",
		es: "Agregar Proveedor a Favoritos",
		en: "Add Provider to Favorites",
		fr: "Ajouter un Fournisseur aux Favoris"
	},
	MARK_INCOMPLETE: {
		X: "MARK_INCOMPLETE",
		es: "Marcar Como Incompleto",
		en: "Mark as Incomplete",
		fr: "Marquer Comme Incomplet"
	},
	MARK_PAID: {
		X: "MARK_PAID",
		es: "Marcar Como Pagado",
		en: "Mark as Paid",
		fr: "Marquer Comme Payé"
	},
	MARKED_PAID: {
		X: "MARKED_PAID",
		es: "Marcado Pagado",
		en: "Marked Paid",
		fr: "Marqué Payé"
	},
	MARKED_UNPAID: {
		X: "MARKED_UNPAID",
		es: "Marcado No Pagado",
		en: "Marked Unpaid",
		fr: "Marqué Non Payé"
	},
	PAID: {
		X: "PAID",
		es: "Pagado",
		en: "Paid",
		fr: "Payé"
	},
	MARK_UNPAID: {
		X: "MARK_UNPAID",
		es: "Marcar Como No Pagado",
		en: "Mark as Unpaid",
		fr: "Marquer Comme Non Payé"
	},
	UNPAID: {
		X: "UNPAID",
		es: "No Pagado",
		en: "Unpaid",
		fr: "Non Payé"
	},
	PROVIDE_REFUND: {
		X: "PROVIDE_REFUND",
		es: "Proporcionar Reembolso",
		en: "Provide Refund",
		fr: "Fournir un Remboursement"
	},
	REFUND_AMOUNT_TAXES: {
		X: "REFUND_AMOUNT_TAXES",
		es: "Importe del Reembolso (impuestos incluidos)",
		en: "Refund Amount (including taxes)",
		fr: "Montant du Remboursement (taxes incluses)"
	},
	MARK_STARTED: {
		X: "MARK_STARTED",
		es: "Marcar Como Iniciado",
		en: "Mark Started",
		fr: "Marquer Commencé"
	},
	CONTACT_PROVIDER: {
		X: "CONTACT_PROVIDER",
		es: "Contactar al Proveedor",
		en: "Contact Provider",
		fr: "Contacter le Fournisseur"
	},
	CONTACT_CUSTOMER: {
		X: "CONTACT_CUSTOMER",
		es: "Contactar al Cliente",
		en: "Contact Customer",
		fr: "Contacter le Client"
	},
	MARK_COMPLETE: {
		X: "MARK_COMPLETE",
		es: "Marcar Como Completo",
		en: "Mark Complete",
		fr: "Marquer Comme Terminé"
	},
	DOWNLOAD_RECEIPT: {
		X: "DOWNLOAD_RECEIPT",
		es: "Descargar Recibo",
		en: "Download Receipt",
		fr: "Télécharger le Reçu"
	},
	ASSIGNED_GROUP: {
		X: "ASSIGNED_GROUP",
		es: "Grupo Asignado",
		en: "Assigned Group",
		fr: "Groupe Désigné"
	},
	ASSIGN_GROUP: {
		X: "ASSIGN_GROUP",
		es: "Designar Grupo",
		en: "Assign Group",
		fr: "Désignér un Groupe"
	},
	ASSIGN_EMPLOYEE: {
		X: "ASSIGN_EMPLOYEE",
		es: "Designar Empleado",
		en: "Assign Employee",
		fr: "Désignér un Employé"
	},
	ASSIGNED_EMPLOYEE: {
		X: "ASSIGNED_EMPLOYEE",
		es: "Empleado Asignado",
		en: "Assigned Employee",
		fr: "Employé Désigné"
	},
	RATE_EXPERIENCE: {
		X: "RATE_EXPERIENCE",
		es: "Calificar la Experiencia",
		en: "Rate Experience",
		fr: "Évaluez l'Expérience"
	},
	PROPOSE_DUE_DATE_CHANGE: {
		X: "PROPOSE_DUE_DATE_CHANGE",
		es: "Proponer Cambio de Fecha de Vencimiento",
		en: "Propose Due Date Change",
		fr: "Proposer un Changement de Date d'Échéance"
	},
	PROPOSE_PRICE_CHANGE: {
		X: "PROPOSE_PRICE_CHANGE",
		es: "Proponer Cambio de Precio",
		en: "Propose Price Change",
		fr: "Proposer un Changement de Prix"
	},
	SHOW_ORDER_DETAILS: {
		X: "SHOW_ORDER_DETAILS",
		es: "Mostrar Detalles del Pedido",
		en: "Show Order Details",
		fr: "Afficher les Détails de la Commande"
	},
	ORDER_DETAILS: {
		X: "ORDER_DETAILS",
		es: "Detalles del Pedido",
		en: "Order Details",
		fr: "Détails de la Commande"
	},
	RESPOND_TO_PROPOSED_CHANGES: {
		X: "RESPOND_TO_PROPOSED_CHANGES",
		es: "Responder a los Cambios Propuestos",
		en: "Respond to Proposed Changes",
		fr: "Répondre aux Modifications Proposées"
	},
	ACCEPT_CHANGES: {
		X: "ACCEPT_CHANGES",
		es: "Aceptar Cambios",
		en: "Accept Changes",
		fr: "Accepter les Modifications"
	},
	CANCEL_ORDER: {
		X: "CANCEL_ORDER",
		es: "Cancelar Pedido",
		en: "Cancel Order",
		fr: "Annuler la Commande"
	},
	INCOMPLETE_ORDER_CONFIRM: {
		X: "INCOMPLETE_ORDER_CONFIRM",
		es: "Presione OK para confirmar que desea marcar el pedido como incompleto.",
		en: "Press OK to confirm you wish to mark this order as incomplete.",
		fr: "Appuyez sur OK pour confirmer que vous souhaitez marquer cette commande comme incomplète."
	},
	CANCEL_ORDER_CONFIRM: {
		X: "CANCEL_ORDER_CONFIRM",
		es: "Presione OK para confirmar que desea cancelar el pedido.",
		en: "Press OK to confirm you wish to cancel the order.",
		fr: "Appuyez sur OK pour confirmer que vous souhaitez annuler la commande."
	},
	CANCEL_ORDER_RECURRENCE_CONFIRM: {
		X: "CANCEL_ORDER_RECURRENCE_CONFIRM",
		es: "Presione OK si desea cancelar todas las instancias futuras programadas de este pedido.",
		en: "Press OK if you would you like to cancel all future scheduled instance of this order.",
		fr: "Appuyez sur OK si vous souhaitez annuler toutes les futures instances programmées de cette commande."
	},
	DECLINE_ORDER_CONFIRM: {
		X: "DECLINE_ORDER_CONFIRM",
		es: "Presione OK para confirmar que desea rechazar el pedido.",
		en: "Press OK to confirm you wish to cancel the order.",
		fr: "Appuyez sur OK pour confirmer que vous souhaitez refuser la commande."
	},
	DECLINE_CHANGES_CONFIRM: {
		X: "DECLINE_CHANGES_CONFIRM",
		es: "Presione OK para confirmar que desea rechazar los cambios.",
		en: "Press OK to confirm you wish to decline changes.",
		fr: "Appuyez sur OK pour confirmer que vous souhaitez refuser les modification."
	},
	ACCEPT_CHANGES_CONFIRM: {
		X: "ACCEPT_CHANGES_CONFIRM",
		es: "Presione OK para confirmar que desea aceptar los cambios.",
		en: "Press OK to confirm you wish to accept changes.",
		fr: "Appuyez sur OK pour confirmer que vous souhaitez accepter les modification."
	},
	CONFIRM_SELECTION: {
		X: "CONFIRM_SELECTION",
		es: "Presione OK para confirmar su selección.",
		en: "Press OK to confirm selection.",
		fr: "Appuyez sur OK pour confirmer la sélection."
	},
	CONFIRM_DO_NOT_CHARGE_CUSTOMER: {
		X: "CONFIRM_DO_NOT_CHARGE_CUSTOMER",
		es: "Presione OK para confirmar que no desea cobrar por este pedido.",
		en: "Press OK to confirm you do not wish to charge the cusotmer for this order.",
		fr: "Appuyez sur OK pour confirmer que vous ne souhaitez pas facturer cette commande au client."
	},
	CONFIRM_REQUEST_FULL_PAYMENT: {
		X: "CONFIRM_REQUEST_FULL_PAYMENT",
		es: "Presione OK para confirmar que desea solicitar el pago completo de este pedido.",
		en: "Press OK to confirm you wish to request full payment for this order.",
		fr: "Appuyez sur OK pour confirmer que vous souhaitez demander le paiement intégral de cette commande."
	},
	CONFIRM_REQUEST_PARTIAL_PAYMENT: {
		X: "CONFIRM_REQUEST_PARTIAL_PAYMENT",
		es: "Presione OK para confirmar que desea solicitar el pago parcial de este pedido.",
		en: "Press OK to confirm you wish to request partial payment for this order.",
		fr: "Appuyez sur OK pour confirmer que vous souhaitez demander le paiement partiel pour cette commande."
	},
	FIX_PRICES_CONFIRM: {
		X: "FIX_PRICES_CONFIRM",
		es: "Presione OK si desea fijar estos precios únicamente en el contexto de esta recurrencia. Presione CANCELAR si desea que la fijación de precios también aplique fuera de esta recurrencia.",
		en: "Press OK if you wish to fix these prices only within the context of this recurrence. Press CANCEL if you wish for the price fix to also apply outside of this recurrence.",
		fr: "Appuyez sur OK si vous souhaitez fixer ces prix uniquement dans le cadre de cette récurrence. Appuyez sur ANNULER si vous souhaitez que la fixation des prix s'applique également en dehors de cette récurrence.",
	},
	INVALID_INPUT: {
		X: "INVALID_INPUT",
		es: "Entrada Inválida",
		en: "Invalid Input",
		fr: "Entrée Invalide"
	},
	ASSIGNED: {
		X: "ASSIGNED",
		es: "Asignado",
		en: "Assigned",
		fr: "Attribué"
	},
	PLACED: {
		X: "PLACED",
		es: "Pedido",
		en: "Placed",
		fr: "Passée"
	},
	APPROVED: {
		X: "APPROVED",
		es: "Aprobado",
		en: "Approved",
		fr: "Approuvé"
	},
	PROCESSING: {
		X: "PROCESSING",
		es: "Procesando",
		en: "Processing",
		fr: "Traitement"
	},
	DISPUTE: {
		X: "DISPUTE",
		es: "Cuestionar",
		en: "Dispute",
		fr: "Contester"
	},
	DISPUTED: {
		X: "DISPUTED",
		es: "Cuestionado",
		en: "Disputed",
		fr: "Contesté"
	},
	ORGANIZATION_DOCUMENT: {
		X: "ORGANIZATION_DOCUMENT",
		es: "Documento Comercial",
		en: "Organization Document",
		fr: "Document Commercial"
	},
	VESSEL_DOCUMENT: {
		X: "VESSEL_DOCUMENT",
		es: "Documento de Embarcación",
		en: "Vessel Document",
		fr: "Document du navire"
	},
	PERSONAL_DOCUMENT: {
		X: "PERSONAL_DOCUMENT",
		es: "Documento Personal",
		en: "Personal Document",
		fr: "Document Personnel"
	},
	CERTIFICATES: {
		X: "CERTIFICATES",
		es: "Licenscias, Permisos y Certificados",
		en: "Licenses, Permits & Certificates",
		fr: "Licences, Permis et Certificats"
	},
	LIABILITY_INSURANCE: {
		X: "LIABILITY_INSURANCE",
		es: "Seguro de Responsabilidad",
		en: "Liability Insurance",
		fr: "Assurance Responsabilité Civile"
	},
	MARINA_ADMIN_ACCOUNT: {
		X: "MARINA_ADMIN_ACCOUNT",
		es: "Cuenta de Administrador de Marina",
		en: "Marina Admin Account",
		fr: "Compte Administrateur d'Entreprise"
	},
	MARINA_EMPLOYEE_ACCOUNT: {
		X: "MARINA_EMPLOYEE_ACCOUNT",
		es: "Cuenta de Empleado de Marina",
		en: "Marina Employee Account",
		fr: "Compte d'Employé d'Marina"
	},
	BUSINESS_ADMIN_ACCOUNT: {
		X: "BUSINESS_ADMIN_ACCOUNT",
		es: "Cuenta de Empleado Comercial",
		en: "Business Admin Account",
		fr: "Compte Administrateur d'Entreprise"
	},
	BUSINESS_EMPLOYEE_ACCOUNT: {
		X: "BUSINESS_EMPLOYEE_ACCOUNT",
		es: "Cuenta de Administrador Cormercial ",
		en: "Business Employee Account",
		fr: "Compte d'Employé d'Entreprise"
	},
	PERSONS: {
		X: "PERSONS",
		es: "Personas",
		en: "Persons",
		fr: "Personnes"
	},
	LOAD: {
		X: "LOAD",
		es: "Carga",
		en: "Load",
		fr: "Charge"
	},
	MOTOR: {
		X: "MOTOR",
		es: "Motor",
		en: "Motor",
		fr: "Moteur"
	},
	PERSONS_MOTOR_AND_GEAR: {
		X: "PERSONS_MOTOR_AND_GEAR",
		es: "Personas, Motor y Equipo",
		en: "Persons, Motor, and Gear",
		fr: "Personnes, Moteur et Équipement"
	},
	DO_NOT_SHARE_MY_INFO: {
		X: "DO_NOT_SHARE_MY_INFO",
		es: "No compartan mi información",
		en: "Do not sell my info",
		fr: "Ne partage pas mes informations"
	},
	PARALIAN: {
		X: "PARALIAN",
		es: "morador del mar",
		en: "dweller by the sea",
		fr: "habitant de la mer"
	},
	SELECTED: {
		X: "SELECTED",
		es: "Seleccionado",
		en: "Selected",
		fr: "Choisi"
	},
	DESCRIPTION: {
		X: "DESCRIPTION",
		es: "Descripción",
		en: "Description",
		fr: "Description"
	},
	HOUSEKEEPING: {
		X: "HOUSEKEEPING",
		es: "Limpieza Doméstica",
		en: "Housekeeping",
		fr: "Entretien Ménager"
	},
	DISHES_LAUNDRY: {
		X: "DISHES_LAUNDRY",
		es: "platos & lavandería",
		en: "dishes & laundry",
		fr: "vaisselle & lessive"
	},
	COMING_SOON: {
		X: "COMING_SOON",
		es: "Próximamente",
		en: "Coming Soon",
		fr: "Prochainement"
	},
	HOUSEKEEPING_COMING_SOON_MESSAGE: {
		X: "HOUSEKEEPING_COMING_SOON_MESSAGE",
		es: "¡Hola! Los servicios de limpieza aún no están disponibles para pedidos directos a través de la " +
			"aplicación, pero nuestro conserje con gusto le resolverá.",
		en: "Hi! Housekeeping services aren't yet available for direct order through the app but " +
			"our concierge would gladly sort things out for you.",
		fr: "Salut! Les services de ménage ne sont pas encore disponibles pour une commande directe via l'application, " +
			"mais notre concierge se fera un plaisir de régler les choses pour vous."
	},
	REPAIR_COMING_SOON_MESSAGE: {
		X: "REPAIR_COMING_SOON_MESSAGE",
		es: "¡Hola! Los servicios de reparación, istalación y mantenimiento aún no están disponibles para pedidos " +
			"directos a través de la aplicación, pero nuestro conserje con gusto le resolverá.",
		en: "Hi! Repair, installation, and maintenance services aren't yet available for direct " +
			"order through the app but our concierge will gladly sort things out for you.",
		fr: "Bonjour! Les services de réparation, d'installation et de maintenance ne sont pas encore disponibles " +
			"pour une commande directe via l'application, mais notre concierge se fera un plaisir de régler les choses " +
			"pour vous."
	},
	LISTINGS_COMING_SOON_MESSAGE: {
		X: "LISTINGS_COMING_SOON_MESSAGE",
		es: "¡Hola! Los servicios de listado y mercado aún no están disponibles directamente a través de la aplicación," +
			" pero puede ver nuestra página de listados en paralian.io. Si necesita encontrar una pieza o un " +
			"artículo para su barco, comuníquese con nuestro conserje, quien con gusto le resuelve.",
		en: "Hi! Listing & marketplace services aren't yet available for directly through the app but you may " +
			"still view our listings page on paralian.io. If you need to find a part or item for your boat, contact " +
			"our concierge who will gladly sort things out for you.",
		fr: "Salut! Les services d'inscription et de marché ne sont pas encore disponibles directement via " +
			"l'application, mais vous pouvez toujours consulter notre page d'annonces sur paralian.io. Si vous avez " +
			"besoin de trouver une pièce ou un article pour votre bateau, contactez notre concierge qui se fera un " +
			"plaisir de trier les choses pour vous."
	},
	FINANCING_COMING_SOON_MESSAGE: {
		X: "FINANCING_COMING_SOON_MESSAGE",
		es: "¡Hola! Los servicios de financiación, seguros e inspección aún no están disponibles para pedidos " +
			"directos a través de la aplicación, pero nuestro conserje le resolverá con gusto.",
		en: "Hi! Financing, insurance & surveyor services aren't yet available for direct order through the " +
			"app but our concierge will gladly sort things out for you.",
		fr: "Bonjour! Les services de financement, d'assurance et de géomètre ne sont pas encore disponibles " +
			"pour une commande directe via l'application, mais notre concierge se fera un plaisir de régler les " +
			"choses pour vous."
	},
	CREW_COMING_SOON_MESSAGE: {
		X: "CREW_COMING_SOON_MESSAGE",
		es: "¡Hola! Los servicios de tripulación aún no están disponibles para pedidos directos a través de la " +
			"aplicación, pero nuestro conserje con gusto le resolverá.",
		en: "Hi! Crew services aren't yet available for direct order through the app but our concierge will gladly " +
			"sort things out for you.",
		fr: "Bonjour! Les services de l'équipage ne sont pas encore disponibles pour une commande directe via " +
			"l'application, mais notre concierge se fera un plaisir de régler les choses pour vous."
	},
	VISIT_CONCIERGE: {
		X: "VISIT_CONCIERGE",
		es: "Visitar Concierge",
		en: "Visit Concierge",
		fr: "Visiter Concierge"
	},
	CARPET_DEEP_LEAN: {
		X: "CARPET_DEEP_LEAN",
		es: "Limpieza Profunda de Alfombras",
		en: "Carpet Deep Clean",
		fr: "Nettoyage en Profondeur des Tapis"
	},
	CARPET_DEEP_LEAN_COMING_SOON_MESSAGE: {
		X: "CARPET_DEEP_LEAN_COMING_SOON_MESSAGE",
		es: "¡Hola! Los servicios de limpieza de alfombras aún no están disponibles para pedidos directos a través de " +
			"la aplicación, pero nuestro conserje con gusto le resolverá.",
		en: "Hi! Carpet cleaning services aren't yet available for direct order through the app but " +
			"our concierge would gladly sort things out for you.",
		fr: "Salut! Les services de nettoyage de tapis ne sont pas encore disponibles pour une commande directe " +
			"via l'application, mais notre concierge se fera un plaisir de régler les choses pour vous."
	},
	NO_NOTIFICATIONS: {
		X: "NO_NOTIFICATIONS",
		es: "No hay Notificaciones",
		en: "No Notifications",
		fr: "Aucune Notification"
	},
	NO_ORDERS: {
		X: "NO_ORDERS",
		es: "No hay Pedidos",
		en: "No Orders",
		fr: "Aucune Commande"
	},
	NO_RATING: {
		X: "NO_RATING",
		es: "Sin Evaluar",
		en: "No Rating",
		fr: "Pas de Notation"
	},
	RESOLVED: {
		X: "RESOLVED",
		es: "Resuelto",
		en: "Resolved",
		fr: "Résolu"
	},
	UNRESOLVED: {
		X: "UNRESOLVED",
		es: "Irresoluto",
		en: "Unresolved",
		fr: "Non Résolu"
	},
	TRANSIT: {
		X: "TRANSIT",
		es: "Tránsito",
		en: "Transit",
		fr: "Transit"
	},
	PROVIDER: {
		X: "PROVIDER",
		es: "Proveedor",
		en: "Provider",
		fr: "Fournisseur"
	},
	PROVIDERS: {
		X: "PROVIDERS",
		es: "Proveedores",
		en: "Providers",
		fr: "Fournisseurs"
	},
	MAINTENANCE: {
		X: "MAINTENANCE",
		es: "Mantenimiento",
		en: "Maintenance",
		fr: "Maintenance"
	},
	REPAIR: {
		X: "REPAIR",
		es: "Reparación",
		en: "Repair",
		fr: "Réparation"
	},
	IMPROVEMENT: {
		X: "IMPROVEMENT",
		es: "Mejora",
		en: "Improvement",
		fr: "Amélioration"
	},
	ELECTRICAL: {
		X: "ELECTRICAL",
		es: "Eléctrico",
		en: "Electrical",
		fr: "Électrique"
	},
	HVAC: {
		X: "HVAC",
		es: "Calefacción, ventilación y aire acondicionado",
		en: "Heating, Ventilation, and Air Conditioning",
		fr: "Chauffage, ventilation et air conditionné"
	},
	PLUMBING: {
		X: "PLUMBING",
		es: "Plomería",
		en: "Plumbing",
		fr: "Plomberie"
	},
	PROPULSION: {
		X: "PROPULSION",
		es: "Propulsión",
		en: "Propulsion",
		fr: "Propulsion"
	},
	RIGGING: {
		X: "RIGGING",
		es: "Aparejo",
		en: "Rigging",
		fr: "Gréement"
	},
	NAVIGATION: {
		X: "NAVIGATION",
		es: "Navegación",
		en: "Navigation",
		fr: "Navigation"
	},
	COMMUNICATIONS: {
		X: "COMMUNICATIONS",
		es: "Comunicaciones",
		en: "Communications",
		fr: "Communication"
	},
	SAFETY: {
		X: "SAFETY",
		es: "Precaución",
		en: "Safety",
		fr: "Précaution"
	},
	STEERING: {
		X: "STEERING",
		es: "Direccion",
		en: "Steering",
		fr: "Pilotage"
	},
	SANITATION: {
		X: "SANITATION",
		es: "Saneamiento",
		en: "Sanitation",
		fr: "Assainissement"
	},
	ENTERTAINMENT: {
		X: "ENTERTAINMENT",
		es: "Entretenimiento",
		en: "Entertainment",
		fr: "Divertissement"
	},
	LIGHTING: {
		X: "LIGHTING",
		es: "Iluminación",
		en: "Lighting",
		fr: "Éclairage"
	},
	SECURITY: {
		X: "SECURITY",
		es: "Seguridad",
		en: "Security",
		fr: "Sécurité"
	},
	LIQUEFIED_PROPANE_GAS: {
		X: "LIQUEFIED_PROPANE_GAS",
		es: "Gas Propano Licuado",
		en: "Liquefield Propane Gas",
		fr: "Gaz Propane Liquéfié"
	},
	FLEX_FUEL: {
		X: "FLEX_FUEL",
		es: "Flex-Fuel (E85)",
		en: "Flex-Fuel (E85)",
		fr: "Flex-Fuel (E85)"
	},
	ELECTRIC: {
		X: "ELECTRIC",
		es: "Eléctrico",
		en: "Electric",
		fr: "Électrique"
	},
	KEROSENE: {
		X: "KEROSENE",
		es: "Queroseno",
		en: "Kerosene",
		fr: "Kérosène"
	},
	NATURAL_GAS: {
		X: "NATURAL_GAS",
		es: "Gas Natural",
		en: "Natural Gas",
		fr: "Gaz Naturel"
	},
	VESSEL_MANAGEMENT: {
		X: "VESSEL_MANAGEMENT",
		es: "Gestión de Embarcaciones",
		en: "Vessel Management",
		fr: "Gestion des Navires"
	},
	UPKEEP: {
		X: "UPKEEP",
		es: "Mantenimiento",
		en: "Upkeep",
		fr: "Entretien"
	},
	MAINTENANCE_REPAIR_AND_IMPROVEMENT_RECORDS: {
		X: "MAINTENANCE_REPAIR_AND_IMPROVEMENT_RECORDS",
		es: "Registros de mantenimiento, reparaciones y mejoras",
		en: "Maintenance, Repair, and Improvement Records",
		fr: "Dossiers d'entretien, de réparation et d'amélioration"
	},
	ORGANIZATIONS: {
		X: "ORGANIZATIONS",
		es: "Organizaciones",
		en: "Organizations",
		fr: "Organisations"
	},
	INDIVIDUALS: {
		X: "INDIVIDUALS",
		es: "Individuos",
		en: "Individuals",
		fr: "Personnes"
	},
	COUNTER_ROTATING: {
		X: "COUNTER_ROTATING",
		es: "Rotación Inversa",
		en: "Counter-Rotating",
		fr: "Tournant en Sens Inverse"
	},
	STANDARD: {
		X: "STANDARD",
		es: "Estándar",
		en: "Standard",
		fr: "Norme"
	},
	FUEL_PUMP_DIESEL: {
		X: "FUEL_PUMP_DIESEL",
		es: "Bomba de Diesel",
		en: "Diesel Pump",
		fr: "Pompe à Diesel"
	},
	FUEL_PUMP_PETROL_87: {
		X: "FUEL_PUMP_PETROL_87",
		es: "Bomba de Gasolina de 87 Octanos",
		en: "87 Octane Gasoline (Petrol) Pump",
		fr: "Pompe à Essence à Indice d'Octane 87"
	},
	FUEL_PUMP_PETROL_89: {
		X: "FUEL_PUMP_PETROL_89",
		es: "Bomba de Gasolina de 89 Octanos",
		en: "89 Octane Gasoline (Petrol) Pump",
		fr: "Pompe à Essence à Indice d'Octane 89"
	},
	FUEL_PUMP_PETROL_91: {
		X: "FUEL_PUMP_PETROL_91",
		es: "Bomba de Gasolina de 91 Octanos",
		en: "91 Octane Gasoline (Petrol) Pump",
		fr: "Pompe à Essence à Indice d'Octane 91"
	},
	FUEL_PUMP_PETROL_93: {
		X: "FUEL_PUMP_PETROL_93",
		es: "Bomba de Gasolina de 93 Octanos",
		en: "93 Octane Gasoline (Petrol) Pump",
		fr: "Pompe à Essence à Indice d'Octane 93"
	},
	FUEL_PUMP_KEROSENE: {
		X: "FUEL_PUMP_KEROSENE",
		es: "Bomba de Queroseno",
		en: "Kerosene Pump",
		fr: "Pompe à Kérosène"
	},
	DIESEL: {
		X: "DIESEL",
		es: "Diesel",
		en: "Diesel",
		fr: "Diesel"
	},
	PETROL_87: {
		X: "PETROL_87",
		es: "Gasolina de 87 Octanos",
		en: "87 Octane Gasoline (Petrol)",
		fr: "87 Octane Essence"
	},
	PETROL_89: {
		X: "PETROL_89",
		es: "Gasolina de 89 Octanos",
		en: "89 Octane Gasoline (Petrol)",
		fr: "89 Octane Essence"
	},
	PETROL_91: {
		X: "PETROL_91",
		es: "Gasolina de 91 Octanos",
		en: "91 Octane Gasoline (Petrol)",
		fr: "91 Octane Essence"
	},
	PETROL_93: {
		X: "PETROL_93",
		es: "Gasolina de 93 Octanos",
		en: "93 Octane Gasoline (Petrol)",
		fr: "93 Octane Essence"
	},
	INBOARD: {
		X: "INBOARD",
		es: "Interno",
		en: "Inboard",
		fr: "Inboard"
	},
	OUTBOARD: {
		X: "OUTBOARD",
		es: "Fuera de Borda",
		en: "Outboard",
		fr: "Hors-Bord"
	},
	STERN_DRIVE: {
		X: "STERN_DRIVE",
		es: "de Popa",
		en: "Stern Drive",
		fr: "d'Entraînement Arrière"
	},
	JET_DRIVE: {
		X: "JET_DRIVE",
		es: "Propulsión a Reacción",
		en: "Jet Drive",
		fr: "à Réaction"
	},
	WHEELCHAIR_ACCESSIBLE: {
		X: "WHEELCHAIR_ACCESSIBLE",
		es: "Accesible para Silla de Ruedas",
		en: "Wheelchair Accessible",
		fr: "Accessible aux Fauteuils Roulants"
	},
	PERSONAL_CARE: {
		X: "PERSONAL_CARE",
		es: "Cuidado Personal",
		en: "Personal Care",
		fr: "Soins Personnels"
	},
	PET_FRIENDLY: {
		X: "PET_FRIENDLY",
		es: "Mascotas Permitidas",
		en: "Pet Friendly",
		fr: "Animaux Acceptés"
	},
	PETS: {
		X: "PETS",
		es: "Mascotas",
		en: "Pets",
		fr: "Animaux Domestiques"
	},
	SPORTS: {
		X: "SPORTS",
		es: "Deportes",
		en: "Sports",
		fr: "Sports"
	},
	DOCK: {
		X: "DOCK",
		es: "Muelle",
		en: "Dock",
		fr: "Quai"
	},
	POWER_OUTLET_120V: {
		X: "POWER_OUTLET_120V",
		es: "Toma de Corriente de 120 Voltios",
		en: "120-Volt Power Outlet",
		fr: "Prise de Courant de 120 Volts"
	},
	POWER_OUTLET_240V: {
		X: "POWER_OUTLET_240V",
		es: "Toma de Corriente de 240 Voltios",
		en: "240-Volt Power Outlet",
		fr: "Prise de Courant de 240 Volts"
	},
	POWER_OUTLET_480V: {
		X: "POWER_OUTLET_480V",
		es: "Toma de Corriente de 480 Voltios",
		en: "480-Volt Power Outlet",
		fr: "Prise de Courant de 480 Volts"
	},
	DOCKSIDE_120V_ELECTRICITY: {
		X: "DOCKSIDE_120V_ELECTRICITY",
		es: "Electricidad de 120 Voltios en el Muelle",
		en: "Dockside 120-Volt Electricity",
		fr: "Électricité 120 volts à Quai"
	},
	DOCKSIDE_240V_ELECTRICITY: {
		X: "DOCKSIDE_240V_ELECTRICITY",
		es: "Electricidad de 240 Voltios en el Muelle",
		en: "Dockside 240-Volt Electricity",
		fr: "Électricité 240 volts à Quai"
	},
	DOCKSIDE_480V_ELECTRICITY: {
		X: "DOCKSIDE_480V_ELECTRICITY",
		es: "Electricidad de 480 Voltios en el Muelle",
		en: "Dockside 480-Volt Electricity",
		fr: "Électricité 480 volts à Quai"
	},
	IN_SLIP_FUELING: {
		X: "IN_SLIP_FUELING",
		es: "Entrega de Combustible en el Muelle",
		en: "In-slip Fueling",
		fr: "Livraison de Carburant à Quai"
	},
	DOCKSIDE_FRESH_WATER: {
		X: "DOCKSIDE_FRESH_WATER",
		es: "Agua Potable en el Muelle",
		en: "Dockside Fresh Water",
		fr: "Eau Potable à Quai"
	},
	DOCKSIDE_POWER: {
		X: "DOCKSIDE_POWER",
		es: "Electricidad en el Muelle",
		en: "Dockside Power",
		fr: "Électricité à Quai"
	},
	IN_SLIP_GASOLINE: {
		X: "IN_SLIP_GASOLINE",
		es: "Entrega de Gasolina en el Muelle",
		en: "In-slip Gasoline",
		fr: "Livraison d'Essence à Quai"
	},
	IN_SLIP_DIESEL: {
		X: "IN_SLIP_DIESEL",
		es: "Entrega de Diesel en el Muelle",
		en: "In-slip Diesel",
		fr: "Livraison de Diesel à Quai"
	},
	TENNIS_COURT: {
		X: "TENNIS_COURT",
		es: "Cancha de Tenis",
		en: "Tennis Court",
		fr: "Court de Tennis"
	},
	GOLF_COURSE: {
		X: "GOLF_COURSE",
		es: "Campo de Golf",
		en: "Golf Course",
		fr: "Terrain de Golf"
	},
	BASKETBALL_COURT: {
		X: "BASKETBALL_COURT",
		es: "Cancha de Baloncesto",
		en: "Basketball Court",
		fr: "Terrain de Basketball"
	},
	SWIMMING_POOL: {
		X: "SWIMMING_POOL",
		es: "Piscina",
		en: "Pool",
		fr: "Piscine"
	},
	DIVING_SHOP: {
		X: "DIVING_SHOP",
		es: "Tienda de Buceo",
		en: "Diving Shop",
		fr: "Magasin de Plongée"
	},
	CONVENIENCE_STORE: {
		X: "CONVENIENCE_STORE",
		es: "Tienda de Conveniencia",
		en: "Convenience Store",
		fr: "Épicerie"
	},
	CLUBHOUSE: {
		X: "CLUBHOUSE",
		es: "Casa Club",
		en: "Clubhouse",
		fr: "Pavillon"
	},
	PHARMACY: {
		X: "PHARMACY",
		es: "Farmacia",
		en: "Pharmacy",
		fr: "Pharmacie"
	},
	DOG_GROOMER: {
		X: "DOG_GROOMER",
		es: "Peluquero Canino",
		en: "Dog Groomer",
		fr: "Toiletteur pour Chien"
	},
	VETERINARIAN: {
		X: "VETERINARIAN",
		es: "Veterinario",
		en: "Veterinarian",
		fr: "Vétérinaire"
	},
	PET_HOTEL: {
		X: "PET_HOTEL",
		es: "Hotel de Mascotas",
		en: "Pet Hotel",
		fr: "Hôtel pour Animal de Compagnie"
	},
	DAY_CARE: {
		X: "DAY_CARE",
		es: "Guardería",
		en: "Day Care",
		fr: "Garderie"
	},
	NAIL_SALON: {
		X: "NAIL_SALON",
		es: "Manicura",
		en: "Nail Salon",
		fr: "Salon de Manucure"
	},
	SPA: {
		X: "SPA",
		es: "Spa",
		en: "Spa",
		fr: "Spa"
	},
	HEALTH_CLINIC: {
		X: "HEALTH_CLINIC",
		es: "Clínica de salud",
		en: "Health Clinic",
		fr: "Clinique de santé"
	},
	DENTIST: {
		X: "DENTIST",
		es: "Dentista",
		en: "Dentist",
		fr: "Dentiste"
	},
	BARBER_SHOP: {
		X: "BARBER_SHOP",
		es: "Barbería",
		en: "Barber Shop",
		fr: "Salon de Coiffure"
	},
	HAIR_SALON: {
		X: "HAIR_SALON",
		es: "Peluquería",
		en: "Hair Salon",
		fr: "Salon de Coiffure"
	},
	EVENT_VENUE: {
		X: "EVENT_VENUE",
		es: "Sala de Eventos",
		en: "Event Venue",
		fr: "Lieu de l'Événement"
	},
	CAR_WASH: {
		X: "CAR_WASH",
		es: "Lavado de Autos",
		en: "Car Wash",
		fr: "Lave-Auto"
	},
	LODGING: {
		X: "LODGING",
		es: "Alojamiento",
		en: "Lodging",
		fr: "Hébergement"
	},
	GAME_ROOM: {
		X: "GAME_ROOM",
		es: "Sala de Juego",
		en: "Game Room",
		fr: "Salle de Jeux"
	},
	PLAY_PEN: {
		X: "PLAY_PEN",
		es: "Parque para Niños",
		en: "Playpen",
		fr: "Parc pour Petits Enfants"
	},
	BAIT_SHOP: {
		X: "BAIT_SHOP",
		es: "Tienda de Cebos",
		en: "Bait Shop",
		fr: "Magasin d'Appâts"
	},
	MARINE_STORE: {
		X: "MARINE_STORE",
		es: "Tienda Marina",
		en: "Marine Store",
		fr: "Magasin Marin"
	},
	CONCIERGE_SERVICE: {
		X: "CONCIERGE_SERVICE",
		es: "Servicios de Conserjería",
		en: "Concierge Services",
		fr: "Conciergerie"
	},
	RESTROOMS: {
		X: "RESTROOMS",
		es: "Baños",
		en: "Restrooms",
		fr: "Toilettes"
	},
	SHOWERS: {
		X: "SHOWERS",
		es: "Duchas",
		en: "Showers",
		fr: "Douches"
	},
	HELIPORT: {
		X: "HELIPORT",
		es: "Helipuerto",
		en: "Heliport",
		fr: "Héliport"
	},
	CUSTOMS_OFFICE: {
		X: "CUSTOMS_OFFICE",
		es: "Oficina de Aduana",
		en: "Customs Office",
		fr: "Bureau de Douane"
	},
	ICE: {
		X: "ICE",
		es: "Hielo",
		en: "Ice",
		fr: "Glace"
	},
	INTERNAL_TRANSPORT: {
		X: "INTERNAL_TRANSPORT",
		es: "Transporte Interno",
		en: "Internal Transport",
		fr: "Transport Interne"
	},
	LAUNDROMAT: {
		X: "LAUNDROMAT",
		es: "Lavandería",
		en: "Laundromat",
		fr: "Laverie"
	},
	DRY_CLEANERS: {
		X: "DRY_CLEANERS",
		es: "Tintorería",
		en: "Dry Cleaners",
		fr: "Nettoyeurs à Sec"
	},
	FUEL_STATION: {
		X: "FUEL_STATION",
		es: "Gasolinera",
		en: "Fuel Station",
		fr: "Station Essence"
	},
	BOAT_RAMP: {
		X: "BOAT_RAMP",
		es: "Rampa para Botes",
		en: "Boat Ramp",
		fr: "Rampe de Bateau"
	},
	WASTE_WATER_DISPOSAL: {
		X: "WASTE_WATER_DISPOSAL",
		es: "Eliminación de Aguas Residuales",
		en: "Wastewater Disposal",
		fr: "Élimination des Eaux Usées"
	},
	OIL_DISPOSAL: {
		X: "OIL_DISPOSAL",
		es: "Eliminación de Aceite",
		en: "Oil Disposal",
		fr: "Élimination de l'huile"
	},
	SOLID_WASTE_DISPOSAL: {
		X: "SOLID_WASTE_DISPOSAL",
		es: "Eliminación de Desechos Sólidos",
		en: "Solid Waste Disposal",
		fr: "Élimination des Déchets Solides"
	},
	ATM: {
		X: "ATM",
		es: "Cajero Automático",
		en: "ATM",
		fr: "Distributeur de Billets"
	},
	BUSINESS_CENTER: {
		X: "BUSINESS_CENTER",
		es: "Centro de Negocios",
		en: "Business Center",
		fr: "Centre d'Affaires"
	},
	FRONT_DESK: {
		X: "FRONT_DESK",
		es: "Oficina de Recepción",
		en: "Front Desk",
		fr: "Réception"
	},
	GYM: {
		X: "GYM",
		es: "Gimnasio",
		en: "Gym",
		fr: "Gym"
	},
	VALET_PARKING: {
		X: "VALET_PARKING",
		es: "Servicio de Estacionamiento",
		en: "Valet Parking",
		fr: "Service de Voiturier"
	},
	BAR: {
		X: "BAR",
		es: "Bar",
		en: "Bar",
		fr: "Bar"
	},
	RESTAURANT: {
		X: "RESTAURANT",
		es: "Restaurante",
		en: "Restaurant",
		fr: "Restaurant"
	},
	PARKING: {
		X: "PARKING",
		es: "Estacionamiento",
		en: "Parking",
		fr: "Stationnement"
	},
	RIG: {
		X: "RIG",
		es: "Aparejo",
		en: "Rig",
		fr: "Gréement"
	},
	CHANGE_PASSWORD: {
		X: "CHANGE_PASSWORD",
		es: "Cambiar Contraseña",
		en: "Change Password",
		fr: "Changer le Mot de Passe"
	},
	CHANGE_USERNAME: {
		X: "CHANGE_USERNAME",
		es: "Cambiar Nombre de Usuario",
		en: "Change Username",
		fr: "Changer le Nom d'Utilisateur"
	},
	CHANGE_NAME: {
		X: "CHANGE_NAME",
		es: "Cambiar Nombre",
		en: "Change Name",
		fr: "Changer de Nom"
	},
	DELETE_ACCOUNT: {
		X: "DELETE_ACCOUNT",
		es: "Borrar Cuenta",
		en: "Delete Account",
		fr: "Supprimer le Compte"
	},
	LOCK_ACCOUNT: {
		X: "LOCK_ACCOUNT",
		es: "Bloquear Cuenta",
		en: "Lock Account",
		fr: "Verrouiller le Compte"
	},
	FIX_PRICES: {
		X: "FIX_PRICES",
		es: "Fijar Precios",
		en: "Fix Prices",
		fr: "Fixer le Prix"
	},
	PUBLICATION_DATE: {
		X: "PUBLICATION_DATE",
		es: "Fecha de Publicación",
		en: "Publication Date",
		fr: "Date de Publication"
	},
	CALL_SIGN: {
		X: "CALL_SIGN",
		es: "Señal de Llamada",
		en: "Call Sign",
		fr: "Signe d'Appel"
	},
	IMO_NUMBER: {
		X: "IMO_NUMBER",
		es: "Número de IMO",
		en: "IMO Number",
		fr: "Numéro de IMO"
	},
	MMSI_NUMBER: {
		X: "MMSI_NUMBER",
		es: "Número de MMSI",
		en: "MMSI Number",
		fr: "Numéro de MMSI"
	},
	DOCUMENTATION_NUMBER: {
		X: "DOCUMENTATION_NUMBER",
		es: "Número de Documentación",
		en: "Documentation Number",
		fr: "Numéro de Documentation"
	},
	DOCUMENTATION_AUTHORITY: {
		X: "DOCUMENTATION_AUTHORITY",
		es: "Autoridad de Documentación",
		en: "Documentation Authority",
		fr: "Autorité de Documentation"
	},
	TAG_NUMBER: {
		X: "TAG_NUMBER",
		es: "Número de Etiqueta",
		en: "Tag Number",
		fr: "Numéro d'Étiquette"
	},
	TITLE_NUMBER: {
		X: "TITLE_NUMBER",
		es: "Número de Título",
		en: "Title Number",
		fr: "Numéro de Titre"
	},
	TITLING_AUTHORITY: {
		X: "TITLING_AUTHORITY",
		es: "Autoridad de Titulación",
		en: "Titling Authority",
		fr: "Autorité de titrage"
	},
	POLICY_NUMBER: {
		X: "POLICY_NUMBER",
		es: "Número de Póliza",
		en: "Policy Number",
		fr: "Numéro de Police"
	},
	AMOUNT: {
		X: "AMOUNT",
		es: "Monto",
		en: "Amount",
		fr: "Montant"
	},
	BOND_NUMBER: {
		X: "BOND_NUMBER",
		es: "Número de Bono",
		en: "Bond Number",
		fr: "Numéro d'Obligation"
	},
	ISSUE_DATE: {
		X: "ISSUE_DATE",
		es: "Fecha de Emisión",
		en: "Issue Date",
		fr: "Date d'Émission"
	},
	PURCHASE_DATE: {
		X: "PURCHASE_DATE",
		es: "Fecha de Compra",
		en: "Purchase Date",
		fr: "Date d'Achat"
	},
	PURCHASE: {
		X: "PURCHASE",
		es: "Comprar",
		en: "Purchase",
		fr: "Acheter"
	},
	CREDITS: {
		X: "CREDITS",
		es: "Créditos",
		en: "Credits",
		fr: "Crédits"
	},
	ORDER_CREDITS: {
		X: "ORDER_CREDITS",
		es: "Créditos de Pedido",
		en: "Order Credits",
		fr: "Crédits de Commande"
	},
	CUSTOMER_CREDITS: {
		X: "CUSTOMER_CREDITS",
		es: "Créditos de Clientes",
		en: "Customer Credits",
		fr: "Crédits Client"
	},
	SIGN_UP: {
		X: "SIGN_UP",
		es: "Inscribirse",
		en: "Sign Up",
		fr: "S'inscrire"
	},
	UNLOAD: {
		X: "UNLOAD",
		es: "Descargar",
		en: "Unload",
		fr: "Décharger"
	},
	PURPOSE: {
		X: "PURPOSE",
		es: "Propósito",
		en: "Purpose",
		fr: "Objectif"
	},
	CERTIFYING_ENTITY: {
		X: "CERTIFYING_ENTITY",
		es: "Entidad Certificadora",
		en: "Certifying Entity",
		fr: "Entité de Certification"
	},
	CERTIFICATE_TYPE: {
		X: "CERTIFICATE_TYPE",
		es: "Tipo de Certificado",
		en: "Certificate Type",
		fr: "Type de Certificat"
	},
	TYPE: {
		X: "TYPE",
		es: "Tipo",
		en: "Type",
		fr: "Type"
	},
	NOTES: {
		X: "NOTES",
		es: "Notas",
		en: "Notes",
		fr: "Remarques"
	},
	VENDOR: {
		X: "VENDOR",
		es: "Vendedor",
		en: "Vendor",
		fr: "Vendeur"
	},
	CERTIFICATION_DATE: {
		X: "CERTIFICATION_DATE",
		es: "Fecha de Certificación",
		en: "Certification Date",
		fr: "Date de certification"
	},
	CERTIFICATE_NUMBER: {
		X: "CERTIFICATE_NUMBER",
		es: "Número de Certificado",
		en: "Certificate Number",
		fr: "Numéro de Certificat"
	},
	REGISTRATION_NUMBER: {
		X: "REGISTRATION_NUMBER",
		es: "Número de Registro",
		en: "Registration Number",
		fr: "Numéro d'Enregistrement"
	},
	REGISTRATION_AUTHORITY: {
		X: "REGISTRATION_AUTHORITY",
		es: "Autoridad de Registro",
		en: "Registration Authority",
		fr: "Autorité d'Enregistrement"
	},
	INSURANCE_AGENCY: {
		X: "INSURANCE_AGENCY",
		es: "Agencia de Seguros",
		en: "Insurance Agency",
		fr: "Agence d'Assurance"
	},
	ISSUING_ORGANIZATION: {
		X: "ISSUING_ORGANIZATION",
		es: "Organización Emisora",
		en: "Issuing Organization",
		fr: "organisme Émetteur"
	},
	COUNTRY_OF_ISSUE: {
		X: "COUNTRY_OF_ISSUE",
		es: "País de Emisión",
		en: "Country of Issue",
		fr: "Pays d'Émission"
	},
	COUNTRY_OF_REGISTRATION: {
		X: "COUNTRY_OF_REGISTRATION",
		es: "Pais de Registro",
		en: "Country of Registration",
		fr: "Pays d'Enregistrement"
	},
	OR_EQUIVALENT: {
		X: "OR_EQUIVALENT",
		es: "o equivalente",
		en: "or equivalent",
		fr: "ou équivalent"
	},
	ISSUED: {
		X: "ISSUED",
		es: "Emitido",
		en: "Issued",
		fr: "Publié"
	},
	STANDARD_WEEK: {
		X: "STANDARD_WEEK",
		es: "Semana Estándar",
		en: "Standard Week",
		fr: "Semaine Standard"
	},
	EDIT_STANDARD_WEEK: {
		X: "EDIT_STANDARD_WEEK",
		es: "Editar Semana Estándar",
		en: "Edit Standard Week",
		fr: "Modifier la Semaine Standard"
	},
	EDIT_ADDRESS: {
		X: "EDIT_ADDRESS",
		es: "Editar Dirección",
		en: "Edit Address",
		fr: "Modifier l'Adresse"
	},
	UNDO: {
		X: "UNDO",
		es: "Anular",
		en: "Undo",
		fr: "Annuler"
	},
	APPROVE_ALL: {
		X: "APPROVE_ALL",
		es: "Aprobar Todos",
		en: "Approve All",
		fr: "Tout Approuver"
	},
	SHOW: {
		X: "SHOW",
		es: "Mostrar",
		en: "Show",
		fr: "Montrer"
	},
	SHARE_WITH_CUSTOMERS: {
		X: "SHARE_WITH_CUSTOMERS",
		es: "Compartir con los Clientes",
		en: "Share with Customers",
		fr: "Partager avec les Clients"
	},
	SHARE: {
		X: "SHARE",
		es: "Compartir",
		en: "Share",
		fr: "Partager"
	},
	CUSTOMERS: {
		X: "CUSTOMERS",
		es: "Clientes",
		en: "Customers",
		fr: "Clients"
	},
	CUSTOMER: {
		X: "CUSTOMER",
		es: "Cliente",
		en: "Customer",
		fr: "Client"
	},
	DEACTIVATED: {
		X: "DEACTIVATED",
		es: "Desactivado",
		en: "Deactivated",
		fr: "désactivé"
	},
	ACTIVE: {
		X: "ACTIVE",
		es: "Activo",
		en: "Active",
		fr: "Actif"
	},
	FROM: {
		X: "FROM",
		es: "de",
		en: "from",
		fr: "de"
	},
	NO_ACCESS: {
		X: "NO_ACCESS",
		es: "Sin Acceso",
		en: "No Access",
		fr: "Pas d'Accès"
	},
	ASSIGN_ORDER: {
		X: "ASSIGN_ORDER",
		es: "Asignar Orden",
		en: "Assign Order",
		fr: "Attribuer une Commande"
	},
	ASSIGN: {
		X: "ASSIGN",
		es: "Asignar",
		en: "Assign",
		fr: "Attribuer"
	},
	PLACE_ORDER: {
		X: "PLACE_ORDER",
		es: "Realizar Pedido",
		en: "Place Order",
		fr: "Passer la Commande"
	},
	ACCEPT_REJECT: {
		X: "ACCEPT_REJECT",
		es: "Aceptar/Rechazar",
		en: "Accept/Reject",
		fr: "Accepter/Rejeter"
	},
	REQUIRED_DOCUMENTS: {
		X: "REQUIRED_DOCUMENTS",
		es: "Documentos Requeridos",
		en: "Required Documents",
		fr: "Documents Requis"
	},
	ADD_REQUIRED_DOCUMENT: {
		X: "ADD_REQUIRED_DOCUMENT",
		es: "Añadir Documento Requerido",
		en: "Add Required Document",
		fr: "Ajouter le Document Requis"
	},
	REPLACE: {
		X: "REPLACE",
		es: "Reemplazar",
		en: "Replace",
		fr: "Remplacer"
	},
	REPLACE_DOCUMENT: {
		X: "REPLACE_DOCUMENT",
		es: "Reemplazar Documento",
		en: "Replace Document",
		fr: "Remplacer le Document"
	},
	ALL: {
		X: "ALL",
		es: "Todos",
		en: "All",
		fr: "Tout"
	},
	NONE: {
		X: "NONE",
		es: "Ninguno",
		en: "None",
		fr: "Rien"
	},
	NONE_PROVIDED: {
		X: "NONE_PROVIDED",
		es: "Ninguno Proporcionado",
		en: "None Provided",
		fr: "Aucun Fourni"
	},
	PROVIDED: {
		X: "PROVIDED",
		es: "Proporcionado",
		en: "Provided",
		fr: "Fourni"
	},
	FAVORITE: {
		X: "FAVORITE",
		es: "Favorito",
		en: "Favorite",
		fr: "Préféré"
	},
	MAKE_RECURRING: {
		X: "MAKE_RECURRING",
		es: "Hacer Recurrente",
		en: "Make Recurring",
		fr: "Rendre Récurrent"
	},
	EDIT_RECURRENCE: {
		X: "EDIT_RECURRENCE",
		es: "Modificar Recurrencia",
		en: "Edit Recurrence",
		fr: "Modifier la Récurrence"
	},
	CANCEL_RECURRENCE: {
		X: "CANCEL_RECURRENCE",
		es: "Cancelar Recurrencia",
		en: "Cancel Recurrence",
		fr: "Annuler la Récurrence"
	},
	DO_NOT_CHARGE_CUSTOMER: {
		X: "DO_NOT_CHARGE_CUSTOMER",
		es: "No Cobrar al Cliente",
		en: "Don't Charge Customer",
		fr: "Ne pas Facturer le Client"
	},
	REQUEST_FULL_PAYMENT: {
		X: "REQUEST_FULL_PAYMENT",
		es: "Solicitar Pago Completo",
		en: "Request Full Payment",
		fr: "Demander le Paiement Intégral"
	},
	REQUEST_PARTIAL_PAYMENT: {
		X: "REQUEST_PARTIAL_PAYMENT",
		es: "Solicitar Pago Parcial",
		en: "Request Partial Payment",
		fr: "Demander un Paiement Partiel"
	},
	RECURRENCE: {
		X: "RECURRENCE",
		es: "Recurrencia",
		en: "Recurrence",
		fr: "Récurrence"
	},
	RECURRING_ORDER: {
		X: "RECURRING_ORDER",
		es: "Orden Recurrente",
		en: "Recurring Order",
		fr: "Commande Récurrente"
	},
	ACCEPT: {
		X: "ACCEPT",
		es: "Aceptar",
		en: "Accept",
		fr: "Accepter"
	},
	REJECT: {
		X: "REJECT",
		es: "Rechazar",
		en: "Reject",
		fr: "Rejeter"
	},
	INTERNAL: {
		X: "INTERNAL",
		es: "Interno",
		en: "Internal",
		fr: "Interne"
	},
	INTERNAL_WITHIN_MARINA: {
		X: "INTERNAL_WITHIN_MARINA",
		es: "Interno (dentro de la marina)",
		en: "Internal (within marina)",
		fr: "Interne (dans la marina)"
	},
	EXTERNAL: {
		X: "EXTERNAL",
		es: "Externo",
		en: "External",
		fr: "Externe"
	},
	ADMIN: {
		X: "ADMIN",
		es: "Administrador",
		en: "Administrator",
		fr: "Administrateur"
	},
	ADMINS: {
		X: "ADMINS",
		es: "Administradores de la Cuenta",
		en: "Account Administrators",
		fr: "Administrateur de Compte"
	},
	EMPLOYEE: {
		X: "EMPLOYEE",
		es: "Empleado",
		en: "Employee",
		fr: "Employé"
	},
	ACCESS_LEVEL: {
		X: "ACCESS_LEVEL",
		es: "Nivel de Acceso",
		en: "Access Level",
		fr: "Niveau d'Accès"
	},
	OWNER: {
		X: "OWNER",
		es: "Propietario",
		en: "Owner",
		fr: "propriétaire"
	},
	IS_ORGANIZATION: {
		X: "IS_ORGANIZATION",
		es: "dueño es una organización",
		en: "owner is an organization",
		fr: "propriétaire est une organisation"
	},
	IS_OWNER: {
		X: "IS_OWNER",
		es: "Es Dueño",
		en: "Is Owner",
		fr: "Son Propriétaire"
	},
	IS_NOT_OWNER: {
		X: "IS_NOT_OWNER",
		es: "No es Propietario",
		en: "Is not Owner",
		fr: "N'est pas Propriétaire"
	},
	DATA_ACCESS: {
		X: "DATA_ACCESS",
		es: "Acceso a datos",
		en: "Data Access",
		fr: "Accès aux Données"
	},
	DOCUMENT_VIEW_ACCESS: {
		X: "DOCUMENT_VIEW_ACCESS",
		es: "Acceso a Ver Documentos",
		en: "Document View Access",
		fr: "Accès à la Vue du Document"
	},
	PROFILE_VIEW_ACCESS: {
		X: "PROFILE_VIEW_ACCESS",
		es: "Acceso Ver el Perfil",
		en: "Profile View Access",
		fr: "Accès à la Vue de Profil"
	},
	PHYSICAL_ACCESS: {
		X: "PHYSICAL_ACCESS",
		es: "Acceso Físico",
		en: "Physical Access",
		fr: "Accès Physique"
	},
	MOVE_WITHIN_PREMISES: {
		X: "MOVE_WITHIN_PREMISES",
		es: "Moverse detro de las Instalaciones",
		en: "Move within Premises",
		fr: "Déplacer dans les Locaux"
	},
	LEAVE_PREMISES: {
		X: "LEAVE_PREMISES",
		es: "Salir de las Instalaciones",
		en: "Leave Premises",
		fr: "Quitter les Locaux"
	},
	BOARD: {
		X: "BOARD",
		es: "Abordar",
		en: "Board",
		fr: "Monter à Bord"
	},
	SERVICE: {
		X: "SERVICE",
		es: "Mantener/Arreglar",
		en: "Service",
		fr: "Viabiliser"
	},
	DENIED: {
		X: "DENIED",
		es: "Denegado",
		en: "Denied",
		fr: "Dénié"
	},
	ACCESS: {
		X: "ACCESS",
		es: "Acceso",
		en: "Access",
		fr: "Accès"
	},
	REVIEWS: {
		X: "REVIEWS",
		es: "Reseñas",
		en: "Reviews",
		fr: "Avis"
	},
	PROVIDE_ACCESS: {
		X: "PROVIDE_ACCESS",
		es: "Proveer Acceso",
		en: "Provide Access",
		fr: "Fournir l'Accès"
	},
	GRANT_ACCESS: {
		X: "GRANT_ACCESS",
		es: "Conceder Acceso",
		en: "Grant Access",
		fr: "Accorder l'Accès"
	},
	IMAGE: {
		X: "IMAGE",
		es: "Imagen",
		en: "Image",
		fr: "Image"
	},
	ICON: {
		X: "ICON",
		es: "Icono",
		en: "Icon",
		fr: "Icône"
	},
	LOGO: {
		X: "LOGO",
		es: "Logo",
		en: "Logo",
		fr: "Logo"
	},
	SKIP: {
		X: "SKIP",
		es: "Saltar",
		en: "Skip",
		fr: "Sauter"
	},
	ADD_DOCUMENT: {
		X: "ADD_DOCUMENT",
		es: "Agregar Documento",
		en: "Add Document",
		fr: "Ajouter un Document"
	},
	FILE_UPLOAD: {
		X: "FILE_UPLOAD",
		es: "Subir Archivo",
		en: "File Upload",
		fr: "Télécharger"
	},
	UPLOAD: {
		X: "UPLOAD",
		es: "Subir",
		en: "Upload",
		fr: "Téléchargement de Fichiers"
	},
	UPLOAD_NEW: {
		X: "UPLOAD_NEW",
		es: "Subir Nuevo",
		en: "Upload New",
		fr: "Téléchargement Nouveau"
	},
	DOCUMENTS: {
		X: "DOCUMENTS",
		es: "Documentos",
		en: "Documents",
		fr: "Documents"
	},
	DOCUMENT: {
		X: "DOCUMENT",
		es: "Documento",
		en: "Document",
		fr: "Document"
	},
	DOCUMENT_TYPE: {
		X: "DOCUMENT_TYPE",
		es: "Tipo de Documento",
		en: "Document Type",
		fr: "Type de Document"
	},
	DOCUMENT_CATEGORY: {
		X: "DOCUMENT_CATEGORY",
		es: "Categoría de Documento",
		en: "Document Category",
		fr: "Catégorie de Document"
	},
	PER: {
		X: "PER",
		es: "por",
		en: "per",
		fr: "par"
	},
	COMPOSITE_PRICING: {
		X: "COMPOSITE_PRICING",
		es: "Precios Computestos",
		en: "Composite Pricing",
		fr: "Tarification Composite"
	},
	PRICING: {
		X: "PRICING",
		es: "Precios",
		en: "Pricing",
		fr: "Tarification"
	},
	HOURS_OF_OPERATION: {
		X: "HOURS_OF_OPERATION",
		es: "Horario de Trabajo",
		en: "Hours of Operation",
		fr: "Heures d'Ouverture"
	},
	BRAND_COLORS: {
		X: "BRAND_COLORS",
		es: "Colores de Marca",
		en: "Brand Colors",
		fr: "Couleurs de Marque"
	},
	SERVICES: {
		X: "SERVICES",
		es: "Servicios",
		en: "Services",
		fr: "Services"
	},
	ADD_MARINA: {
		X: "ADD_MARINA",
		es: "Agregar Marina",
		en: "Add Marina",
		fr: "Ajouter une Marina"
	},
	ADD_SERVICE: {
		X: "ADD_SERVICE",
		es: "Agregar Servicio",
		en: "Add Service",
		fr: "Ajouter un Service"
	},
	RESTRICT_BY_COUNTRY: {
		X: "RESTRICT_BY_COUNTRY",
		es: "Restringir por País",
		en: "Restrict by Country",
		fr: "Restreindre par Pays"
	},
	LIMIT_TO_STATE: {
		X: "LIMIT_TO_STATE",
		es: "Limitar a Estado",
		en: "Limit to State",
		fr: "Limiter à l'Etat"
	},
	LIMIT_TO_REGION: {
		X: "LIMIT_TO_REGION",
		es: "Limitar a Región",
		en: "Limit to Region",
		fr: "Limiter à la Région"
	},
	MILES: {
		X: "MILES",
		es: "Millas",
		en: "Miles",
		fr: "Miles"
	},
	SUBSCRIPTION_INTERVAL: {
		X: "SUBSCRIPTION_INTERVAL",
		es: "Intervalo de Suscripción",
		en: "Subscription Inverval",
		fr: "Intervalle d'Abonnement"
	},
	KILOMETERS: {
		X: "KILOMETERS",
		es: "Kilómetros",
		en: "Kilometers",
		fr: "Kilomètres"
	},
	PROXIMITY_SEARCH: {
		X: "PROXIMITY_SEARCH",
		es: "Búsqueda de Proximidad",
		en: "Proximity Search",
		fr: "Recherche de Proximité"
	},
	LIMITED_TO: {
		X: "LIMITED_TO",
		es: "Limitado a",
		en: "Limited to",
		fr: "Limité à"
	},
	LIMIT: {
		X: "LIMIT",
		es: "Límite",
		en: "Limit",
		fr: "Limite"
	},
	NO_LIMIT_APPLIED: {
		X: "NO_LIMIT_APPLIED",
		es: "Sin Límite Aplicado",
		en: "No Limit Applied",
		fr: "Aucune Limite Appliquée"
	},
	DISTANCE: {
		X: "DISTANCE",
		es: "Distancia",
		en: "Distance",
		fr: "Distance"
	},
	EDIT_ADMIN: {
		X: "EDIT_ADMIN",
		es: "Editar Administrador",
		en: "Edit Administrator",
		fr: "Modifier l'Administrateur"
	},
	EDIT_EMPLOYEE: {
		X: "EDIT_EMPLOYEE",
		es: "Editar Empleado",
		en: "Edit Employee",
		fr: "Modifier l'Employé"
	},
	EDIT_SERVICE: {
		X: "EDIT_SERVICE",
		es: "Editar Servicio",
		en: "Edit Service",
		fr: "Modifier le Service"
	},
	EDIT_SERVICE_AREA: {
		X: "EDIT_SERVICE_AREA",
		es: "Editar Área de Servicio",
		en: "Edit Service Area",
		fr: "Modifier la Zone de Service"
	},
	EDIT_LOCATION: {
		X: "EDIT_LOCATION",
		es: "Editar Ubicación",
		en: "Edit Location",
		fr: "Modifier l'Emplacement"
	},
	PARALIAN_PRODUCTS: {
		X: "PARALIAN_PRODUCTS",
		es: "Productos Paralian",
		en: "Paralian Products",
		fr: "Produits Paralian"
	},
	PRODUCT: {
		X: "PRODUCT",
		es: "Producto",
		en: "Product",
		fr: "Produit"
	},
	EDIT_PRODUCT: {
		X: "EDIT_PRODUCT",
		es: "Editar Producto",
		en: "Edit Subscription",
		fr: "Modifier le Produit"
	},
	PERSONAL_PREMIUM: {
		X: "PERSONAL_PREMIUM",
		es: "Membresia Premium",
		en: "Premium Membership",
		fr: "Abonnement Premium"
	},
	PERSONAL_SUBSCRIPTION: {
		X: "PERSONAL_SUBSCRIPTION",
		es: "Membresia Premium",
		en: "Premium Membership",
		fr: "Abonnement Premium"
	},
	ORGANIZATION_CUSTOMER_TOKEN: {
		X: "ORGANIZATION_CUSTOMER_TOKEN",
		es: "Tokens de Cliente",
		en: "Customer Tokens",
		fr: "Jetons Client"
	},
	ORGANIZATION_ORDER_TOKEN: {
		X: "ORGANIZATION_ORDER_TOKEN",
		es: "Tokens de Pedido",
		en: "Order Tokens",
		fr: "Jetons de Commande"
	},
	SERVICE_AREA: {
		X: "SERVICE_AREA",
		es: "Área de Servicio",
		en: "Service Area",
		fr: "Zone de Service"
	},
	WEBSITE: {
		X: "WEBSITE",
		es: "Sitio Web",
		en: "Website",
		fr: "Site Internet"
	},
	FOOT: {
		X: "FOOT",
		es: "Pie",
		en: "Foot",
		fr: "Pied"
	},
	METER: {
		X: "METER",
		es: "Metro",
		en: "Meter",
		fr: "Mètre"
	},
	EXAMPLE: {
		X: "EXAMPLE",
		es: "Ejemplo",
		en: "Example",
		fr: "Exemple"
	},
	MINIMUM: {
		X: "MINIMUM",
		es: "Mínimo",
		en: "Minimum",
		fr: "Minimum"
	},
	TIME_ZONE: {
		X: "TIME_ZONE",
		es: "Zona Horaria",
		en: "Timezone",
		fr: "Fuseau Horaire"
	},
	TIME: {
		X: "TIME",
		es: "Hora",
		en: "Time",
		fr: "Heure"
	},
	CURRENCY: {
		X: "CURRENCY",
		es: "Moneda",
		en: "Currency",
		fr: "Devise"
	},
	YEARLY_RECURRENCE: {
		X: "YEARLY_RECURRENCE",
		es: "Recurrencia Anual",
		en: "Yearly Recurrence",
		fr: "Récurrence Annuelle"
	},
	OR: {
		X: "OR",
		es: "o",
		en: "or",
		fr: "ou"
	},
	OF: {
		X: "OF",
		es: "de",
		en: "of",
		fr: "de"
	},
	AT: {
		X: "AT",
		es: "a las",
		en: "at",
		fr: "à"
	},
	ON: {
		X: "ON",
		es: "el",
		en: "on",
		fr: "le"
	},
	ON_THE: {
		X: "ON_THE",
		es: "el",
		en: "on the",
		fr: "le"
	},
	EVERY: {
		X: "EVERY",
		es: "Cada",
		en: "Every",
		fr: "Toutes"
	},
	FIRST: {
		X: "FIRST",
		es: "Primer",
		en: "First",
		fr: "Première"
	},
	SECOND: {
		X: "SECOND",
		es: "Segundo",
		en: "Second",
		fr: "Seconde"
	},
	THIRD: {
		X: "THIRD",
		es: "Tercer",
		en: "Third",
		fr: "Troisième"
	},
	FORTH: {
		X: "FORTH",
		es: "Cuarto",
		en: "Forth",
		fr: "Quatrième"
	},
	LAST: {
		X: "LAST",
		es: "Último",
		en: "Last",
		fr: "Dernier"
	},
	ADD_HOLIDAY: {
		X: "ADD_HOLIDAY",
		es: "Agregar día Feriado",
		en: "Add Holiday",
		fr: "Ajouter des Vacances"
	},
	ADD_ADMIN: {
		X: "ADD_ADMIN",
		es: "Agregar Administrador",
		en: "Add Administrator",
		fr: "Ajouter un Administrateur"
	},
	ADD_EMPLOYEE: {
		X: "ADD_EMPLOYEE",
		es: "Agregar Empleado",
		en: "Add Employee",
		fr: "Ajouter un Employé"
	},
	EDIT_HOLIDAY: {
		X: "EDIT_HOLIDAY",
		es: "Editar día Feriado",
		en: "Edit Holiday",
		fr: "Modifier des Vacances"
	},
	HOLIDAY: {
		X: "HOLIDAY",
		es: "Día Feriado",
		en: "Holiday",
		fr: "Vacances"
	},
	HOLIDAYS: {
		X: "HOLIDAYS",
		es: "Días Feriados",
		en: "Holidays",
		fr: "Vacances"
	},
	NOT_VERIFIED: {
		X: "NOT_VERIFIED",
		es: "Sin Verificar",
		en: "Not Verified",
		fr: "Non Vérifié"
	},
	OPEN_24_HR: {
		X: "OPEN_24_HR",
		es: "Abierto 24 Horas",
		en: "Open 24 Hours",
		fr: "Ouvert 24 Heures"
	},
	OPEN: {
		X: "OPEN",
		es: "Apertura",
		en: "Open",
		fr: "Ouverture"
	},
	CLOSE: {
		X: "CLOSE",
		es: "Cerrar",
		en: "Close",
		fr: "Fermeture"
	},
	OPEN_ALT: {
		X: "OPEN_ALT",
		es: "Abierto",
		en: "Open",
		fr: "Ouvert"
	},
	CLOSED: {
		X: "CLOSED",
		es: "Cerrado",
		en: "Closed",
		fr: "Fermé"
	},
	MILITARY_TIME: {
		X: "MILITARY_TIME",
		es: "Reloj de 24 Horas",
		en: "24-Hr Clock",
		fr: "Horloge de 24 Heures"
	},
	TIME_FORMAT: {
		X: "TIME_FORMAT",
		es: "Formato de Tiempo",
		en: "Time Format",
		fr: "Format de l'Heure"
	},
	EDIT_PRIMARY_COLOR: {
		X: "EDIT_PRIMARY_COLOR",
		es: "Editar Color Primario",
		en: "Edit Primary Color",
		fr: "Modifier la Couleur Primaire"
	},
	PRIMARY_COLOR: {
		X: "PRIMARY_COLOR",
		es: "Color Primario",
		en: "Primary Color",
		fr: "Couleur Primaire"
	},
	SECONDARY: {
		X: "SECONDARY",
		es: "Secundario",
		en: "Secondary",
		fr: "Secondaire"
	},
	EDIT_SECONDARY_COLOR: {
		X: "EDIT_SECONDARY_COLOR",
		es: "Editar Color Secundario",
		en: "Edit Secondary Color",
		fr: "Modifier la Couleur Secondaire"
	},
	SECONDARY_COLOR: {
		X: "SECONDARY_COLOR",
		es: "Color Secundario",
		en: "Secondary Color",
		fr: "Couleur Secondaire"
	},
	SOLE_PROPRIETORSHIP: {
		X: "SOLE_PROPRIETORSHIP",
		es: "Propietario Único",
		en: "Sole Proprietorship",
		fr: "Entreprise Individuelle"
	},
	TAX_EXEMPT_GOVERNMENT_INSTRUMENTALITY: {
		X: "TAX_EXEMPT_GOVERNMENT_INSTRUMENTALITY",
		es: "Instrumentalidad Gubernamental Exenta de Impuestos",
		en: "Tax Exempt Government Instrumentality",
		fr: "Instrumentation Gouvernementale Exonérée d'Impôt"
	},
	UNINCORPORATED_ASSOCIATION: {
		X: "UNINCORPORATED_ASSOCIATION",
		es: "Asociación no Incorporada",
		en: "Unincorporated Association",
		fr: "Organisation non Constituée en Société"
	},
	UNINCORPORATED_NON_PROFIT: {
		X: "UNINCORPORATED_NON_PROFIT",
		es: "Sin Fines de Lucro no Incorporada",
		en: "Unincorporated Non-Profit",
		fr: "Sans but Lucratif non Constituée en Société"
	},
	SINGLE_MEMBER_LLC: {
		X: "SINGLE_MEMBER_LLC",
		es: "LLC de un Solo Miembro",
		en: "Single Member LLC",
		fr: "LLC un Membre"
	},
	PUBLIC_PARTNERSHIP: {
		X: "PUBLIC_PARTNERSHIP",
		es: "Asociación Pública",
		en: "Public Partnership",
		fr: "Partenariat Public"
	},
	PUBLIC_CORPORATION: {
		X: "PUBLIC_CORPORATION",
		es: "Corporación Pública",
		en: "Public Corporation",
		fr: "Société Publique"
	},
	PUBLIC_COMPANY: {
		X: "PUBLIC_COMPANY",
		es: "Compañía Pública",
		en: "Public Company",
		fr: "Entreprise Publique"
	},
	PRIVATE_PARTNERSHIP: {
		X: "PRIVATE_PARTNERSHIP",
		es: "Sociedad Privada",
		en: "Private Partnership",
		fr: "Partenariat Privé"
	},
	PRIVATE_CORPORATION: {
		X: "PRIVATE_CORPORATION",
		es: "Corporación Privada",
		en: "Private Corporation",
		fr: "Société Privée"
	},
	PRIVATE_COMPANY: {
		X: "PRIVATE_COMPANY",
		es: "Empresa Privada",
		en: "Private Company",
		fr: "Entreprise Privée"
	},
	MULTI_MEMBER_LLC: {
		X: "MULTI_MEMBER_LLC",
		es: "LLC de Varios Miembros",
		en: "Multi-Member LLC",
		fr: "LLC Multi-Membres"
	},
	LIMITED_LIABILITY_PARTNERSHIP: {
		X: "LIMITED_LIABILITY_PARTNERSHIP",
		es: "Sociedad de Responsabilidad Limitada",
		en: "Limited Liability Partnership",
		fr: "Société à Responsabilité Limitée"
	},
	INCORPORATED_NON_PROFIT: {
		X: "INCORPORATED_NON_PROFIT",
		es: "Incorporada sin Fines de Lucro",
		en: "Incorporated Non-Profit",
		fr: "Incorporé à but non Lucratif"
	},
	GOVERNMENTAL_UNIT: {
		X: "GOVERNMENTAL_UNIT",
		es: "Unidad Gubernamental",
		en: "Governmental Unit",
		fr: "Unité Gouvernementale"
	},
	GOVERNMENT_INSTRUMENTALITY: {
		X: "GOVERNMENT_INSTRUMENTALITY",
		es: "Instrumentalidad del Gobierno",
		en: "Government Instrumentality",
		fr: "instrument du Gouvernement"
	},
	INDIVIDUAL: {
		X: "INDIVIDUAL",
		es: "Individu@",
		en: "Individual",
		fr: "Individuelle"
	},
	INSURANCE: {
		X: "INSURANCE",
		es: "Seguro",
		en: "Insurance",
		fr: "Assurance"
	},
	NON_PROFIT: {
		X: "NON_PROFIT",
		es: "Sin Fines de Lucro",
		en: "Non-Profit",
		fr: "Non Lucratif"
	},
	COMPANY: {
		X: "COMPANY",
		es: "Empresa",
		en: "Company",
		fr: "Compagnie"
	},
	GOVERNMENT_ENTITY: {
		X: "GOVERNMENT_ENTITY",
		es: "Entidad Gubernamental",
		en: "Government Entity",
		fr: "Entité Gouvernementale"
	},
	BUSINESS_STRUCTURE: {
		X: "BUSINESS_STRUCTURE",
		es: "Estructura de Negocio",
		en: "Business Structure",
		fr: "Structure d'Entreprise"
	},
	BUSINESS_TYPE: {
		X: "BUSINESS_TYPE",
		es: "Tipo de Negocio",
		en: "Business Type",
		fr: "Type d'Entreprise"
	},
	DISPLAY_NAME: {
		X: "DISPLAY_NAME",
		es: "Nombre de Presentación",
		en: "Display Name",
		fr: "nom d'affichage"
	},
	LEGAL_NAME: {
		X: "LEGAL_NAME",
		es: "Nombre Legal",
		en: "Legal Name",
		fr: "Nom Légal"
	},
	NAME: {
		X: "NAME",
		es: "Nombre",
		en: "Name",
		fr: "Nom"
	},
	VESSEL_NAME: {
		X: "VESSEL_NAME",
		es: "Nombre de la Embarcació",
		en: "Vessel Name",
		fr: "Nom du Navire"
	},
	INFORMATION: {
		X: "INFORMATION",
		es: "Información",
		en: "Information",
		fr: "Information"
	},
	SORT: {
		X: "SORT",
		es: "Ordenar",
		en: "Sort",
		fr: "Trier"
	},
	CLEAR: {
		X: "CLEAR",
		es: "Borrar",
		en: "Clear",
		fr: "Effacer"
	},
	RESET: {
		X: "RESET",
		es: "Reiniciar",
		en: "Reset",
		fr: "Réinitialiser"
	},
	AUTO_UPDATE: {
		X: "AUTO_UPDATE",
		es: "Auto Actualización",
		en: "Auto Update",
		fr: "Mise à jour Automatique"
	},
	UPDATE: {
		X: "UPDATE",
		es: "Actualizar",
		en: "Update",
		fr: "Mise à Jour"
	},
	ENABLE_PUSH_NOTIFICATIONS: {
		X: "ENABLE_PUSH_NOTIFICATIONS",
		es: "Habilitar Notificaciones Push",
		en: "Enable Push Notificaitons",
		fr: "Activer les Notifications Push"
	},
	AUTO_RENEW: {
		X: "AUTO_RENEW",
		es: "Auto Renovación",
		en: "Auto Renew",
		fr: "Renouvellement Automatique"
	},
	AUTOMATIC_BILLING: {
		X: "AUTOMATIC_BILLING",
		es: "Facturación Automática",
		en: "Automatic Billing",
		fr: "Facturation Automatique"
	},
	RENAME: {
		X: "RENAME",
		es: "Renombrar",
		en: "Rename",
		fr: "Renommer"
	},
	RENEW: {
		X: "RENEW",
		es: "Renovar",
		en: "Renew",
		fr: "Renouveler"
	},
	RENEWS: {
		X: "RENEWS",
		es: "Renueva",
		en: "Renews",
		fr: "Renouvellement"
	},
	NEXT_BILL: {
		X: "NEXT_BILL",
		es: "Próxima Factura",
		en: "Next Bill",
		fr: "Facture Suivante"
	},
	NEXT_DUE_DATE: {
		X: "NEXT_DUE_DATE",
		es: "Fecha de Engrega del Próximo Pedido",
		en: "Next Due Date",
		fr: "Prochaine Date d'Échéance"
	},
	STARTING: {
		X: "STARTING",
		es: "Comenzando",
		en: "Starting",
		fr: "Commencerons"
	},
	TERM_END: {
		X: "TERM_END",
		es: "Fin de Plazo",
		en: "Term End",
		fr: "Fin du Mandat"
	},
	CANCEL: {
		X: "CANCEL",
		es: "Cancelar",
		en: "Cancel",
		fr: "Annuler"
	},
	REFRESH: {
		X: "REFRESH",
		es: "Actualizar",
		en: "Refresh",
		fr: "Rafraîchir"
	},
	STATUS: {
		X: "STATUS",
		es: "Estado",
		en: "Status",
		fr: "Statut"
	},
	PENDING: {
		X: "PENDING",
		es: "Pendiente",
		en: "Pending",
		fr: "En Attente"
	},
	DECLINED: {
		X: "DECLINED",
		es: "Rechazado",
		en: "Declined",
		fr: "Diminué"
	},
	DECLINE: {
		X: "DECLINE",
		es: "Rechazar",
		en: "Decline",
		fr: "Déclin"
	},
	DECLINE_CHANGES: {
		X: "DECLINE_CHANGES",
		es: "Rechazar Cambios",
		en: "Decline Changes",
		fr: "Refuser les Modifications"
	},
	RETRACT: {
		X: "RETRACT",
		es: "Retraer",
		en: "Retract",
		fr: "Rétracter"
	},
	FILTER: {
		X: "FILTER",
		es: "Filtrar",
		en: "Filter",
		fr: "Filtrer"
	},
	ORDER_TYPE: {
		X: "ORDER_TYPE",
		es: "Tipo de Orden",
		en: "Order Type",
		fr: "Type de Commande"
	},
	ORDER_DATE: {
		X: "ORDER_DATE",
		es: "Fecha de Orden",
		en: "Order Date",
		fr: "Date de Commande"
	},
	LAST_UPDATE: {
		X: "LAST_UPDATE",
		es: "Última Actualización",
		en: "Last Update",
		fr: "Dernière Mise à Jour"
	},
	WATER: {
		X: "WATER",
		es: "Agua",
		en: "Water",
		fr: "Eau"
	},
	WIFI: {
		X: "WIFI",
		es: "Wifi",
		en: "Wifi",
		fr: "Wifi"
	},
	ETHERNET: {
		X: "ETHERNET",
		es: "Ethernet",
		en: "Ethernet",
		fr: "Ethernet"
	},
	CABLE_TV: {
		X: "EXPAND",
		es: "Televisión por Cable",
		en: "Cable-tv",
		fr: "Télévision par Câble"
	},
	SHORE_POWER: {
		X: "SHORE_POWER",
		es: "Electricidad",
		en: "Shore Power",
		fr: "Électricité"
	},
	ELECTRICITY: {
		X: "ELECTRICITY",
		es: "Electricidad",
		en: "Electricity",
		fr: "Électricité"
	},
	COMPLETE_BY: {
		X: "COMPLETE_BY",
		es: "Completar antes de:",
		en: "Complete by:",
		fr: "Compléter par:"
	},
	COMPLETED: {
		X: "COMPLETED",
		es: "Realizado",
		en: "Completed",
		fr: "Terminé"
	},
	INCOMPLETE: {
		X: "INCOMPLETE",
		es: "Incompleto",
		en: "Incomplete",
		fr: "Incomplet"
	},
	INCOMPLETE_CUSTOMER: {
		X: "INCOMPLETE_CUSTOMER",
		es: "Incompleto",
		en: "Incomplete",
		fr: "Incomplet"
	},
	CANCELLED: {
		X: "CANCELLED",
		es: "Cancelado",
		en: "Cancelled",
		fr: "Annulé"
	},
	SENT: {
		X: "SENT",
		es: "Expedido",
		en: "Sent",
		fr: "Expédié"
	},
	RECEIVED: {
		X: "RECEIVED",
		es: "Recibido",
		en: "Received",
		fr: "A Reçu"
	},
	RECEIPT: {
		X: "RECEIPT",
		es: "Recibo",
		en: "Receipt",
		fr: "Reçu"
	},
	CONFIRMED: {
		X: "CONFIRMED",
		es: "Confirmado",
		en: "Confirmed",
		fr: "Confirmé"
	},
	AWAITING_CONFIRMATION: {
		X: "AWAITING_CONFIRMATION",
		es: "Pendiente de Confirmación",
		en: "Awaiting Confirmation",
		fr: "En Attente de Confirmation"
	},
	AWAITING_YOUR_RESPONSE: {
		X: "AWAITING_YOUR_RESPONSE",
		es: "En Espera de su Respuesta",
		en: "Awaiting your Response",
		fr: "En Attendant votre Réponse"
	},
	AWAITING_CUSTOMER_RESPONSE: {
		X: "AWAITING_CUSTOMER_RESPONSE",
		es: "Esperando la Respuesta del Cliente",
		en: "Awaiting Customer Response",
		fr: "En Attente de la Réponse du Client"
	},
	SCHEDULED: {
		X: "SCHEDULED",
		es: "Programado",
		en: "Scheduled",
		fr: "Programmé"
	},
	IN_PROGRESS: {
		X: "IN_PROGRESS",
		es: "En Progreso",
		en: "In Progress",
		fr: "En Cours"
	},
	EXPAND: {
		X: "EXPAND",
		es: "Expandir",
		en: "Expand",
		fr: "Développer"
	},
	PREMIUM: {
		X: "PREMIUM",
		es: "Premium",
		en: "Premium",
		fr: "Premium"
	},
	SAVE: {
		X: "SAVE",
		es: "Guardar",
		en: "Save",
		fr: "Enregistrer"
	},
	SUBMIT: {
		X: "SUBMIT",
		es: "Someter",
		en: "Submit",
		fr: "Soumettre"
	},
	SUBJECT: {
		X: "SUBJECT",
		es: "Asunto",
		en: "Subject",
		fr: "Matière"
	},
	DELETE: {
		X: "DELETE",
		es: "Borrar",
		en: "Delete",
		fr: "Supprimer"
	},
	EDIT: {
		X: "EDIT",
		es: "Editar",
		en: "Edit",
		fr: "Éditer"
	},
	OTHER: {
		X: "OTHER",
		es: "Otr@",
		en: "Other",
		fr: "Autre"
	},
	BACK: {
		X: "BACK",
		es: "Atrás",
		en: "Back",
		fr: "Précédent"
	},
	TRAILERS: {
		X: "TRAILERS",
		es: "Espacios para Remolques",
		en: "Trailer Spaces",
		fr: "Espaces pour Remorques"
	},
	CRADLES: {
		X: "CRADLES",
		es: "Cunas",
		en: "Cradles",
		fr: "Berceaux"
	},
	SLIPS: {
		X: "SLIPS",
		es: "Muelles",
		en: "Slips",
		fr: "Quai"
	},
	RACKS: {
		X: "RACKS",
		es: "Bastidores",
		en: "Racks",
		fr: "Supports"
	},
	BOATYARD: {
		X: "BOATYARD",
		es: "Astillero",
		en: "Boatyard",
		fr: "Chantier Naval"
	},
	BOAT_YARD: {
		X: "BOAT_YARD",
		es: "Astillero",
		en: "Boatyard",
		fr: "Chantier Naval"
	},
	MOORINGS: {
		X: "MOORINGS",
		es: "Amarres",
		en: "Moorings",
		fr: "Amarrages"
	},
	QR_CODE: {
		X: "QR_CODE",
		es: "Código QR",
		en: "QR Code",
		fr: "QR Code"
	},
	ORDERS: {
		X: "ORDERS",
		es: "Pedidos",
		en: "Orders",
		fr: "Ordres"
	},
	ORDER: {
		X: "ORDER",
		es: "Pedido",
		en: "Order",
		fr: "Ordre"
	},
	PLACE_ORDERS: {
		X: "PLACE_ORDERS",
		es: "Hacer pedidos",
		en: "Place Orders",
		fr: "Passer des Commandes"
	},
	ORDERS_ACCESS: {
		X: "ORDERS_ACCESS",
		es: "Acceso a Pedidos",
		en: "Orders Access",
		fr: "Accès aux Commandes"
	},
	NOTIFICATIONS: {
		X: "NOTIFICATIONS",
		es: "Notificaciones",
		en: "Notifications",
		fr: "Notificaciones"
	},
	STATUS_UPDATED: {
		X: "STATUS_UPDATED",
		es: "Estado Actualizado",
		en: "Status Updated",
		fr: "Statut mis à Jour"
	},
	DUE_DATE: {
		X: "DUE_DATE",
		es: "Fecha de Entrega",
		en: "Due Date",
		fr: "Date d'Échéance"
	},
	ORDER_NUMBER: {
		X: "ORDER_NUMBER",
		es: "Número de Orden",
		en: "Order Number",
		fr: "Numéro de Commande"
	},
	FACILITY: {
		X: "FACILITY",
		es: "Instalaciones",
		en: "Facility",
		fr: "Établissement"
	},
	ADD_ENGINE: {
		X: "ADD_ENGINE",
		es: "Agregar Motor",
		en: "Add Engine",
		fr: "Ajouter Moteur"
	},
	ENGINE: {
		X: "ENGINE",
		es: "Motor",
		en: "Engine",
		fr: "Moteur"
	},
	ENGINES: {
		X: "ENGINES",
		es: "Motores",
		en: "Engines",
		fr: "Moteurs"
	},
	WHOLE: {
		X: "WHOLE",
		es: "Entero",
		en: "Whole",
		fr: "Entier"
	},
	DECIMAL: {
		X: "DECIMAL",
		es: "Decimal",
		en: "Decimal",
		fr: "Décimal"
	},
	METERS: {
		X: "METERS",
		es: "Metros",
		en: "Meters",
		fr: "Mètres"
	},
	FEET: {
		X: "FEET",
		es: "Pies",
		en: "Feet",
		fr: "Pieds"
	},
	INCHES: {
		X: "INCHES",
		es: "Pulgadas",
		en: "Inches",
		fr: "Pouces"
	},
	CAPACITY: {
		X: "CAPACITY",
		es: "Capacidad",
		en: "Capacity",
		fr: "Capacité"
	},
	EDIT_CAPACITY: {
		X: "EDIT_CAPACITY",
		es: "Editar Capacidad",
		en: "Edit Capacity",
		fr: "Modifier Capacité"
	},
	DRY_WEIGHT: {
		X: "DRY_WEIGHT",
		es: "Peso Seco",
		en: "Dry Weight",
		fr: "Poids Sec"
	},
	EDIT_DRY_WEIGHT: {
		X: "EDIT_DRY_WEIGHT",
		es: "Editar Peso Seco",
		en: "Edit Dry Weight",
		fr: "Modifier Poids Sec"
	},
	HULL_IDENTIFICATION_NUMBER: {
		X: "HULL_IDENTIFICATION_NUMBER",
		es: "Número de Identificación del Casco",
		en: "Hull Identification Number",
		fr: "Numéro d'Identification de Coque"
	},
	EDIT_HULL_IDENTIFICATION_NUMBER: {
		X: "EDIT_HULL_IDENTIFICATION_NUMBER",
		es: "Editar Número de Identificación del Casco",
		en: "Edit Hull Identification Number",
		fr: "Modifier Numéro d'Identification de Coque"
	},
	EDIT_DIMENSIONS: {
		X: "EDIT_DIMENSIONS",
		es: "Editar Dimensiones",
		en: "Edit Dimensions",
		fr: "Modifier Dimensions"
	},
	DIMENSIONS: {
		X: "DIMENSIONS",
		es: "Dimensiones",
		en: "Dimensions",
		fr: "Dimensions"
	},
	EDIT_HULL: {
		X: "EDIT_HULL",
		es: "Editar Casco",
		en: "Edit Hull",
		fr: "Modifier Coque"
	},
	HULL: {
		X: "HULL",
		es: "Casco",
		en: "Hull",
		fr: "Coque"
	},
	HULL_SPECIFICATIONS: {
		X: "HULL_SPECIFICATIONS",
		es: "Especificaciones del Casco",
		en: "Hull Specifications",
		fr: "Spécifications de la Coque"
	},
	VERIFIED: {
		X: "VERIFIED",
		es: "Verificado",
		en: "Verified",
		fr: "Vérifié"
	},
	VERIFICATION: {
		X: "VERIFICATION",
		es: "Verificación",
		en: "Verification",
		fr: "Vérification"
	},
	VERIFICATION_METHOD: {
		X: "VERIFICATION_METHOD",
		es: "Método de Verificación",
		en: "Verification Method",
		fr: "Méthode de Vérification"
	},
	VERIFICATION_CODE: {
		X: "VERIFICATION_CODE",
		es: "Código de Verificación",
		en: "Verification Code",
		fr: "Code de Vérification"
	},
	PASSWORD_RESET_CODE: {
		X: "PASSWORD_RESET_CODE",
		es: "Código de Restablecimiento de Contraseña",
		en: "Password Reset Code",
		fr: "Code de Réinitialisation du Mot de Passe"
	},
	CURRENT_PASSWORD: {
		X: "CURRENT_PASSWORD",
		es: "Contraseña Actual",
		en: "Current Password",
		fr: "Mot de Passe Actuel"
	},
	NEW_PASSWORD: {
		X: "NEW_PASSWORD",
		es: "Contraseña Nueva",
		en: "New Password",
		fr: "Nouveau Mot de Passe"
	},
	POSTAL_CODE: {
		X: "POSTAL_CODE",
		es: "Código Postal",
		en: "Postal Code",
		fr: "Code Postal"
	},
	CITY: {
		X: "CITY",
		es: "Ciudad",
		en: "City",
		fr: "Ville"
	},
	ETC: {
		X: "ETC",
		es: "etc",
		en: "etc",
		fr: "etc"
	},
	STATE: {
		X: "STATE",
		es: "Estado",
		en: "State",
		fr: "Etat"
	},
	TERRITORY: {
		X: "TERRITORY",
		es: "Territorio",
		en: "Territory",
		fr: "Territoire"
	},
	REGION: {
		X: "REGION",
		es: "Región",
		en: "Region",
		fr: "Région"
	},
	COLLECTIVITY: {
		X: "COLLECTIVITY",
		es: "Colectividad",
		en: "Collectivity",
		fr: "Collectivité"
	},
	DEPENDENCY: {
		X: "DEPENDENCY",
		es: "Dependencia",
		en: "Dependency",
		fr: "Dépendance"
	},
	PUBLIC_BODY: {
		X: "PUBLIC_BODY",
		es: "Organismo Público",
		en: "Public Body",
		fr: "Organisme Public"
	},
	EXPIRATION_DATE: {
		X: "EXPIRATION_DATE",
		es: "Fecha de Vencimiento",
		en: "Expiration Date",
		fr: "Date de d'Expiration"
	},
	START_DATE: {
		X: "START_DATE",
		es: "Fecha de Inicio",
		en: "Start Date",
		fr: "Date de Début"
	},
	END_DATE: {
		X: "END_DATE",
		es: "Fecha de Vencimiento",
		en: "End Date",
		fr: "Date de d'Expiration"
	},
	START: {
		X: "START",
		es: "Inicio",
		en: "Start",
		fr: "Début"
	},
	END: {
		X: "END",
		es: "Vencimiento",
		en: "End",
		fr: "Expiration"
	},
	TERM: {
		X: "TERM",
		es: "Plazo",
		en: "Term",
		fr: "Mandat"
	},
	DURATION: {
		X: "DURATION",
		es: "Duración",
		en: "Duration",
		fr: "Durée"
	},
	PROVINCE: {
		X: "PROVINCE",
		es: "Provincia",
		en: "Province",
		fr: "Province"
	},
	COUNTRY: {
		X: "COUNTRY",
		es: "País",
		en: "Country",
		fr: "Pays"
	},
	LINE: {
		X: "LINE",
		es: "Línea",
		en: "Line",
		fr: "Ligne"
	},
	PROFILE_PICTURE: {
		X: "PROFILE_PICTURE",
		es: "Foto de Perfil",
		en: "Profile Picture",
		fr: "Image de Profil"
	},
	EMAILS_AND_PHONE_NUMBER_CODE: {
		X: "EMAILS_AND_PHONE_NUMBER_CODE",
		es: "Verificación",
		en: "Verification",
		fr: "Vérification"
	},
	EMAILS_AND_PHONE_NUMBER: {
		X: "EMAILS_AND_PHONE_NUMBER",
		es: "Información de Contacto",
		en: "Contact Info",
		fr: "Informations de Contact"
	},
	ORIGIN: {
		X: "ORIGIN",
		es: "Origen",
		en: "Origin",
		fr: "Origine"
	},
	DESTINATION: {
		X: "DESTINATION",
		es: "Destino",
		en: "Destination",
		fr: "Destination"
	},
	MARINA: {
		X: "MARINA",
		es: "Marina",
		en: "Marina",
		fr: "Marina"
	},
	ACCOUNT_CONTROL: {
		X: "ACCOUNT_CONTROL",
		es: "Control de la Cuenta",
		en: "Account Control",
		fr: "Contrôle de Compte"
	},
	ACCOUNT_PROFILE: {
		X: "ACCOUNT_PROFILE",
		es: "Perfil de Cuenta",
		en: "Account Profile",
		fr: "Profil du Compte"
	},
	EMPLOYEE_PROFILE: {
		X: "EMPLOYEE_PROFILE",
		es: "Perfil de Empleado",
		en: "Employee Profile",
		fr: "Profil des Employés"
	},
	ORGANIZATION_PROFILE: {
		X: "ORGANIZATION_PROFILE",
		es: "Perfil de Organización",
		en: "Organization Profile",
		fr: "Profil de l'Organisation"
	},
	PRIVACY: {
		X: "PRIVACY",
		es: "Privacidad",
		en: "Privacy",
		fr: "Confidentialité"
	},
	IDENTITY_VERIFICATION: {
		X: "IDENTITY_VERIFICATION",
		es: "Verificación de Identidad",
		en: "Identity Verification",
		fr: "Vérification d'Identité"
	},
	NEW_REQUEST: {
		X: "NEW_REQUEST",
		es: "Nueva Solicitud",
		en: "New Request",
		fr: "Nouvelle requête"
	},
	MEMBERSHIPS: {
		X: "MEMBERSHIPS",
		es: "Membresías",
		en: "Memberships",
		fr: "Adhésions"
	},
	GENERATE_MEMBERSHIP_VERIFICATION_REQUEST: {
		X: "GENERATE_MEMBERSHIP_VERIFICATION_REQUEST",
		es: "Genere una Solicitud de Verificación de Membresías",
		en: "Generate a Membership Verification Request",
		fr: "Générer une Demande de Vérification d'Adhésions"
	},
	GENERATE_IDENTITY_VERIFICATION_REQUEST: {
		X: "GENERATE_IDENTITY_VERIFICATION_REQUEST",
		es: "Genere una Solicitud de Verificación de Identidad",
		en: "Generate an Identity Verification Request",
		fr: "Générer une Demande de Vérification d'Identité"
	},
	LICENSES_PERMITS_CERTIFICATES: {
		X: "LICENSES_PERMITS_CERTIFICATES",
		es: "Licenscias, Permisos y Certificados",
		en: "Licenses, Permits & Certificates",
		fr: "Licences, Permis et Certificats"
	},
	BONDS: {
		X: "BONDS",
		es: "Documentos de Fianza",
		en: "Bond Documents",
		fr: "Documents de Cautionnement"
	},
	INSURANCE_DOCUMENTS: {
		X: "INSURANCE_DOCUMENTS",
		es: "Documentos de Seguros",
		en: "Insurance Documents",
		fr: "Documents d'Assurance"
	},
	TITLE: {
		X: "TITLE",
		es: "Título de Propiedad",
		en: "Property Title",
		fr: "Titre de Propriété"
	},
	JOB_TITLE: {
		X: "JOB_TITLE",
		es: "Puesto de Trabajo",
		en: "Job Title",
		fr: "Titre de Poste"
	},
	REGISTRATION: {
		X: "REGISTRATION",
		es: "Registro",
		en: "Registration",
		fr: "Enregistrement"
	},
	REGISTRATIONS: {
		X: "REGISTRATIONS",
		es: "Registro",
		en: "Registration",
		fr: "Enregistrement"
	},
	DOCUMENTATION: {
		X: "DOCUMENTATION",
		es: "Documentación",
		en: "Documentation",
		fr: "Documentation"
	},
	DOCUMENTATIONS: {
		X: "DOCUMENTATIONS",
		es: "Documentación",
		en: "Documentation",
		fr: "Documentation"
	},
	ORGANIZATION_REGISTRATION_DOCUMENTS: {
		X: "ORGANIZATION_REGISTRATION_DOCUMENTS",
		es: "Documentos de Registro de la Organización",
		en: "Organization Registration Documents",
		fr: "Documents d'Enregistrement de l'Organisation"
	},
	CONTRACTS_AGREEMENTS: {
		X: "CONTRACTS_AGREEMENTS",
		es: "Contratos & Acuerdos",
		en: "Contracts & Agreements",
		fr: "Contrats & Accords"
	},
	WARRANTIES: {
		X: "WARRANTIES",
		es: "Documentos de Garantías",
		en: "Warranty Documents",
		fr: "Documents de Garantie"
	},
	PUBLIC_DOCUMENTS: {
		X: "PUBLIC_DOCUMENTS",
		es: "Documentos Públicos",
		en: "Public Documents",
		fr: "Documents Publics"
	},
	PERSONAL_ACCOUNT: {
		X: "PERSONAL_ACCOUNT",
		es: "Cuenta Personal",
		en: "Personal Account",
		fr: "Compte Personnel"
	},
	ACCOUNT: {
		X: "ACCOUNT",
		es: "Cuenta",
		en: "Account",
		fr: "Compte"
	},
	EMPLOYEES: {
		X: "EMPLOYEES",
		es: "Empleados",
		en: "Employees",
		fr: "Employés"
	},
	EMPLOYEE_ACCOUNT: {
		X: "EMPLOYEE_ACCOUNT",
		es: "Cuentas de Empleado",
		en: "Employee Account",
		fr: "Comptes d'Employé"
	},
	ORGANIZATION: {
		X: "ORGANIZATION",
		es: "Organización",
		en: "Organization",
		fr: "Organisation"
	},
	ORGANIZATION_ACCOUNT: {
		X: "ORGANIZATION_ACCOUNT",
		es: "Cuenta de Negocio",
		en: "Business Account",
		fr: "Compte d'Entreprise"
	},
	ADD_LINKED_ACCOUNT: {
		X: "ADD_LINKED_ACCOUNT",
		es: "Agregar Cuenta Vinculada",
		en: "Add Linked Account",
		fr: "Ajouter un Compte Lié"
	},
	VESSEL_STORAGE_UNITS: {
		X: "VESSEL_STORAGE_UNITS",
		es: "Unidades de Almacenamiento de Embarcaciones",
		en: "Vessel Storage Units",
		fr: "Unités de Stockage de Bateau"
	},
	ADD_VESSEL_STORAGE_UNIT: {
		X: "ADD_VESSEL_STORAGE_UNIT",
		es: "Agregar Unidad de Almacenamiento de Embarcaciones",
		en: "Add Vessel Storage Unit",
		fr: "Ajouter une Unité de Stockage de Bateau"
	},
	ADD: {
		X: "ADD",
		es: "Agregar",
		en: "Add",
		fr: "Ajouter"
	},
	ADD_NEW: {
		X: "ADD_NEW",
		es: "Agregar Nuevo",
		en: "Add New",
		fr: "Ajouter Nouveau"
	},
	CONFIRM_LOCATION: {
		X: "CONFIRM_LOCATION",
		es: "Confirmar Ubicación",
		en: "Confirm Location",
		fr: "Confirmer l'Emplacement"
	},
	LINK_ACCOUNT: {
		X: "LINK_ACCOUNT",
		es: "Vincular Cuenta",
		en: "Link Account",
		fr: "Lien de Compte"
	},
	LINKED_ACCOUNTS: {
		X: "LINKED_ACCOUNTS",
		es: "Cuentas Vinculadas",
		en: "Linked Accounts",
		fr: "Comptes Liés"
	},
	EDIT_CONTACT_INFO: {
		X: "EDIT_CONTACT_INFO",
		es: "Editar Información de Contacto",
		en: "Edit Contact Info",
		fr: "Modifier les Informations de Contact"
	},
	CONTACT_INFO: {
		X: "CONTACT_INFO",
		es: "Información de Contacto",
		en: "Contact Info",
		fr: "Informations de Contact"
	},
	ADDRESSES: {
		X: "ADDRESSES",
		es: "Direcciones",
		en: "Addresses",
		fr: "Adresses"
	},
	ADDRESS: {
		X: "ADDRESS",
		es: "Dirección",
		en: "Address",
		fr: "Adresse"
	},
	ADD_ADDRESS: {
		X: "ADD_ADDRESS",
		es: "Añadir Dirección",
		en: "Add Address",
		fr: "Ajoutez l'Adresse"
	},
	SET_AS_PRIMARY: {
		X: "SET_AS_PRIMARY",
		es: "Establecer como Primario",
		en: "Set as Primary",
		fr: "Définir comme Principale"
	},
	SET_AS_DEFAULT: {
		X: "SET_AS_DEFAULT",
		es: "Establecer por Defecto",
		en: "Set as Default",
		fr: "Définir par Défaut"
	},
	FAUCET: {
		X: "FAUCET",
		es: "Grifo",
		en: "Faucet",
		fr: "Robinet"
	},
	EXPIRES: {
		X: "EXPIRES",
		es: "Vence",
		en: "Expires",
		fr: "Expire"
	},
	QUANTITY: {
		X: "QUANTITY",
		es: "Cantidad",
		en: "Quantity",
		fr: "Quantité"
	},
	AREA: {
		X: "AREA",
		es: "Área",
		en: "Area",
		fr: "Superficie"
	},
	AUTOMATIC_TOP_UP: {
		X: "AUTOMATIC_TOP_UP",
		es: "Recarga Automática",
		en: "Automatic Top-up",
		fr: "Recharge Automatique"
	},
	PRICE: {
		X: "PRICE",
		es: "Precio",
		en: "Price",
		fr: "Prix"
	},
	PRICE_PER_UNIT: {
		X: "PRICE_PER_UNIT",
		es: "Precio por Unidad",
		en: "Price per Unit",
		fr: "Prix par Unité"
	},
	PRICE_PER_TOKEN: {
		X: "PRICE_PER_TOKEN",
		es: "Precio por Token",
		en: "Price per Token",
		fr: "Prix par Jeton"
	},
	PRICE_PER_CREDIT: {
		X: "PRICE_PER_CREDIT",
		es: "Precio por Crédito",
		en: "Price per Credit",
		fr: "Prix par Crédit"
	},
	TOKENS: {
		X: "TOKENS",
		es: "Tokens",
		en: "Tokens",
		fr: "Jetons"
	},
	SET_AS_PREFERRED: {
		X: "SET_AS_PREFERRED",
		es: "Establecer como Preferido",
		en: "Set as Preferred",
		fr: "Définir comme Préférée"
	},
	DEFAULT: {
		X: "DEFAULT",
		es: "Predeter",
		en: "Default",
		fr: "Défaut"
	},
	ASSIGNED_PAYMENT_METHOD: {
		X: "ASSIGNED_PAYMENT_METHOD",
		es: "Método de Pago Asignado",
		en: "Assigned Payment Method",
		fr: "Méthode de Paiement Attribuée"
	},
	PREFERRED_PAYMENT_METHOD: {
		X: "PREFERRED_PAYMENT_METHOD",
		es: "Método de Pago Preferido",
		en: "Preferred Payment Method",
		fr: "Méthode de Paiement Préférée"
	},
	PAYMENT_METHOD: {
		X: "PAYMENT_METHOD",
		es: "Método de Pago",
		en: "Payment Method",
		fr: "Méthode de Paiement"
	},
	PERCENT_DISCOUNT: {
		X: "PERCENT_DISCOUNT",
		es: "Porcentaje de Descuento",
		en: "Percent Discount",
		fr: "Pourcentage de Remise"
	},
	DISCOUNTED: {
		X: "DISCOUNTED",
		es: "Descontado",
		en: "Discounted",
		fr: "Réduit"
	},
	PAYMENT_METHODS: {
		X: "PAYMENT_METHODS",
		es: "Métodos de Pago",
		en: "Payment Methods",
		fr: "Méthodes de Payement"
	},
	PAYMENT_DUE: {
		X: "PAYMENT_DUE",
		es: "Pago Pendiente",
		en: "Payment Due",
		fr: "Paiement Dû"
	},
	PAYMENT_REQUESTED: {
		X: "PAYMENT_REQUESTED",
		es: "Pago Solicitado",
		en: "Payment Requested",
		fr: "Paiement Demandé"
	},
	ADD_PAYMENT_METHOD: {
		X: "ADD_PAYMENT_METHOD",
		es: "Añadir Método de Pago",
		en: "Add Payment Method",
		fr: "Ajoutez Mode de Paiement"
	},
	PRIMARY_ADDRESS: {
		X: "PRIMARY_ADDRESS",
		es: "Dirección Principal",
		en: "Primary Address",
		fr: "Adresse Principale"
	},
	MAILING_ADDRESS: {
		X: "MAILING_ADDRESS",
		es: "Dirección Postal",
		en: "Mailing Address",
		fr: "Adresse Postale"
	},
	BILLING_ADDRESS: {
		X: "BILLING_ADDRESS",
		es: "Dirección de Facturación",
		en: "Billing Address",
		fr: "Adresse de Facturation"
	},
	BILLING_FREQUENCY: {
		X: "BILLING_FREQUENCY",
		es: "Frecuencia de Facturación",
		en: "Billing Frequency",
		fr: "Fréquence de Facturation"
	},
	ONCE: {
		X: "ONCE",
		es: "Una Vez",
		en: "Once",
		fr: "Une Fois"
	},
	PHYSICAL_ADDRESS: {
		X: "PHYSICAL_ADDRESS",
		es: "Dirección Física",
		en: "Physical Address",
		fr: "Adresse Physique"
	},
	FACILITIES_AND_AMENITIES: {
		X: "FACILITIES_AND_AMENITIES",
		es: "Instalaciones y Comodidades",
		en: "Facilities and Amenities",
		fr: "Installations et Commodités"
	},
	SUPPORT: {
		X: "SUPPORT",
		es: "Soporte",
		en: "Support",
		fr: "Assistance"
	},
	SUPPORT_ADDRESS: {
		X: "SUPPORT_ADDRESS",
		es: "Dirección de Soporte",
		en: "Support Address",
		fr: "Adresse d'Assistance"
	},
	SUPPORT_EMAIL: {
		X: "SUPPORT_EMAIL",
		es: "Correo Electrónico de Soporte",
		en: "Support Email",
		fr: "Email d'Assistance"
	},
	SUPPORT_EMAIL_VERIFIED: {
		X: "SUPPORT_EMAIL_VERIFIED",
		es: "Correo Electrónico de Soporte Verificado",
		en: "Support Email Verified",
		fr: "Email d'Assistance Vérifié"
	},
	SUPPORT_PHONE: {
		X: "SUPPORT_PHONE",
		es: "Teléfono de Soporte",
		en: "Support Phone",
		fr: "Téléphone d'Assistance"
	},
	SUPPORT_PHONE_VERIFIED: {
		X: "SUPPORT_PHONE_VERIFIED",
		es: "Teléfono de Soporte Verificado",
		en: "Support Phone Verified",
		fr: "Téléphone d'Assistance Vérifié"
	},
	SUPPORT_WEBSITE: {
		X: "SUPPORT_WEBSITE",
		es: "Sitio Web de Soporte",
		en: "Support Website",
		fr: "Site Web d'Assistance"
	},
	DATE_OF_BIRTH: {
		X: "DATE_OF_BIRTH",
		es: "Fecha de Nacimiento",
		en: "Date of Birth",
		fr: "Date de Naissance"
	},
	US_CUSTOMARY: {
		X: "US_CUSTOMARY",
		es: "Americanas",
		en: "US Customary",
		fr: "Américain"
	},
	SI_METRIC: {
		X: "SI_METRIC",
		es: "Métricas",
		en: "Metric",
		fr: "Métriques"
	},
	UNITS: {
		X: "UNITS",
		es: "Unidades",
		en: "Units",
		fr: "Unités"
	},
	UNIT: {
		X: "UNIT",
		es: "Unidad",
		en: "Unit",
		fr: "Unité"
	},
	EACH: {
		X: "EACH",
		es: "Cada Uno",
		en: "Each",
		fr: "Chacun"
	},
	UNIT_SYSTEM: {
		X: "UNIT_SYSTEM",
		es: "Sistema de Unidades",
		en: "Unit System",
		fr: "Système d'Unité"
	},
	CATEGORY: {
		X: "CATEGORY",
		es: "Categoría",
		en: "Category",
		fr: "Catégorie"
	},
	CATEGORIES: {
		X: "CATEGORIES",
		es: "Categorías",
		en: "Categories",
		fr: "Catégories"
	},
	SYSTEM: {
		X: "SYSTEM",
		es: "Sistema",
		en: "System",
		fr: "Système"
	},
	REQUESTS: {
		X: "REQUESTS",
		es: "Peticiones",
		en: "Requests",
		fr: "Demandes"
	},
	NO_REQUESTS: {
		X: "NO_REQUESTS",
		es: "No hay Peticiones",
		en: "No Requests",
		fr: "Pas Demandes"
	},
	TOOLS: {
		X: "TOOLS",
		es: "Herramientas",
		en: "Tools",
		fr: "Outils"
	},
	CHART: {
		X: "CHART",
		es: "Carta Marina",
		en: "Nautical Chart",
		fr: "Carte Nautique"
	},
	TEXT_FORECAST: {
		X: "TEXT_FORECAST",
		es: "Pronóstico",
		en: "Forecast",
		fr: "Prévision"
	},
	WAVE_MAP: {
		X: "WAVE_MAP",
		es: "Mapa de Oleaje",
		en: "Wave Map",
		fr: "Carte des Vagues"
	},
	WIND_MAP: {
		X: "WIND_MAP",
		es: "Mapa de Vientos",
		en: "Wind Map",
		fr: "Carte du Vent"
	},
	AIS_MAP: {
		X: "AIS_MAP",
		es: "Mapa AIS",
		en: "AIS Map",
		fr: "Carte AIS"
	},
	WEATHER_MAP: {
		X: "WEATHER_MAP",
		es: "Mapa Climático",
		en: "Weather Map",
		fr: "Carte Météorologique"
	},
	PRIMARY_EMAIL: {
		X: "PRIMARY_EMAIL",
		es: "Correo Electrónico Principal",
		en: "Primary eMail",
		fr: "eMail Principal"
	},
	PRIMARY_EMAIL_VERIFIED: {
		X: "PRIMARY_EMAIL_VERIFIED",
		es: "Correo Electrónico Principal Verificado",
		en: "Primary eMail Verified",
		fr: "eMail Principal Vérifié"
	},
	PRIMARY_PHONE: {
		X: "PRIMARY_PHONE",
		es: "Teléfono Principal",
		en: "Primary Phone",
		fr: "Téléphone Principal"
	},
	PRIMARY_PHONE_VERIFIED: {
		X: "PRIMARY_PHONE_VERIFIED",
		es: "Teléfono Principal Verificado",
		en: "Primary Phone Verified",
		fr: "Téléphone Principal Vérifié"
	},
	RECOVERY: {
		X: "RECOVERY",
		es: "de Recuperación",
		en: "Recovery",
		fr: "de Secours"
	},
	RECOVERY_PHONE: {
		X: "RECOVERY_PHONE",
		es: "Teléfono de Recuperación",
		en: "Recovery Phone",
		fr: "Téléphone de Secours"
	},
	RECOVERY_EMAIL: {
		X: "RECOVERY_EMAIL",
		es: "Correo Electrónico de Recuperación",
		en: "Recovery eMail",
		fr: "eMail de Secours"
	},
	COUNTRY_CODE: {
		X: "COUNTRY_CODE",
		es: "Código de País",
		en: "Country Code",
		fr: "Le Code du Pays"
	},
	PHONE: {
		X: "PHONE",
		es: "Teléfono",
		en: "Phone",
		fr: "Téléphone"
	},
	MOBILE_PHONE: {
		X: "MOBILE_PHONE",
		es: "Teléfono Móvil",
		en: "Mobile Phone",
		fr: "Téléphone Portable"
	},
	FULL_NAME: {
		X: "FULL_NAME",
		es: "Nombre Completo",
		en: "Full Name",
		fr: "Nom Complet"
	},
	FULL_NAME_MESSAGE: {
		X: "FULL_NAME_MESSAGE",
		es: "(Según Documentos Oficiales)",
		en: "(As Shown in Official documents)",
		fr: "(Comme Indiqué dans les documents Officiels)"
	},
	IDENTIFICATION_NUMBER: {
		X: "IDENTIFICATION_NUMBER",
		es: "Número de Identificación",
		en: "Identification Number",
		fr: "Numéro d'Identification"
	},
	IDENTIFIERS: {
		X: "IDENTIFIERS",
		es: "Identificadores",
		en: "Identifiers",
		fr: "Identifiants"
	},
	ORGANIZATION_ID: {
		X: "ORGANIZATION_ID",
		es: "ID de la Organización",
		en: "Organization ID",
		fr: "Identifiant de l'Organisation"
	},
	CODE: {
		X: "CODE",
		es: "Código",
		en: "Code",
		fr: "Code"
	},
	SUCCESS_EXCLAMATION: {
		X: "SUCCESS_EXCLAMATION",
		es: "¡Éxito!",
		en: "Success!",
		fr: "Succès!"
	},
	REGISTERING_MARINA_Q: {
		X: "REGISTERING_MARINA_Q",
		es: "Estará registrando una marina?",
		en: "Will you be registering a marina?",
		fr: "Allez-vous enregistrer une marina?"
	},
	RECEIVED_INVITATION_Q: {
		X: "RECEIVED_INVITATION_Q",
		es: "Ha recibido una invitación con código?",
		en: "Have you received an invitation code?",
		fr: "avez-vous reçu un code d'invitation?"
	},
	CONFIRM_PASSWORD: {
		X: "CONFIRM_PASSWORD",
		es: "Confirmar Contraseña",
		en: "Confirm Password",
		fr: "Confirmez le Mot de Passe"
	},
	INVITATION: {
		X: "INVITATION",
		es: "Invitación",
		en: "Invitation",
		fr: "Invitation"
	},
	YES: {
		X: "YES",
		es: "sí",
		en: "yes",
		fr: "oui"
	},
	NO: {
		X: "NO",
		es: "no",
		en: "no",
		fr: "non"
	},
	OK: {
		X: "OK",
		es: "de acuerdo",
		en: "ok",
		fr: "d'accord"
	},
	YES_CAP: {
		X: "YES_CAP",
		es: "Sí",
		en: "Yes",
		fr: "Oui"
	},
	NO_CAP: {
		X: "NO_CAP",
		es: "No",
		en: "No",
		fr: "Non"
	},
	OK_CAP: {
		X: "OK_CAP",
		es: "De Acuerdo",
		en: "Ok",
		fr: "d'Accord"
	},
	LOGIN_EMAIL: {
		X: "LOGIN_EMAIL",
		es: "Correo Electrónico de Acceso",
		en: "Login Email",
		fr: "Adresse eMail de Connexion"
	},
	LOGIN_PHONE: {
		X: "LOGIN_PHONE",
		es: "Número de Teléfono de Acceso",
		en: "Login Phone",
		fr: "Numéro de Téléphone de Connexion"
	},
	LOGIN: {
		X: "LOGIN",
		es: "Iniciar Sesión",
		en: "Login",
		fr: "Entrer"
	},
	PERSONAL_SECURITY_PHRASE: {
		X: "PERSONAL_SECURITY_PHRASE",
		es: "Frase Personal de Seguridad",
		en: "Personal Security Phrase",
		fr: "Phrase de Sécurité Personnelle"
	},
	USERNAME: {
		X: "USERNAME",
		es: "Nombre de Usuario",
		en: "Username",
		fr: "Nom d'Utilisateur"
	},
	PHONE_NUMBER: {
		X: "PHONE_NUMBER",
		es: "Número de Teléfono",
		en: "Phone Number",
		fr: "Numéro de Téléphone"
	},
	ALTERNATE_PHONE_NUMBER: {
		X: "ALTERNATE_PHONE_NUMBER",
		es: "Número de Teléfono Alterno",
		en: "Alternate Phone Number",
		fr: "Autre Numéro de Téléphone"
	},
	EMAIL: {
		X: "EMAIL",
		es: "Correo Electrónico",
		en: "eMail",
		fr: "eMail"
	},
	ALTERNATE_EMAIL: {
		X: "ALTERNATE_EMAIL",
		es: "Correo Electrónico Alterno",
		en: "Alternate eMail",
		fr: "Autre eMail"
	},
	PASSWORD: {
		X: "PASSWORD",
		es: "Contraseña",
		en: "Password",
		fr: "Mot de Passe"
	},
	SETTINGS: {
		X: "SETTINGS",
		es: "Configuraciones",
		en: "Settings",
		fr: "Paramètres"
	},
	MENU: {
		X: "MENU",
		es: "Menú",
		en: "Menu",
		fr: "Menu"
	},
	USER: {
		X: "USER",
		es: "Usuario",
		en: "User",
		fr: "Utilisateur"
	},
	NEW_USER: {
		X: "NEW_USER",
		es: "Nuevo Usuario",
		en: "New User",
		fr: "Nouvel Utilisateur"
	},
	NEW_ACCOUNT: {
		X: "NEW_ACCOUNT",
		es: "Cuenta Nueva",
		en: "New Account",
		fr: "Nouveau Compte"
	},
	LANGUAGE: {
		X: "LANGUAGE",
		es: "Idioma",
		en: "Language",
		fr: "Langue"
	},
	LENGTH_OVER_ALL: {
		X: "LENGTH_OVER_ALL",
		es: "Longitud Total",
		en: "Length Overall",
		fr: "Longueur Totale"
	},
	VESSEL_LENGTH_OVER_ALL: {
		X: "VESSEL_LENGTH_OVER_ALL",
		es: "Longitud Total de la Embarcación",
		en: "Vessel Length Overall",
		fr: "Longueur Totale du Navire"
	},
	HULL_LENGTH_OVER_ALL: {
		X: "HULL_LENGTH_OVER_ALL",
		es: "Longitud Total del Casco",
		en: "Hull Length Overall",
		fr: "Longueur Totale de Coque"
	},
	VESSELS: {
		X: "VESSELS",
		es: "Embarcaciónes",
		en: "Vessels",
		fr: "Navires"
	},
	MY_FLEET: {
		X: "MY_FLEET",
		es: "Mi Flota",
		en: "My Fleet",
		fr: "Ma Flotte"
	},
	OUR_FLEET: {
		X: "OUR_FLEET",
		es: "Nuestra Flota",
		en: "Our Fleet",
		fr: "Notre Flotte"
	},
	CUSTOMER_VESSELS: {
		X: "CUSTOMER_VESSELS",
		es: "Embarcaciones de Clientes",
		en: "Customer Vessels",
		fr: "Navires Clients"
	},
	MY_CUSTOMERS: {
		X: "MY_CUSTOMERS",
		es: "Mis Clientes",
		en: "My Customers",
		fr: "Mes Clients"
	},
	INVITE_CUSTOMER: {
		X: "INVITE_CUSTOMER",
		es: "Invitar un Cliente",
		en: "Invite Customer",
		fr: "Inviter un Client"
	},
	ADD_VESSEL: {
		X: "ADD_VESSEL",
		es: "Agregar Embarcación",
		en: "Add Vessel",
		fr: "Ajouter Navire"
	},
	ADD_CUSTOMER_VESSEL: {
		X: "ADD_CUSTOMER_VESSEL",
		es: "Agregar Embarcación Cliente",
		en: "Add Customer Vessel",
		fr: "Ajouter un Navire Client"
	},
	SWITCH_VESSEL: {
		X: "SWITCH_VESSEL",
		es: "Cambiar Embarcación",
		en: "Switch Vessel",
		fr: "Changer de Navire"
	},
	VESSEL: {
		X: "VESSEL",
		es: "Embarcación",
		en: "Vessel",
		fr: "Navire"
	},
	VESSEL_MAINTENANCE: {
		X: "VESSEL_MAINTENANCE",
		es: "Mantenimiento de Embarcación",
		en: "Vessel Maintenance",
		fr: "Entretien des Navires"
	},
	VESSEL_STORAGE_UNIT_RESERVATION: {
		X: "VESSEL_STORAGE_UNIT_RESERVATION",
		es: "Reservación de Unidad de Almacenamiento de la Embarcación",
		en: "Vessel Storage Unit Reservation",
		fr: "Réservation d'Unité de Stockage de Navire"
	},
	CLEANING: {
		X: "CLEANING",
		es: "Limpieza",
		en: "Cleaning",
		fr: "Navire"
	},
	INTERIOR: {
		X: "INTERIOR",
		es: "Interior",
		en: "Interior",
		fr: "Intérieur"
	},
	EXTERIOR: {
		X: "EXTERIOR",
		es: "Exterior",
		en: "Exterior",
		fr: "Extérieur"
	},
	DETAIL_WASH: {
		X: "DETAIL_WASH",
		es: "Lavado Detallado",
		en: "Detailed Wash",
		fr: "Lavage Détaillé"
	},
	WASH: {
		X: "WASH",
		es: "Lavado",
		en: "Wash",
		fr: "Lavage"
	},
	STANDARD_WASH: {
		X: "STANDARD_WASH",
		es: "Lavado Estándar",
		en: "Standard Wash",
		fr: "Lavage Standard"
	},
	WASH_WAX: {
		X: "WASH_WAX",
		es: "Lavado & Cera",
		en: "Wash & Wax",
		fr: "Lavage & Cire"
	},
	WAX: {
		X: "WAX",
		es: "Cera",
		en: "Wax",
		fr: "Cire"
	},
	CARNAUBA_WAX: {
		X: "WAX",
		es: "Cera de Carnauba",
		en: "Carnauba Wax",
		fr: "Cire de Carnauba"
	},
	POLISH: {
		X: "POLISH",
		es: "Pulido",
		en: "Polish",
		fr: "Poli"
	},
	CERAMIC: {
		X: "CERAMIC",
		es: "Revestimiento Cerámico",
		en: "Ceramic Coating",
		fr: "Revêtement céramique"
	},
	CERAMIC_1: {
		X: "CERAMIC_1",
		es: "Revestimiento Cerámico I",
		en: "Ceramic Coating I",
		fr: "Revêtement céramique I"
	},
	CERAMIC_2: {
		X: "CERAMIC_2",
		es: "Revestimiento Cerámico II",
		en: "Ceramic Coating II",
		fr: "Revêtement céramique II"
	},
	CERAMIC_3: {
		X: "CERAMIC_3",
		es: "Revestimiento Cerámico III",
		en: "Ceramic Coating III",
		fr: "Revêtement céramique III"
	},
	COMPOUND: {
		X: "COMPOUND",
		es: "Compuesto de Corte Moderado",
		en: "Medium Cut Compound",
		fr: "Composé de Coupe Moyenne"
	},
	ONE_STEP: {
		X: "ONE_STEP",
		es: "One-Step Pulido y Protección",
		en: "One-Step Polish & Protect",
		fr: "One-Step Polir et Protéger"
	},
	ENGINE_FLUSH: {
		X: "ENGINE_FLUSH",
		es: "Enjuague del Motor",
		en: "Engine Flush",
		fr: "Rinçage du Moteur"
	},
	GENERATOR_FLUSH: {
		X: "GENERATOR_FLUSH",
		es: "Revestimiento Cerámico",
		en: "Generator Flush",
		fr: "Rinçage du Générateur"
	},
	WASH_POLISH_WAX: {
		X: "WASH_POLISH_WAX",
		es: "Lavado, Pulido & Cera",
		en: "Wash, Polish & Wax",
		fr: "Lavage, Poli & Cire"
	},
	UPPER_LIMITS: {
		X: "UPPER_LIMITS",
		es: "Límites Superiores",
		en: "Upper Limits",
		fr: "Limites Supérieures"
	},
	HULL_BEAM: {
		X: "HULL_BEAM",
		es: "Manga",
		en: "Beam",
		fr: "Largeur"
	},
	BEAM: {
		X: "BEAM",
		es: "Manga",
		en: "Beam",
		fr: "Largeur"
	},
	DRAUGHT: {
		X: "DRAUGHT",
		es: "Calado",
		en: "Draught",
		fr: "Faire le Brouillon"
	},
	HEIGHT: {
		X: "HEIGHT",
		es: "Altura",
		en: "Height",
		fr: "Altitude"
	},
	CLEARANCE: {
		X: "CLEARANCE",
		es: "Altura",
		en: "Clearance",
		fr: "Hauteur"
	},
	WIDTH: {
		X: "WIDTH",
		es: "Ancho",
		en: "Width",
		fr: "Largeur"
	},
	LENGTH: {
		X: "LENGTH",
		es: "Longitud",
		en: "Length",
		fr: "Longueur"
	},
	WEIGHT: {
		X: "WEIGHT",
		es: "Peso",
		en: "Weight",
		fr: "Pesanteur"
	},
	POUNDS: {
		X: "POUNDS",
		es: "Libras",
		en: "Pounds",
		fr: "Livres"
	},
	KILOGRAMS: {
		X: "KILOGRAMS",
		es: "Kilogramos",
		en: "Kilograms",
		fr: "Kilogrammes"
	},
	SLEEPING: {
		X: "SLEEPING",
		es: "Dormir",
		en: "Sleeping",
		fr: "Couchage"
	},
	FUEL: {
		X: "FUEL",
		es: "Combustible",
		en: "Fuel",
		fr: "Combustible"
	},
	FRESH_WATER: {
		X: "FRESH_WATER",
		es: "Agua dulce",
		en: "Fresh water",
		fr: "Eau douce"
	},
	BOTTOM_SCRUB: {
		X: "BOTTOM_SCRUB",
		es: "Limpieza del Fondo",
		en: "Bottom Scrub",
		fr: "Gommage du Fond"
	},
	RAMP: {
		X: "RAMP",
		es: "Rampa para Botes",
		en: "Boat Ramp",
		fr: "Rampe de Bateau"
	},
	HOLDING_TANK: {
		X: "HOLDING_TANK",
		es: "Tanque de Retención",
		en: "Holding Tank",
		fr: "Réservoir d'Eaux Usées"
	},
	LIVEWELL: {
		X: "LIVEWELL",
		es: "Vivero",
		en: "Livewell",
		fr: "Vivier"
	},
	BRAND: {
		X: "BRAND",
		es: "Marca",
		en: "Brand",
		fr: "Marque"
	},
	MODEL: {
		X: "MODEL",
		es: "Modelo",
		en: "Model",
		fr: "Modèle"
	},
	MODEL_YEAR: {
		X: "MODEL_YEAR",
		es: "Año de Modelo",
		en: "Model Year",
		fr: "Année Modèle"
	},
	EDIT_BRAND_MODEL_MODEL_YEAR: {
		X: "BRAND_MODEL_MODEL_YEAR",
		es: "Editar Marca, Modelo, Año",
		en: "Edit Brand, Model, Year",
		fr: "Modifier Marque, Modèle, Année"
	},
	BRAND_MODEL_MODEL_YEAR: {
		X: "BRAND_MODEL_MODEL_YEAR",
		es: "Marca, Modelo, Año",
		en: "Brand, Model, Year",
		fr: "Marque, Modèle, Année"
	},
	EDIT_LABEL: {
		X: "EDIT_LABEL",
		es: "Editar Apodo",
		en: "Edit Label",
		fr: "Modifier Label"
	},
	LABEL: {
		X: "LABEL",
		es: "Apodo",
		en: "Label",
		fr: "Label"
	},
	EDIT_SERIAL_NUMBER: {
		X: "SERIAL_NUMBER",
		es: "Editar Número de Serie",
		en: "Edit Serial Number",
		fr: "Modifier Numéro de Série"
	},
	EDIT_MODEL_NUMBER: {
		X: "MODEL_NUMBER",
		es: "Editar Número de Modelo",
		en: "Edit Model Number",
		fr: "Modifier Numéro de Modèle"
	},
	SERIAL_NUMBER: {
		X: "SERIAL_NUMBER",
		es: "Número de Serie",
		en: "Serial Number",
		fr: "Numéro de Série"
	},
	MODEL_NUMBER: {
		X: "MODEL_NUMBER",
		es: "Número de Modelo",
		en: "Model Number",
		fr: "Numéro de Modèle"
	},
	POWER: {
		X: "POWER",
		es: "Potencia Rominal",
		en: "Power Rating",
		fr: "Puissance Nominale"
	},
	CLASS: {
		X: "CLASS",
		es: "Categoría",
		en: "Class",
		fr: "Catégorie"
	},
	ROTATION: {
		X: "ROTATION",
		es: "Rotación",
		en: "Rotation",
		fr: "Rotation"
	},
	SPECS: {
		X: "SPECS",
		es: "Especificaciones",
		en: "Specifications",
		fr: "Spécifications"
	},
	EDIT_ENGINE_SPECS: {
		X: "EDIT_ENGINE_SPECS",
		es: "Editar Especificaciones del Motor",
		en: "Edit Engine Specifications",
		fr: "Modifier les Spécifications du Moteur"
	},
	ENGINE_SPECS: {
		X: "ENGINE_SPECS",
		es: "Especificaciones del Motor",
		en: "Engine Specifications",
		fr: "Spécifications du Moteur"
	},
	PRIMARY: {
		X: "PRIMARY",
		es: "Principal",
		en: "Primary",
		fr: "Primaire"
	},
	CURRENT: {
		X: "CURRENT",
		es: "Actual",
		en: "Current",
		fr: "Actuel"
	},
	SPACE_AND_CAPACITY: {
		X: "SPACE_AND_CAPACITY",
		es: "Espacio y Capacidad",
		en: "Space & Capacity",
		fr: "Espace et Capacité"
	},
	BRIDGE_CLEARANCE: {
		X: "BRIDGE_CLEARANCE",
		es: "Despeje de Puente",
		en: "Bridge Clearance",
		fr: "Dégagement du Pont"
	},
	EDIT_SPACE_AND_CAPACITY: {
		X: "EDIT_SPACE_AND_CAPACITY",
		es: "Editar Espacio y Capacidad",
		en: "Edit Space & Capacity",
		fr: "Modifier Espace et Capacité"
	},
	MINIMUM_REQUIREMENTS: {
		X: "MINIMUM_REQUIREMENTS",
		es: "Requerimientos Mínimos",
		en: "Minimum Requirements",
		fr: "Exigences Minimales"
	},
	REQUIREMENTS: {
		X: "REQUIREMENTS",
		es: "Requerimientos",
		en: "Requirements",
		fr: "Exigences"
	},
	REQUIREMENTS_DOCUMENT: {
		X: "REQUIREMENTS_DOCUMENT",
		es: "Documento de Requisitos",
		en: "Requirements Document",
		fr: "Document sur les Exigences"
	},
	EDIT_MARINAS_AND_STORAGE_FACILITIES: {
		X: "EDIT_MARINAS_AND_STORAGE_FACILITIES",
		es: "Editar Marinas y Almacenamiento de Embarcaciones",
		en: "Edit Marinas & Storage Facilities",
		fr: "Modifier Marinas et Installations d'Entreposage de Navires"
	},
	MARINA_SERVICE_ACCESS: {
		X: "MARINA_SERVICE_ACCESS",
		es: "Acceso de Servicios a la marina",
		en: "Marina Service Access",
		fr: "Accès aux Services à la marina"
	},
	MARINAS_AND_STORAGE_FACILITIES: {
		X: "MARINAS_AND_STORAGE_FACILITIES",
		es: "Marinas y Almacenamiento de Embarcaciones",
		en: "Marinas & Storage Facilities",
		fr: "Marinas et Installations d'Entreposage de Navires"
	},
	MY_MARINAS: {
		X: "MY_MARINAS",
		es: "Mis Marinas",
		en: "My Marinas",
		fr: "Mes Marinas"
	},
	MARINAS: {
		X: "MARINAS",
		es: "Marinas",
		en: "Marinas",
		fr: "Marinas"
	},
	MAX_UNOCCUPIED_WEIGHT: {
		X: "MAX_UNOCCUPIED_WEIGHT",
		es: "Peso máximo desocupado",
		en: "Maximum unoccupied weight",
		fr: "Poids inoccupé maximum"
	},
	HOIST: {
		X: "HOIST",
		es: "Polipasto",
		en: "Hoist",
		fr: "Treuil"
	},
	EDIT_MAINSAIL: {
		X: "EDIT_MAINSAIL",
		es: "Modificar Vela mayor",
		en: "Edit Mainsail",
		fr: "Modifier Grand-Voile"
	},
	MAINSAIL: {
		X: "MAINSAIL",
		es: "Vela Mayor",
		en: "Mainsail",
		fr: "Grand-Voile"
	},
	FORETRIANGLE: {
		X: "FORETRIANGLE",
		es: "Trinquete",
		en: "Foretriangle",
		fr: "Triangle d'Arc"
	},
	EDIT_FORETRIANGLE: {
		X: "EDIT_FORETRIANGLE",
		es: "Editar Trinquete",
		en: "Edit Foretriangle",
		fr: "Modifier Triangle d'Arc"
	},
	BASE: {
		X: "BASE",
		es: "Base",
		en: "Base",
		fr: "Base"
	},
	EDIT_INNER_FORETRIANGLE: {
		X: "EDIT_INNER_FORETRIANGLE",
		es: "Modificar Triángulo de proa interno",
		en: "Edit Inner foretriangle",
		fr: "Modifier Triangle intérieur d'arc"
	},
	INNER_FORETRIANGLE: {
		X: "INNER_FORETRIANGLE",
		es: "Triángulo de proa interno",
		en: "Inner foretriangle",
		fr: "Triangle intérieur d'arc"
	},
	EDIT_MIZZEN: {
		X: "EDIT_MIZZEN",
		es: "Modificar Mesana",
		en: "Edit Mizzen",
		fr: "Modifier Artimon "
	},
	MIZZEN: {
		X: "MIZZEN",
		es: "Mesana",
		en: "Mizzen",
		fr: "Artimon "
	},
	ELEVATION: {
		X: "ELEVATION",
		es: "Altura",
		en: "Elevation",
		fr: "Hauteur"
	},
	EDIT_SPINNAKER: {
		X: "EDIT_SPINNAKER",
		es: "Modificar Espinaquer",
		en: "Edit Spinnaker",
		fr: "Modifier Spinnaker"
	},
	SPINNAKER: {
		X: "SPINNAKER",
		es: "Espinaquer",
		en: "Spinnaker",
		fr: "Spinnaker"
	},
	POLE: {
		X: "POLE",
		es: "Tangón",
		en: "Pole",
		fr: "Tangon"
	},
	TACK: {
		X: "TACK",
		es: "Bordada",
		en: "Tack",
		fr: "Point d’Amure "
	},
	HALYARD: {
		X: "HALYARD",
		es: "Driza",
		en: "Halyard",
		fr: "Drisse"
	},
	MAKE_RESERVATION: {
		X: "MAKE_RESERVATION",
		es: "Hacer Reservación",
		en: "Make Reservation",
		fr: "Faire Réservation"
	},
	REQUEST_SERVICE: {
		X: "REQUEST_SERVICE",
		es: "Solicitar Servicio",
		en: "Request Service",
		fr: "Demander Service"
	},
	SHUTTLE_SERVICE: {
		X: "SHUTTLE_SERVICE",
		es: "Servicio de Transporte (dentro de la marina)",
		en: "Shuttle Service (within marina)",
		fr: "Service de Navette (dans la marina)"
	},
	VESSEL_CLEANING: {
		X: "VESSEL_CLEANING",
		es: "Limpieza de Embarcación",
		en: "Vessel Cleaning",
		fr: "Nettoyage de Navires"
	},
	VESSEL_TRANSPORT: {
		X: "VESSEL_TRANSPORT",
		es: "Transporte de Embarcación (dentro de la marina)",
		en: "Vessel Transport (within marina)",
		fr: "Transport de Navire (dans la marina)"
	},
	VESSEL_FUELING: {
		X: "VESSEL_FUELING",
		es: "Abastecimiento de Combustible de la Embarcación",
		en: "Vessel Fueling, Pump-out, & Water Supply",
		fr: "Ravitaillement des Navires"
	},
	VESSEL_STORAGE: {
		X: "VESSEL_STORAGE",
		es: "Almacenamiento de la Embarcación",
		en: "Vessel Storage",
		fr: "Stockage de Navires"
	},
	EQUIPMENT_RENTAL: {
		X: "EQUIPMENT_RENTAL",
		es: "Alquiler de Equipo",
		en: "Equipment Rental",
		fr: "Location d'équipement"
	},
	JANUARY: {
		X: "JANUARY",
		es: "enero",
		en: "January",
		fr: "janvier"
	},
	FEBRUARY: {
		X: "FEBRUARY",
		es: "febrero",
		en: "February",
		fr: "février"
	},
	MARCH: {
		X: "MARCH",
		es: "marzo",
		en: "March",
		fr: "mars"
	},
	APRIL: {
		X: "APRIL",
		es: "abril",
		en: "April",
		fr: "avril"
	},
	MAY: {
		X: "MAY",
		es: "mayo",
		en: "May",
		fr: "mai"
	},
	JUNE: {
		X: "JUNE",
		es: "junio",
		en: "June",
		fr: "juin"
	},
	JULY: {
		X: "JULY",
		es: "julio",
		en: "July",
		fr: "juillet"
	},
	AUGUST: {
		X: "AUGUST",
		es: "agosto",
		en: "August",
		fr: "août"
	},
	SEPTEMBER: {
		X: "SEPTEMBER",
		es: "septiembre",
		en: "September",
		fr: "septembre"
	},
	OCTOBER: {
		X: "OCTOBER",
		es: "octubre",
		en: "October",
		fr: "octobre"
	},
	NOVEMBER: {
		X: "NOVEMBER",
		es: "noviembre",
		en: "November",
		fr: "novembre"
	},
	DECEMBER: {
		X: "DECEMBER",
		es: "diciembre",
		en: "December",
		fr: "décembre"
	},
	MONDAY: {
		X: "MONDAY",
		es: "lunes",
		en: "Monday",
		fr: "lundi"
	},
	TUESDAY: {
		X: "TUESDAY",
		es: "martes",
		en: "Tuesday",
		fr: "mardi"
	},
	WEDNESDAY: {
		X: "WEDNESDAY",
		es: "miércoles",
		en: "Wednesday",
		fr: "mercredi"
	},
	THURSDAY: {
		X: "THURSDAY",
		es: "jueves",
		en: "Thursday",
		fr: "jeudi"
	},
	FRIDAY: {
		X: "FRIDAY",
		es: "viernes",
		en: "Friday",
		fr: "vendredi"
	},
	SATURDAY: {
		X: "SATURDAY",
		es: "sábado",
		en: "Saturday",
		fr: "samedi"
	},
	SUNDAY: {
		X: "SUNDAY",
		es: "domingo",
		en: "Sunday",
		fr: "dimanche"
	},
	MONDAYS: {
		X: "MONDAYS",
		es: "los lunes",
		en: "Mondays",
		fr: "le lundi"
	},
	TUESDAYS: {
		X: "TUESDAYS",
		es: "los martes",
		en: "Tuesdays",
		fr: "le mardi"
	},
	WEDNESDAYS: {
		X: "WEDNESDAYS",
		es: "los miércoles",
		en: "Wednesdays",
		fr: "le mercredi"
	},
	THURSDAYS: {
		X: "THURSDAYS",
		es: "los jueves",
		en: "Thursdays",
		fr: "le jeudi"
	},
	FRIDAYS: {
		X: "FRIDAYS",
		es: "los viernes",
		en: "Fridays",
		fr: "le vendredi"
	},
	SATURDAYS: {
		X: "SATURDAYS",
		es: "los sábados",
		en: "Saturdays",
		fr: "le samedi"
	},
	SUNDAYS: {
		X: "SUNDAYS",
		es: "los domingos",
		en: "Sundays",
		fr: "le dimanche"
	},
	MINUTES: {
		X: "MINUTES",
		es: "Minutos",
		en: "Minutes",
		fr: "Minutes"
	},
	HOUR: {
		X: "HOUR",
		es: "Hora",
		en: "Hour",
		fr: "Heure"
	},
	HOURS: {
		X: "HOURS",
		es: "Horas",
		en: "Hours",
		fr: "Heures"
	},
	EDIT_HOURS: {
		X: "EDIT_HOURS",
		es: "Editar Horas",
		en: "Edit Hours",
		fr: "Modifier les Heures"
	},
	TYPICAL_RESPONSE_TIME: {
		X: "TYPICAL_RESPONSE_TIME",
		es: "Tiempo de Respuesta Típico",
		en: "Typical Response Time",
		fr: "Temps de Réponse Typique"
	},
	BETWEEN: {
		X: "BETWEEN",
		es: "Entre",
		en: "Between",
		fr: "Entre"
	},
	AND: {
		X: "AND",
		es: "y",
		en: "and",
		fr: "et"
	},
	YEAR: {
		X: "YEAR",
		es: "Año",
		en: "Year",
		fr: "An"
	},
	YEARLY: {
		X: "YEARLY",
		es: "Anual",
		en: "Yearly",
		fr: "Annuel"
	},
	MONTH: {
		X: "MONTH",
		es: "Mes",
		en: "Month",
		fr: "Mois"
	},
	MONTHLY: {
		X: "MONTHLY",
		es: "Mensual",
		en: "Monthly",
		fr: "Mensuel"
	},
	DATE: {
		X: "DATE",
		es: "Día",
		en: "Date",
		fr: "Date"
	},
	DAY: {
		X: "DAY",
		es: "Día",
		en: "Day",
		fr: "Jour"
	},
	DAYS: {
		X: "DAYS",
		es: "Días",
		en: "Days",
		fr: "Jours"
	},
	WEEKS: {
		X: "WEEKS",
		es: "Semanas",
		en: "Weeks",
		fr: "Semaines"
	},
	MONTHS: {
		X: "MONTHS",
		es: "Meses",
		en: "Months",
		fr: "Mois"
	},
	YEARS: {
		X: "YEARS",
		es: "Años",
		en: "Years",
		fr: "Années"
	},
	REGISTER_BUSINESS_OPTION: {
		X: "REGISTER_BUSINESS_OPTION",
		es: "Deseo registrar un negocio con Paralian",
		en: "I would like to register a business with Paralian",
		fr: "Je souhaite enregistrer une entreprise auprès de Paralian"
	},
	OPEN_PERSONAL_ACCOUNT_OPTION: {
		X: "OPEN_PERSONAL_ACCOUNT_OPTION",
		es: "Deseo crear una cuenta personal",
		en: "I would like to create a personal account",
		fr: "Je souhaite créer un compte personnel"
	},
	ORGANIZATION_SERVICES_Q_HEADER: {
		X: "ORGANIZATION_SERVICES_Q_HEADER",
		es: "Comencemos!",
		en: "Let's get started!",
		fr: "Commençons!"
	},
	ORGANIZATION_SERVICES_Q_BODY_PT_1: {
		X: "ORGANIZATION_SERVICES_Q_BODY_PT_1",
		es: "A continuación, se le presentará una lista de servicios. Seleccione los servicios que ofrecerá su empresa " +
			"en el mercado de Paralian.",
		en: "Next, you will be presented with a list of services. Please select the services which your business " +
			"will offer on Paralian's marketplace.",
		fr: "Ensuite, une liste de services vous sera présentée. Veuillez sélectionner les services que votre entreprise " +
			"proposera sur la place de marché de Paralian."
	},
	ORGANIZATION_SERVICES_Q_BODY_PT_2: {
		X: "ORGANIZATION_SERVICES_Q_BODY_PT_2",
		es: "Podrá agregar y eliminar servicios más tarde, pero debe ofrecer al menos un servicio para abrir una cuenta " +
			"comercial con nosotros.",
		en: "You'll be able to add and remove " +
			"services later, but you must offer at least one service to open a business account with us.",
		fr: "Vous pourrez ajouter et supprimer des services plus tard, mais vous devez proposer au moins un service " +
			"pour ouvrir un compte professionnel chez nous."
	},
	WELCOME: {
		X: "WELCOME",
		es: "Bienvenido!",
		en: "Welcome!",
		fr: "Bienvenu!"
	},
	ORGANIZATION_STEP_1_BODY: {
		X: "ORGANIZATION_STEP_1_BODY",
		es: "Comencemos creando una cuenta de administrador para su negocio.",
		en: "Let's begin by creating an admin account for your business.",
		fr: "Commençons par créer un compte administrateur pour votre entreprise."
	},
	ORGANIZATION_EMAIL_MESSAGE: {
		X: "ORGANIZATION_EMAIL_MESSAGE",
		es: "Proporcione un correo electrónico que servirá como correo electrónico de inicio de sesión para la cuenta de " +
			"administrador de su empresa. Recomendamos correos electrónicos genéricos como admin@negocio.com.",
		en: "Please provide an email which will serve as the login email for your " +
			"business' admin account. Generic emails such as admin@business.com are highly recommended.",
		fr: "Veuillez fournir un e-mail qui servira d'e-mail de connexion pour le compte administrateur de votre " +
			"entreprise. Les e-mails génériques tels que admin@business.com sont fortement recommandés."
	},
	VERIFICATION_MESSAGE_PT_1: {
		X: "VERIFICATION_MESSAGE_PT_1",
		es: "Un código de verificación ha sido enviado a ",
		en: "A verification code was sent to ",
		fr: "Un code de vérification a été envoyé à "
	},
	VERIFICATION_MESSAGE_PT_2: {
		X: "VERIFICATION_MESSAGE_PT_2",
		es: ". Para completar el proceso de verificación, ingrese el código en el campo provisto.",
		en: ". To complete the verification process, please enter the code in the field provided.",
		fr: ". Pour terminer le processus de vérification, veuillez saisir le code dans le champ prévu à cet effet."
	},
	INVALID_ENTRY: {
		X: "INVALID_ENTRY",
		es: "Entrada Invalida",
		en: "Invalid Entry",
		fr: "Entrée invalide"
	},
	INVALID_ENTRY_BODY: {
		X: "INVALID_ENTRY_BODY",
		es: "El código que ingresó no coincide con nuestros archivos. Por favor, inténte de nuevo.",
		en: "The code you entered does not match any on file. Please try again.",
		fr: "Le code que vous avez entré ne correspond à aucun des fichiers. Veuillez réessayer."
	},
	CODE_EXPIRED: {
		X: "CODE_EXPIRED",
		es: "Código Caducó",
		en: "Code Expired",
		fr: "Code Expiré"
	},
	EXPIRED: {
		X: "EXPIRED",
		es: "Caducó",
		en: "Expired",
		fr: "Expiré"
	},
	EXPIRES_IN: {
		X: "EXPIRES_IN",
		es: "Caduca en",
		en: "Expires in",
		fr: "Expire dans"
	},
	CODE_EXPIRED_BODY: {
		X: "CODE_EXPIRED_BODY",
		es: "El código que ha introducido ha caducado. Se ha enviado un nuevo código al mismo destino que el código " +
			"anterior. Ingrese el nuevo código a continuación.",
		en: "The code you have entered has expired. A new code has been sent to the same destination as the previous " +
			"code. Please enter the new code below.",
		fr: "Le code que vous avez entré est expiré. Un nouveau code a été envoyé à la même destination que le code " +
			"précédent. Veuillez saisir le nouveau code ci-dessous."
	},
	ERROR: {
		X: "ERROR",
		es: "Error",
		en: "Error",
		fr: "Erreur"
	},
	CODE_ERROR_BODY: {
		X: "CODE_ERROR_BODY",
		es: "No pudimos verificar el código. Asegúrese de estar conectado a Internet y vuelva a intentarlo.",
		en: "We were unable to verify the code. Please make sure you are connected to the internet and try again.",
		fr: "Nous n'avons pas pu vérifier le code. Veuillez vous assurer que vous êtes connecté à Internet et réessayer."
	},
	HOLD_FAILED_TITLE: {
		X: "HOLD_FAILED_TITLE",
		es: "Fondos no Disponibles",
		en: "Funds Unavailable",
		fr: "Fonds Indisponibles"
	},
	HOLD_FAILED_STATUS_PROVIDER: {
		X: "HOLD_FAILED_STATUS_PROVIDER",
		es: "Fondos no Disponibles - Pendiente a Acción del Cliente",
		en: "Funds Unavailable - Customer Action Pending",
		fr: "Fonds Indisponibles - Action du Client en Attante"
	},
	ACTION_REQUIRED_INVALID_PAYMENT_METHOD: {
		X: "ACTION_REQUIRED_INVALID_PAYMENT_METHOD",
		es: "¡Acción Requrerida! Método de Pago no Válido",
		en: "Action Required! Invalid Payment Method",
		fr: "Action Requise! | Méthode de Paiement non Valide"
	},
	HOLD_FAILED_MESSAGE: {
		X: "HOLD_FAILED_MESSAGE",
		es: "No logramos confirmar la disponibilidad de fondos mediante el método de pago especificado para este pedido. " +
			"Le notificamos al cliente y le hemos pedido que resuelva este problema lo antes posible. " +
			"Le notificaremos una vez que se haya resuelto el problema y se haya confirmado la disponibilidad de fondos.",
		en: "We were unable to confirm the availability of funds using the payment method specified for this order. " +
			"We have notified the  customer and asked them to resolve this issue as soon as possible. " +
			"We will notify once the issue has been resolved and the availability of funds has been confirmed.",
		fr: "Nous n'avons pas pu confirmer la disponibilité des fonds en utilisant le mode de paiement spécifié pour cette commande." +
			"Nous avons informé le client et lui avons demandé de résoudre ce problème dès que possible. " +
			"Nous vous informerons une fois le problème résolu et la disponibilité des fonds confirmée. "
	},
	ORGANIZATION_USERNAME_MESSAGE: {
		X: "ORGANIZATION_USERNAME_MESSAGE",
		es: "Proporcione un nombre de usuario para esta cuenta de administrador.",
		en: "Please provide an admin username.",
		fr: "Veuillez fournir un nom d'utilisateur pour ce compte administrateur."
	},
	ORGANIZATION_PASSWORD_MESSAGE: {
		X: "ORGANIZATION_PASSWORD_MESSAGE",
		es: "Proporcione una contraseña para la cuenta de administrador de su empresa. ¡Hágala buena!",
		en: "Please provide a password for your business' admin account. Make it a good one!",
		fr: "Veuillez fournir un mot de passe pour le compte administrateur de votre entreprise. Faites-en un bon!"
	},
	NEW_LOGIN_PERSONAL_SECURITY_PHRASE_MESSAGE: {
		X: "NEW_LOGIN_PERSONAL_SECURITY_PHRASE_MESSAGE",
		es: "Su frase de seguridad personal se le mostrará en cada intento de inicio de sesión inicial. Debe ser corto " +
			"(máx. 128 caracteres), único y solo puede contener letras, números, espacios y el carácter de subrayado. Las " +
			"frases de seguridad personal proporcionan una capa adicional de seguridad para su cuenta.",
		en: "Your personal security phrase will be shown to you on each initial login attempt. It should be short " +
			"(max. 128 characters), unique, and may contain only letters, numbers, space, and the underscore character. " +
			"Personal Security Phrases provide an additional layer of security for your account.",
		fr: "Votre phrase de sécurité personnelle vous sera montrée à chaque tentative de connexion initiale. Il doit " +
			"être court (128 caractères maximum), unique et ne peut contenir que des lettres, des chiffres, des espaces " +
			"et le caractère de soulignement. Les phrases de sécurité personnelles fournissent une couche supplémentaire " +
			"de sécurité pour votre compte."
	},
	TERMS_AND_CONDITIONS_CHECK_BOX_1: {
		X: "TERMS_AND_CONDITIONS_CHECK_BOX_1",
		es: "He leído y acepto los ",
		en: "I have read and agree to Paralian's ",
		fr: "J'ai lu et j'accepte les "
	},
	TERMS_AND_CONDITIONS_CHECK_BOX_2: {
		X: "TERMS_AND_CONDITIONS_CHECK_BOX_2",
		es: "Términos de uso, ",
		en: "Terms of Use, ",
		fr: "conditions d'utilisation "
	},
	TERMS_AND_CONDITIONS_CHECK_BOX_3: {
		X: "TERMS_AND_CONDITIONS_CHECK_BOX_3",
		es: "la Política de privacidad ",
		en: "Privacy Policy ",
		fr: "et la politique de confidentialité "
	},
	TERMS_AND_CONDITIONS_CHECK_BOX_4: {
		X: "TERMS_AND_CONDITIONS_CHECK_BOX_4",
		es: "de Paralian y recibir correos " +
			"electrónicos de Paralian.",
		en: "and to receive Paralian emails.",
		fr: "de Paralian et je reçois des e-mails de Paralian."
	},
	TERMS_AND_CONDITIONS_UNCHECKED: {
		X: "TERMS_AND_CONDITIONS_UNCHECKED",
		es: "El uso de su cuenta requiere la aceptación de nuestros términos y condiciones.",
		en: "Use of your account requires agreement with our terms and conditions.",
		fr: "L'utilisation de votre compte nécessite l'acceptation de nos termes et conditions."
	},
	ORGANIZATION_STEP_2_HEADER: {
		X: "ORGANIZATION_STEP_2_HEADER",
		es: "Primer Paso",
		en: "Step One",
		fr: "Première Étape"
	},
	ORGANIZATION_STEP_2_BODY: {
		X: "ORGANIZATION_STEP_2_BODY",
		es: "A continuación, cuéntenos sobre su negocio.",
		en: "Next, tell us about your business.",
		fr: "Ensuite, parlez-nous de votre entreprise."
	},
	CONTACT_INFO_VERIFY_MESSAGE: {
		X: "CONTACT_INFO_VERIFY_MESSAGE",
		es: "Se han enviado códigos de verificación a los correos electrónicos y teléfonos proporcionados. Por favor " +
			"confírmelo ingresando los códigos en sus respectivos campos a continuación. Solo se mostrará la información " +
			"de contacto verificada.",
		en: "Verification codes have been sent to the emails and phones " +
			"provided. Please confirm these by entering the codes in their respective fields below. Only verified contact " +
			"information will be displayed.",
		fr: "Des codes de vérification ont été envoyés aux e-mails et aux téléphones fournis. Veuillez les confirmer en " +
			"entrant les codes dans leurs champs respectifs ci-dessous. Seules les informations de contact vérifiées " +
			"seront affichées."
	},
	ADDRESS_MISMATCH_MESSAGE: {
		X: "ADDRESS_MISMATCH_MESSAGE",
		es: "Parece que uno de los siguientes valores es incorrecto: ciudad, estado / provincia, país. Revise su entrada " +
			"y vuelva a intentarlo.",
		en: "It appears that one of the following values is incorrect: city, state/province, country. Please review your " +
			"input and try again.",
		fr: "Il semble que l'une des valeurs suivantes soit incorrecte: ville, état / province, pays. Veuillez revoir " +
			"votre entrée et réessayer."
	},
	COORDINATES_MESSAGE_SUCCESS: {
		X: "COORDINATES_MESSAGE_SUCCESS",
		es: "Arrastre y suelte el perno en el mapa para marcar la ubicación física de su empresa.",
		en: "Drag and drop the pin in the map to mark your business' physical location.",
		fr: "Faites glisser et déposez l'épingle sur la carte pour marquer l'emplacement physique de votre entreprise."
	},
	COORDINATES_MESSAGE_FAILURE: {
		X: "COORDINATES_MESSAGE_FAILURE",
		es: "No pudimos encontrar una ubicación precisa a partir de la dirección proporcionada. Arrastre y suelte el " +
			"perno en el mapa para marcar la ubicación física de su empresa.",
		en: "We were unable to find a precise location from the address provided. Drag and drop the pin in the map to " +
			"mark your business' physical location.",
		fr: "Nous n'avons pas pu trouver un emplacement précis à partir de l'adresse fournie. Faites glisser et déposez " +
			"l'épingle sur la carte pour marquer l'emplacement physique de votre entreprise."
	},
	LOCATION: {
		X: "LOCATION",
		es: "Ubicación",
		en: "Location",
		fr: "Emplacement"
	},
	PHYSICAL_ADDRESS_VISIT_Q_MESSAGE: {
		X: "PHYSICAL_ADDRESS_VISIT_Q_MESSAGE",
		es: "¿Aceptan visitas de clientes en esta ubicación?",
		en: "Do you accept customer visits at this location?",
		fr: "Acceptez-vous les visites de clients à cet endroit?"
	},
	MAILING_ADDRESS_Q_MESSAGE: {
		X: "MAILING_ADDRESS_Q_MESSAGE",
		es: "¿La dirección postal es la misma que la dirección física?",
		en: "Is the mailing address the same as the physical address?",
		fr: "L'adresse postale est-elle la même que l'adresse physique?"
	},
	SUPPORT_ADDRESS_Q_MESSAGE: {
		X: "SUPPORT_ADDRESS_Q_MESSAGE",
		es: "¿La dirección de soporte es la misma que la dirección postal?",
		en: "Is the support address the same as the mailing address?",
		fr: "L'adresse d'assistance est-elle la même que l'adresse postale?"
	},
	HOURS_OF_OPERATION_MESSAGE: {
		X: "HOURS_OF_OPERATION_MESSAGE",
		es: "Especifique el horario de atención estándar de su empresa. Podrá notar las excepciones en la siguiente ventana.",
		en: "Please specify your business' standard hours of operation. You will be able to note exceptions " +
			"in the next window.",
		fr: "Veuillez préciser les heures d'ouverture standard de votre entreprise. Vous pourrez noter les exceptions " +
			"dans la fenêtre suivante."
	},
	HOLIDAYS_MESSAGE: {
		X: "HOLIDAYS_MESSAGE",
		es: "Ingrese el horario de atención de su empresa para los días feriados.",
		en: "Please enter your business' hours of operation for any observed holidays.",
		fr: "Veuillez saisir les heures d'ouverture de votre entreprise pour les jours fériés observés."
	},
	COMPOSITE_PRICING_MESSAGE: {
		X: "COMPOSITE_PRICING_MESSAGE",
		es: "Describe tu fórmula de precios aquí. Intentaremos convertirlo en código de computadora y nos " +
			"comunicaremos con usted para su aprobación antes de activarlo.",
		en: "Please describe your pricing formula here. We’ll attempt to turn it into computer code and contact " +
			"you for approval before taking it live.",
		fr: "Veuillez décrire votre formule de tarification ici. Nous tenterons de le transformer en code informatique " +
			"et vous contacterons pour approbation avant de le mettre en ligne."
	},
	ORGANIZATION_SERVICE_MARINA_MESSAGE: {
		X: "ORGANIZATION_SERVICE_MARINA_MESSAGE",
		es: "Las siguientes marinas / instalaciones de almacenamiento de embarcaciones se encuentran dentro del área de " +
			"servicio especificada anteriormente. Seleccione aquellos en los que su empresa puede operar. No seleccione " +
			"aquellos de los que no esté seguro. Podrá agregarlos en el futuro.",
		en: "The following marinas/vessel facilities facilities fall within the service area specified previously. Please" +
			" select those where your business is allowed to operate. Do not select those of which you are unsure. You " +
			"will be able to add them in the future.",
		fr: "Les marinas / installations de navires suivantes font partie de la zone de service spécifiée précédemment. " +
			"Veuillez sélectionner ceux dans lesquels votre entreprise est autorisée à opérer. Ne sélectionnez pas ceux " +
			"dont vous n'êtes pas sûr. Vous pourrez les ajouter ultérieurement."
	},
	ORGANIZATION_DOCUMENTS_MESSAGE: {
		X: "ORGANIZATION_DOCUMENTS_MESSAGE",
		es: "Los documentos cargados se utilizan para reforzar las credenciales de su empresa - y, por lo tanto, su " +
			"prioridad de búsqueda - (registro comercial, licencias, permisos, certificados), informar a sus clientes " +
			"(documentos públicos) y ayudar a su empresa a obtener acceso a puertos deportivos (documentos de fianza, " +
			"documentos de seguros). Podrá volver a visitar este paso después de la creación de la cuenta.",
		en: "Uploaded documents are used to bolster your business' credentials and thus your search priority " +
			"(business registration, licenses, permits, certificates), inform your customers (public documents), " +
			"and help your business attain access to marinas (bonds, insurance documents). You will be able to re-visit " +
			"this step after account creation.",
		fr: "Les documents téléchargés sont utilisés pour renforcer les informations d'identification de votre " +
			"entreprise et donc votre priorité de recherche (immatriculation, licences, permis, certificats), informer " +
			"vos clients (documents publics) et aider votre entreprise à accéder aux marinas (obligations, documents " +
			"d'assurance). Vous pourrez revoir cette étape après la création du compte."
	},
	PRICING_MESSAGE: {
		X: "PRICING_MESSAGE",
		es: "Cuando los usuarios de Paralian buscan un servicio, se les presentan precios calculados automáticamente por " +
			"adelantado. Aquí, recopilaremos la información necesaria para realizar estos cálculos. Podrá modificar los " +
			"precios con la aprobación del cliente. Sin embargo, las modificaciones importantes y frecuentes del precio " +
			"final pueden resultar en clasificaciones de búsqueda más bajas y / o ser excluido de los resultados de búsqueda.",
		en: "When Paralian users browse for a service, they are presented with automatically calculated prices " +
			"up front. Here, we’ll be collecting the information necessary to make these calculations. You will be able " +
			"to alter prices with the customer's approval. However, large and frequent " +
			"alterations to the final price may result in lower search rankings and/or being excluded for search results.",
		fr: "Lorsque les utilisateurs de Paralian recherchent un service, des prix calculés automatiquement à l'avance " +
			"leur sont présentés. Ici, nous collecterons les informations nécessaires pour effectuer ces calculs. Vous " +
			"pourrez modifier les prix avec l'approbation du client. Cependant, des modifications importantes et " +
			"fréquentes du prix final peuvent entraîner des classements de recherche inférieurs et / ou être exclus des " +
			"résultats de recherche."
	},
	ORGANIZATION_STEP_3_BODY: {
		X: "ORGANIZATION_STEP_3_BODY",
		es: "Su marca es muy importante para nosotros. A continuación, le preguntaremos sobre los colores, el logotipo " +
			"y el icono de su empresa.",
		en: "Your brand is very important to us. Next, we'll be asking about your business colors, logo, and icon.",
		fr: "Votre marque est très importante pour nous. Ensuite, nous vous poserons des questions sur les couleurs, le " +
			"logo et l'icône de votre entreprise."
	},
	ORGANIZATION_STEP_3_HEADER: {
		X: "ORGANIZATION_STEP_3_HEADER",
		es: "Segundo paso",
		en: "Step Two",
		fr: "Deuxième Étape"
	},
	CONFIRM_PASSWORD_MESSAGE: {
		X: "CONFIRM_PASSWORD_MESSAGE",
		es: "Confirme su contraseña para continuar e intente nuevamente.",
		en: "Please confirm your password to continue and try again.",
		fr: "Veuillez confirmer votre mot de passe pour continuer et réessayer."
	},
	CONFIRM: {
		X: "CONFIRM",
		es: "Confirmar",
		en: "Confirm",
		fr: "Confirmer"
	},
	UPDATE_ORDER_PAYMENT_METHOD: {
		X: "UPDATE_ORDER_PAYMENT_METHOD",
		es: "Actualizar Método de Pago del Pedido",
		en: "Update Order Payment Method",
		fr: "Mettre à Jour le Mode de Paiement de la Commande"
	},
	INPUT_ERROR: {
		X: "INPUT_ERROR",
		es: "Error de entrada",
		en: "Input Error",
		fr: "Erreur d'entrée"
	},
	WARNING: {
		X: "WARNING",
		es: "Advertencia",
		en: "Warning",
		fr: "Avertissement"
	},
	HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE: {
		X: "HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE",
		es: "La hora de cierre es la misma que la hora de apertura en su entrada para el ",
		en: "The closing hour is the same as the opening hour in your entry for ",
		fr: "L'heure de fermeture est la même que l'heure d'ouverture de votre entrée pour le "
	},
	HOLIDAY_HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE: {
		X: "HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE",
		es: "La hora de cierre es la misma que la hora de apertura en uno o más días festivos.",
		en: "The closing hour is the same as the opening hour in one or more holiday.",
		fr: "L'heure de fermeture est la même que l'heure d'ouverture d'un ou plusieurs jours fériés."
	},
	NO_HOLIDAYS_SPECIFIED: {
		X: "NO_HOLIDAYS_SPECIFIED",
		es: "Ningún día feriado ha sido definido.",
		en: "No holidays specified.",
		fr: "Aucun jour férié spécifié."
	},
	ORGANIZATION_EMPLOYEES_Q_BODY: {
		X: "ORGANIZATION_EMPLOYEES_Q_BODY",
		es: "¿Le gustaría crear cuentas adicionales de administrador y/o empleados?",
		en: "Would you like to create additional employee and/or admin accounts?",
		fr: "Souhaitez-vous créer des comptes d'employés et / ou d'administrateurs supplémentaires?"
	},
	NO_EMPLOYEES_SPECIFIED: {
		X: "NO_EMPLOYEES_SPECIFIED",
		es: "Ningún empleado o administrador ha sido definido.",
		en: "No employee or admin specified.",
		fr: "Aucun employé ou administrateur spécifié."
	},
	ORGANIZATION_STEP_4_HEADER: {
		X: "ORGANIZATION_STEP_4_HEADER",
		es: "Ahora en el paso final. ¡Vamos a completar los preparativos para que te paguen!",
		en: "Now onto the final step. Let's set you up to get paid!",
		fr: "Passons maintenant à la dernière étape. Préparons-nous pour être payé!"
	},
	ORGANIZATION_STEP_4_BODY: {
		X: "ORGANIZATION_STEP_4_BODY",
		es: "Al hacer clic en el botón de abajo, se le enviará al sitio web de Stripe. Aquí se le pedirá que " +
			"proporcione la información necesaria para que le paguen cuando se realice una venta.",
		en: "On clicking the button below, you will be sent to Stripe's website. Here you will be asked to provide the " +
			"information necessary to get you paid when a sale is made.",
		fr: "En cliquant sur le bouton ci-dessous, vous serez dirigé vers le site Web de Stripe. Ici, il vous sera " +
			"demandé de fournir les informations nécessaires pour vous faire payer lors d'une vente."
	},
	WELCOME_TO_PARALIAN: {
		X: "WELCOME_TO_PARALIAN",
		es: "¡Bienvenido a Paralian!",
		en: "Welcome to Paralian!",
		fr: "Bienvenue à Paralian!"
	},
	ORGANIZATION_GOING_LIVE_MESSAGE: {
		X: "ORGANIZATION_GOING_LIVE_MESSAGE",
		es: "Registro completo. Presione el siguiente botón a continuación para comenzar.",
		en: "Registration complete. Press the next button below to go live.",
		fr: "Inscription complète. Appuyez sur le bouton suivant ci-dessous pour passer en direct."
	},
	EMPLOYEE_AND_ADMIN_ACCOUNTS: {
		X: "EMPLOYEE_AND_ADMIN_ACCOUNTS",
		es: "Cuentas de emplead@s y administrador@s",
		en: "Employee and Admin Accounts",
		fr: "Comptes Employés et Administrateurs"
	},
	CONNECT_WITH_STRIPE: {
		X: "CONNECT_WITH_STRIPE",
		es: "Conécteme con Stripe",
		en: "Connect with Stripe",
		fr: "Connectez-vous avec Stripe"
	},
	PRIVACY_POLICY: {
		X: "PRIVACY_POLICY",
		es: "Política de Privacidad",
		en: "Privacy Policy",
		fr: "Politique de Confidentialité"
	},
	COOKIE_POLICY: {
		X: "COOKIE_POLICY",
		es: "Política de Cookies",
		en: "Cookie Policy",
		fr: "Politique Relative aux Cookies"
	},
	DISCLAIMERS: {
		X: "DISCLAIMERS",
		es: "Descargos de responsabilidad",
		en: "Disclaimers",
		fr: "Avis de Non-Responsabilité"
	},
	TERMS_AND_CONDITIONS: {
		X: "TERMS_AND_CONDITIONS",
		es: "Términos y Condiciones",
		en: "Terms and Conditions",
		fr: "Termes et Conditions"
	},
	TERMS_OF_USE: {
		X: "TERMS_OF_USE",
		es: "Condiciones de Uso",
		en: "Terms of Use",
		fr: "Conditions d'Utilisation"
	},
	TERMS_OF_SALE: {
		X: "TERMS_OF_SALE",
		es: "Condiciones de Venta",
		en: "Terms of Sale",
		fr: "Conditions de Vente"
	},
	NEW_ACCOUNT_NAME_MESSAGE: {
		X: "NEW_ACCOUNT_NAME_MESSAGE",
		es: "Proporcione su nombre completo tal como aparece en su identificación oficial emitida por el gobierno. Se " +
			"utilizará como parte del proceso de verificación de identidad en una etapa posterior.",
		en: "Please provide your full name as it appears on your official government-issued identification. It will be " +
			"used as part of the identity verification process at a later stage.",
		fr: "Veuillez indiquer votre nom complet tel qu'il apparaît sur votre pièce d'identité officielle émise par le " +
			"gouvernement. Il sera utilisé dans le cadre du processus de vérification d'identité à un stade ultérieur."
	},
	NEW_ACCOUNT_NAME_CHARACTERS: {
		X: "NEW_ACCOUNT_NAME_CHARACTERS",
		es: "Solo se permiten letras, espacios y guiones (-) de hasta 128 caracteres.",
		en: "Only letters, spaces, and dashes (-) are allowed up to 128 characters.",
		fr: "Seuls les lettres, espaces et tirets (-) sont autorisés jusqu'à 128 caractères."
	},
	PASSWORD_MISMATCH: {
		X: "PASSWORD_MISMATCH",
		es: "Contraseñas no Coinciden",
		en: "Password Mismatch",
		fr: "Non Concordance des Mots de Passe"
	},
	PASSWORD_MISMATCH_MESSAGE: {
		X: "PASSWORD_MISMATCH_MESSAGE",
		es: "Hay una falta de coincidencia entre el texto de la contraseña superior e inferior.",
		en: "There's a mismatch between the top and bottom password text.",
		fr: "Il y a un décalage entre le texte du mot de passe du haut et du bas."
	},
	USERNAME_UNAVAILABLE: {
		X: "USERNAME_UNAVAILABLE",
		es: "Nombre de Usuario Ocupado",
		en: "Username Unavailable",
		fr: "Nom d'Utilisateur Indisponible"
	},
	AVAILABLE: {
		X: "AVAILABLE",
		es: "Disponible",
		en: "Available",
		fr: "Disponible"
	},
	USED: {
		X: "USED",
		es: "Utilizados",
		en: "Used",
		fr: "Utilisé"
	},
	TOTAL: {
		X: "TOTAL",
		es: "Total",
		en: "Total",
		fr: "Total"
	},
	ADJUSTED_TOTAL: {
		X: "ADJUSTED_TOTAL",
		es: "Total Ajustado",
		en: "Adjusted Total",
		fr: "Total Ajusté"
	},
	REFUNDS: {
		X: "REFUNDS",
		es: "Reembolsos",
		en: "Refunds",
		fr: "Remboursements"
	},
	REFUND: {
		X: "REFUND",
		es: "Reembolsar",
		en: "Refund",
		fr: "Rembourser"
	},
	SUBTOTAL: {
		X: "SUBTOTAL",
		es: "Subtotal",
		en: "Subtotal",
		fr: "Sous-total"
	},
	TAXES: {
		X: "TAXES",
		es: "Impuestos",
		en: "Taxes",
		fr: "Impôts"
	},
	THRESHOLD: {
		X: "THRESHOLD",
		es: "Umbral",
		en: "Threshold",
		fr: "Seuil"
	},
	USERNAME_UNAVAILABLE_MESSAGE: {
		X: "USERNAME_UNAVAILABLE_MESSAGE",
		es: "Lo siento, alguien más está usando ese nombre de usuario. Por favor seleccione otro.",
		en: "Sorry, someone else is using that username. Please select another.",
		fr: "Désolé, quelqu'un d'autre utilise ce nom d'utilisateur. Veuillez en sélectionner un autre."
	},
	MISMATCH: {
		X: "MISMATCH",
		es: "Discordancia",
		en: "Mismatch",
		fr: "Décalage"
	},
	OVERALL_RATING: {
		X: "OVERALL_RATING",
		es: "General",
		en: "Overall",
		fr: "Globale"
	},
	QUALITY_RATING: {
		X: "QUALITY_RATING",
		es: "Calidad",
		en: "Quality",
		fr: "Qualité"
	},
	SERVICE_RATING: {
		X: "SERVICE_RATING",
		es: "Servicio",
		en: "Service",
		fr: "Service"
	},
	VALUE_RATING: {
		X: "VALUE_RATING",
		es: "Valor",
		en: "Value",
		fr: "Valeur"
	},
	OUT_OF: {
		X: "OUT_OF",
		es: "de",
		en: "out of",
		fr: "sur"
	},
	ALLOW_CUSTOMER_VISITS: {
		X: "ALLOW_CUSTOMER_VISITS",
		es: "Se Permiten Visitas de Clientes",
		en: "Customer Visits Allowed",
		fr: "Autoriser les Visites Client"
	},
	CUSTOMER_VISITS_ALLOWED: {
		X: "CUSTOMER_VISITS_ALLOWED",
		es: "Se Permiten Visitas de Clientes",
		en: "Customer Visits Allowed",
		fr: "Autoriser les Visites Client"
	},
	NO_VISITORS_ALLOWED: {
		X: "NO_VISITORS_ALLOWED",
		es: "No se Permiten Visitas",
		en: "No Visitors Allowed",
		fr: "Pas de Visiteurs Autorisés"
	},
	PROVISIONAL: {
		X: "PROVISIONAL",
		es: "Provisional",
		en: "Provisional",
		fr: "Provisoire"
	},
	VIEW: {
		X: "VIEW",
		es: "Ver",
		en: "View",
		fr: "Vue"
	},
	DATE_OF_BIRTH_INPUT_MESSAGE: {
		X: "DATE_OF_BIRTH_INPUT_MESSAGE",
		es: "Su fecha de nacimiento se utiliza para validar su cuenta comparándola con la de su identificación oficial. " +
			"No podrá validar su cuenta sin antes proporcionar esta información.",
		en: "Your date of birth is used to validate your account by comparing it to that on your official ID. " +
			"You will not be able to validate your account without first providing this information.",
		fr: "Votre date de naissance sert à valider votre compte en le comparant à celui de votre pièce d'identité " +
			"officielle. Vous ne pourrez pas valider votre compte sans fournir au préalable ces informations."
	},
	ORG_JOB_TITLE_INPUT_MESSAGE: {
		X: "ORG_JOB_TITLE_INPUT_MESSAGE",
		es: "Proporcione su puesto de trabajo dentro de la organización.",
		en: "Please provide your job title within the organization.",
		fr: "Veuillez indiquer votre titre de poste au sein de l'organisation."
	},
	YOUR: {
		X: "YOUR",
		es: "Su",
		en: "Your",
		fr: "Votre"
	},
	CONTACT_INFO_FIELDS_VERIFY_MESSAGE_PT2: {
		X: "CONTACT_INFO_FIELDS_VERIFY_MESSAGE_PT2",
		es: " aún no se ha verificado. Complete la verificación presionando el botón de edición y volviendo a enviar el ",
		en: " has yet to be verified. Please complete verification by pressing the edit button, and re-submitting the ",
		fr: " n'a pas encore été vérifiée. Veuillez terminer la vérification en appuyant sur le bouton Modifier et en renvoyant l'"
	},
	CONTACT_INFO_FIELDS_VERIFY_MESSAGE_PT3: {
		X: "CONTACT_INFO_FIELDS_VERIFY_MESSAGE_PT3",
		es: ". Se le pedirá un código de verificación.",
		en: ". You will be prompted for a verification code.",
		fr: ". Vous serez invité à entrer un code de vérification."
	},
	CONTACT_INFO_FIELDS_LOGIN_EMAIL_MESSAGE: {
		X: "CONTACT_INFO_FIELDS_LOGIN_EMAIL_MESSAGE",
		es: "Esta dirección de correo electrónico también funciona como una alternativa a su nombre de usuario al iniciar sesión.",
		en: "This email address doubles as an alternative to your username when logging in.",
		fr: "Cette adresse e-mail sert également d'alternative à votre nom d'utilisateur lors de la connexion."
	},
	CONTACT_INFO_FIELDS_LOGIN_PHONE_MESSAGE: {
		X: "CONTACT_INFO_FIELDS_LOGIN_PHONE_MESSAGE",
		es: "Este número de teléfono funciona como una alternativa a su nombre de usuario al iniciar sesión.",
		en: "This phone number doubles as an alternative to your username when logging in.",
		fr: "Ce numéro de téléphone sert d'alternative à votre nom d'utilisateur lors de la connexion."
	},
	PRIMARY_EMAIL_FIELD_EMPTY: {
		X: "PRIMARY_EMAIL_FIELD_EMPTY",
		es: "Campo de Correo Electrónico Principal Vacío",
		en: "Primary Email Field Empty",
		fr: "Champ d'Adresse e-Mail Principal Vide"
	},
	PRIMARY_PHONE_FIELD_EMPTY: {
		X: "PRIMARY_PHONE_FIELD_EMPTY",
		es: "Campo de Teléfono Principal Vacío",
		en: "Primary Phone Field Empty",
		fr: "Champ de Téléphone Principal Vide"
	},
	RECOVERY_EMAIL_FIELD_EMPTY: {
		X: "RECOVERY_EMAIL_FIELD_EMPTY",
		es: "Campo de Correo Electrónico de Recuperación Vacío",
		en: "Recovery Email Field Empty",
		fr: "Champ d'Adresse e-Mail de Récupération Vide"
	},
	RECOVERY_PHONE_FIELD_EMPTY: {
		X: "RECOVERY_PHONE_FIELD_EMPTY",
		es: "Campo de Teléfono de Recuperación Vacío",
		en: "Recovery Phone Field Empty",
		fr: "Champ de Téléphone de Récupération Vide"
	},
	SUPPORT_EMAIL_FIELD_EMPTY: {
		X: "SUPPORT_EMAIL_FIELD_EMPTY",
		es: "Campo de Correo Electrónico de Soporte Vacío",
		en: "Support Email Field Empty",
		fr: "Champ d'Adresse e-Mail d'Assistance Vide"
	},
	SUPPORT_PHONE_FIELD_EMPTY: {
		X: "SUPPORT_PHONE_FIELD_EMPTY",
		es: "Campo de Teléfono de Soporte Vacío",
		en: "Support Phone Field Empty",
		fr: "Champ de Téléphone d'Assistance Vide"
	},
	WEBSITE_FIELD_EMPTY: {
		X: "WEBSITE_FIELD_EMPTY",
		es: "Campo de Sitio Web Vacío",
		en: "Website Field Empty",
		fr: "Champ de Site Web Vide"
	},
	LOGIN_EMAIL_CONFLICT: {
		X: "LOGIN_EMAIL_CONFLICT",
		es: "Conflicto de Correo Electrónico de Inicio de Sesión",
		en: "Login Email Conflict",
		fr: "Conflit d'e-Mail de Connexion"
	},
	LOGIN_EMAIL_CONFLICT_MESSAGE: {
		X: "LOGIN_EMAIL_CONFLICT_MESSAGE",
		es: "Esta dirección de correo electrónico ya sirve como correo electrónico de inicio de sesión para otra cuenta. " +
			"Aún puede configurarlo como su correo electrónico principal. Sin embargo, no podrá iniciar sesión en esta " +
			"cuenta con su correo electrónico. Puede iniciar sesión con su nombre de usuario. ¿Desea continuar?",
		en: "This email address already serves as the login email for another account. You may still set it as your " +
			"primary email. However, you will be unable to log in to this account using your email address. " +
			"You may log in using your username instead. Would you like to proceed?",
		fr: "Cette adresse e-mail sert déjà d'e-mail de connexion pour un autre compte. Vous pouvez toujours le définir " +
			"comme adresse e-mail principale. Cependant, vous ne pourrez pas vous connecter à ce compte en utilisant votre " +
			"adresse e-mail. Vous pouvez vous connecter en utilisant votre nom d'utilisateur à la place. Voulez vous procéder?"
	},
	LOGIN_PHONE_CONFLICT: {
		X: "LOGIN_PHONE_CONFLICT",
		es: "Conflicto de Número Telefónico de Inicio de Sesión",
		en: "Login Phone Conflict",
		fr: "Conflit de Téléphone de Connexion"
	},
	LOGIN_PHONE_CONFLICT_MESSAGE: {
		X: "LOGIN_PHONE_CONFLICT_MESSAGE",
		es: "Este número de teléfono ya sirve como teléfono de inicio de sesión para otra cuenta. Aún puede " +
			"configurarlo como su teléfono principal. Sin embargo, no podrá iniciar sesión en esta cuenta con su número " +
			"de teléfono. Puede iniciar sesión con su nombre de usuario. ¿Desea continuar?",
		en: "This phone number already serves as the login phone for another account. You may still set it as your " +
			"primary phone. However, you will be unable to log in to this account using your phone number. You may log " +
			"in using your username instead. Would you like to proceed?",
		fr: "Ce numéro de téléphone sert déjà de téléphone de connexion pour un autre compte. Vous pouvez toujours le " +
			"définir comme téléphone principal. Cependant, vous ne pourrez pas vous connecter à ce compte en utilisant " +
			"votre numéro de téléphone. Vous pouvez vous connecter en utilisant votre nom d'utilisateur à la place. " +
			"Voulez vous procéder?"
	},
	USERNAME_INFO_MESSAGE:{
		X: "USERNAME_INFO_MESSAGE",
		es: "Los nombres de usuario no distinguen entre mayúsculas y minúsculas. Solo se permiten letras, números, " +
			"guiones (-), guiones bajos (_) y puntos (.).",
		en: "Usernames are not case-sensitive. Only letters, numbers, dashes (-), underscores (_), and periods (.) " +
			"are allowed.",
		fr: "Les noms d'utilisateur ne sont pas sensibles à la casse. Seuls les lettres, les chiffres, les tirets (-), " +
			"les traits de soulignement (_) et les points (.) Sont autorisés."
	},
	LOGIN_PHONE_INFO_MESSAGE:{
		X: "LOGIN_PHONE_INFO_MESSAGE",
		es: "El número de teléfono debe comenzar con el código del país. Puede incluir los siguientes caracteres (+()-.) " +
			"y espacios, pero estos no son obligatorios. Ejemplo: +1 (222) 333-4444",
		en: "Phone number must start with the country code. You may include +()-. characters and spaces but these are " +
			"not required. Example: +1 (222) 333-4444",
		fr: "Le numéro de téléphone doit commencer par le code du pays. Vous pouvez inclure +()-. caractères et " +
			"espaces, mais ceux-ci ne sont pas obligatoires. Exemple: +1 (222) 333-4444"
	},
	LOGIN_EMAIL_INFO_MESSAGE:{
		X: "LOGIN_EMAIL_INFO_MESSAGE",
		es: "Los correos electrónicos no distinguen entre mayúsculas y minúsculas",
		en: "Emails are not case-sensitive",
		fr: "Les e-mails ne sont pas sensibles à la casse"
	},
	LOGIN_PASSWORD_INFO_MESSAGE:{
		X: "LOGIN_PASSWORD_INFO_MESSAGE",
		es: "Las contraseñas son sensibles a las mayúsculas. Deben tener al menos 8 caracteres. Se permiten letras, " +
			"números y los siguientes símbolos: -!. # $% & '* + / =? ^ _ `{|} ~ @",
		en: "Passwords are case-sensitive. They must be at least 8 characters long. Letters, numbers, and the following " +
			"symbols are allowed: -!.#$%&'*+/=?^_`{|}~@",
		fr: "Les mots de passe sont sensibles aux majuscules. Ils doivent comporter au moins 8 caractères. Les lettres, " +
			"les chiffres et les symboles suivants sont autorisés: -!. # $% & '* + / =? ^ _ `{|} ~ @"
	},
	FORGOT_USERNAME_MESSAGE:{
		X: "FORGOT_USERNAME_MESSAGE",
		es: "¿Olvidaste tu nombre de usuario? Inicie sesión con su correo electrónico de inicio de sesión o teléfono " +
			"de inicio de sesión utilizando el botón a la izquierda del campo de entrada de nombre de usuario.",
		en: "Did you forget your username? Please log in using your login email or login phone by using the button to " +
			"the left of the username input field.",
		fr: "Avez-vous oublié votre nom d'utilisateur? Veuillez vous connecter à l'aide de votre adresse e-mail de " +
			"connexion ou de votre téléphone de connexion en utilisant le bouton à gauche du champ de saisie du nom d'utilisateur."
	},
	FORGOT_PASSWORD:{
		X: "FORGOT_PASSWORD",
		es: "Olvidé mi contraseña",
		en: "I forgot my password",
		fr: "J'ai oublié mon mot de passe"
	},
	PASSWORD_RESET:{
		X: "PASSWORD_RESET",
		es: "Restablecimiento de Contraseña",
		en: "Password Reset",
		fr: "Réinitialisation du Mot de Passe"
	},
	PASSWORD_RESET_MESSAGE:{
		X: "PASSWORD_RESET_MESSAGE",
		es: "Ingrese su nueva contraseña a continuación.",
		en: "Please enter your new password below",
		fr: "S'il vous plaît entrez votre nouveau mot de passe ci-dessous"
	},
	PASSWORD_RESET_CODE_MESSAGE_PT1:{
		X: "PASSWORD_RESET_CODE_MESSAGE_PT1",
		es: "Se ha enviado un código de restablecimiento de contraseña a su ",
		en: "A password reset code has been sent to your ",
		fr: "Un code de réinitialisation du mot de passe a été envoyé à votre "
	},
	PASSWORD_RESET_CODE_MESSAGE_PT2:{
		X: "PASSWORD_RESET_CODE_MESSAGE_PT2",
		es: ". Ingrese el código a continuación",
		en: ". Please the code below",
		fr: ". Veuillez saisir le code ci-dessous."
	},
	ACCOUNT_CLOSED:{
		X: "ACCOUNT_CLOSED",
		es: "Cuenta Cerrada",
		en: "Account Closed",
		fr: "Compte Fermé"
	},
	ACCOUNT_DEACTIVATED:{
		X: "ACCOUNT_DEACTIVATED",
		es: "Cuenta Desactivada",
		en: "Account Deactivated",
		fr: "Compte Désactivé"
	},
	ACCOUNT_DISABLED:{
		X: "ACCOUNT_DISABLED",
		es: "Cuenta Deshabilitada",
		en: "Account Disabled",
		fr: "Compte Désactivé"
	},
	DISABLED:{
		X: "DISABLED",
		es: "Deshabilitado",
		en: "Disabled",
		fr: "Désactivé"
	},
	ENABLED:{
		X: "ENABLED",
		es: "Activado",
		en: "Enabled",
		fr: "Activé"
	},
	ACCOUNT_LOCKED:{
		X: "ACCOUNT_LOCKED",
		es: "Cuenta Bloqueada",
		en: "Account Locked",
		fr: "Compte Bloqué"
	},
	ACCOUNT_DEACTIVATED_MESSAGE: {
		X: "ACCOUNT_DEACTIVATED_MESSAGE",
		es: "La cuenta a la que está intentando acceder ha sido inhabilitada. Crea una cuenta nueva.",
		en: "The account you are trying to access has been disabled. Please create a new account.",
		fr: "Le compte auquel vous essayez d'accéder a été désactivé. Veuillez créer un nouveau compte."
	},
	NO_MATCH: {
		X: "NO_MATCH",
		es: "Sin Coincidencia",
		en: "No Match",
		fr: "Pas de Correspondance"
	},
	TOO_MANY_LOGIN_ATTEMPTS: {
		X: "TOO_MANY_LOGIN_ATTEMPTS",
		es: "Demasiados Intentos de Inicio de Sesión",
		en: "Too Many Login Attempts",
		fr: "Trop de Tentatives de Connexion"
	},
	ACCOUNT_CLOSED_MESSAGE: {
		X: "ACCOUNT_CLOSED_MESSAGE",
		es: "La cuenta a la que intenta acceder se ha cerrado de forma permanente.",
		en: "The account you are trying to access has been closed permanently.",
		fr: "Le compte auquel vous essayez d'accéder a été définitivement fermé."
	},
	ACCOUNT_DISABLED_MESSAGE: {
		X: "ACCOUNT_DISABLED_MESSAGE",
		es: "La cuenta a la que está intentando acceder ha sido inhabilitada. Póngase en contacto con nosotros en " +
			"support@paralian.io si desea habilitar la cuenta.",
		en: "The account you are trying to access has been disabled. Please contact us at support@paralian.io if you " +
			"wish to enable the account.",
		fr: "Le compte auquel vous essayez d'accéder a été désactivé. Veuillez nous contacter à support@paralian.io " +
			"si vous souhaitez activer le compte."
	},
	ACCOUNT_LOCKED_MESSAGE: {
		X: "ACCOUNT_LOCKED_MESSAGE",
		es: "Esta cuenta se ha bloqueado y, por lo tanto, solo disfrutará de una funcionalidad limitada. Puede " +
			"desbloquear la cuenta yendo a su perfil después de iniciar sesión.",
		en: "This account has been locked and will thus enjoy only limited functionality. You may unlock the account " +
			"by going to its profile after logging in.",
		fr: "Ce compte a été verrouillé et ne bénéficiera donc que de fonctionnalités limitées. Vous pouvez " +
			"déverrouiller le compte en accédant à son profil après vous être connecté."
	},
	USER_NOT_FOUND_MESSAGE: {
		X: "USER_NOT_FOUND_MESSAGE",
		es: "La información de inicio de sesión proporcionada no aparece en nuestros registros. Inténtalo de nuevo. " +
			"Puede iniciar sesión con su nombre de usuario, correo electrónico o número de teléfono.",
		en: "The login information provided does not match any on record. Please try again. You may log in using your " +
			"username, email or phone number.",
		fr: "Les informations de connexion fournies ne correspondent à aucune donnée enregistrée. Veuillez réessayer. " +
			"Vous pouvez vous connecter en utilisant votre nom d'utilisateur, votre e-mail ou votre numéro de téléphone."
	},
	TOO_MANY_LOGIN_ATTEMPTS_MESSAGE: {
		X: "TOO_MANY_LOGIN_ATTEMPTS_MESSAGE",
		es: "Ha superado el número máximo de intentos de inicio de sesión permitidos. Vuelva a intentarlo en cinco " +
			"minutos. Si olvidó su contraseña, vaya al enlace de Ayuda a continuación.",
		en: "You have exceeded the maximum allowed login attempts. Please try again in five minutes. If you have " +
			"forgotten your password, please go to the Help link below.",
		fr: "Vous avez dépassé le nombre maximal de tentatives de connexion autorisées. Veuillez réessayer dans cinq " +
			"minutes. Si vous avez oublié votre mot de passe, veuillez consulter le lien Aide ci-dessous."
	},
	LOGIN_ERROR_MESSAGE: {
		X: "LOGIN_ERROR_MESSAGE",
		es: "Se produjo un error al buscar su cuenta. Inténtalo de nuevo. Si el problema persiste, háganoslo " +
			"saber poniéndose en contacto con support@paralian.io",
		en: "An error occurred while searching for your account. Please try again. If the problem persists, please " +
			"let us know by contacting support@paralian.io",
		fr: "Une erreur s'est produite lors de la recherche de votre compte. Veuillez réessayer. Si le problème " +
			"persiste, veuillez nous en informer en contactant support@paralian.io"
	},
	NEW_USERNAME_MESSAGE: {
		X: "NEW_USERNAME_MESSAGE",
		es: "Proporcione un nombre de usuario para su cuenta.",
		en: "Please provide a username for your account.",
		fr: "Veuillez fournir un nom d'utilisateur pour votre compte."
	},
	NEW_LOGIN_EMAIL_MESSAGE: {
		X: "NEW_LOGIN_EMAIL_MESSAGE",
		es: "Proporcione un correo electrónico que servirá como correo electrónico de inicio de sesión para su cuenta.",
		en: "Please provide an email which will serve as the login email for your account.",
		fr: "Veuillez fournir un e-mail qui servira d'e-mail de connexion pour votre compte."
	},
	NEW_LOGIN_PASSWORD_MESSAGE: {
		X: "NEW_LOGIN_PASSWORD_MESSAGE",
		es: "Proporcione una contraseña para su cuenta.",
		en: "Please provide a password for your account.",
		fr: "Veuillez fournir un mot de passe pour votre compte."
	},
	NEW_ORG_ACCOUNT_DOCUMENTS_MESSAGE: {
		X: "NEW_ORG_ACCOUNT_DOCUMENTS_MESSAGE",
		es: "La mayoría de los puertos deportivos requieren que ciertos documentos estén disponibles como parte de " +
			"su proceso de aprobación de acceso. Estos pueden incluir certificados, fianzas, seguros y documentos de " +
			"registro, entre otros. Podrá cargarlos directamente a través de la aplicación desde el menú de organización " +
			"de su cuenta de administrador.",
		en: "Most marinas require certain documents to be made available as part of their access approval process. " +
			"These may include certificates, bond, insurance, and registration documents among others. You will be able " +
			"to upload these directly through the app from the organization menu of your admin account.",
		fr: "La plupart des marinas exigent que certains documents soient mis à disposition dans le cadre de leur " +
			"processus d'approbation d'accès. Ceux-ci peuvent inclure des certificats, des obligations, des assurances " +
			"et des documents d'enregistrement, entre autres. Vous pourrez les télécharger directement via l'application " +
			"à partir du menu d'organisation de votre compte administrateur."
	}
}

export default Dictionary