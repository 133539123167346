import React, {useState} from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {cancelOrderRecurrence, editOrder} from "../../../../../globals/functions/client/serverFunctions"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.cancel
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const CancelOrderButton = props => {
	const [disableButtons, setDisableButtons] = useState(false)
	return props.order.buttons.cancel ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"cancel"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.CANCEL_ORDER.X)}}	left={{icon: ICONS.TIMES_CIRCLE}}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}} disabled={disableButtons}
				onClick={async () => {
					try {
						const confirmed = window.confirm(translate(DICTIONARY.CANCEL_ORDER_CONFIRM.X))
						let recurrence = false, cancelOrderResponse = false, cancelRecurrenceResponse = false
						let cancelOrderSuccess = false, cancelRecurrenceSuccess = false
						if (props.order.buttons.cancelRecurrence) {
							recurrence = window.confirm(translate(DICTIONARY.CANCEL_ORDER_RECURRENCE_CONFIRM.X))
						}
						if (recurrence) {
							setDisableButtons(true)
							cancelRecurrenceResponse = await cancelOrderRecurrence({id: props.order.id})
							// console.log(response)
							setDisableButtons(false)
						}
						if (confirmed) {
							setDisableButtons(true)
							cancelOrderResponse = await editOrder(props.order.id, ORDER_BUTTON.CANCEL)
							// console.log(response)
							setDisableButtons(false)
						}
						cancelOrderSuccess = cancelOrderResponse.status === SERVER_RESPONSE_STATE.SUCCESS
						cancelRecurrenceSuccess = cancelRecurrenceResponse.status === SERVER_RESPONSE_STATE.SUCCESS
						if ((cancelOrderSuccess === confirmed) && (cancelRecurrenceSuccess === recurrence) && (confirmed || recurrence)) {
							props.refreshOrders()
							fadingMessage("cancel-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
						}
					} catch (e) {
						console.log(e)
						setDisableButtons(false)
					}
					// console.log("cancel")
				}}/>
		</div> : <></>
}

export default CancelOrderButton