import React, {useEffect, useState} from "react"
import ICONS from "../../../globals/constants/client/icons"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {useElements, useStripe} from "@stripe/react-stripe-js"
import {hideLoader, newMessage, showLoader, translate} from "../../../globals/functions/client/localFunctions"
import submitNewPaymentMethod from "./submitNewPaymentMethod"
import MobileInputCreditCard from "./MobileInputCreditCard"
import MobilePaymentMethodsDropdown from "../../shared/input/dropdown/MobilePaymentMethodsDropdown"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import Address from "../../../globals/classes/shared/Address"
//  * @param {function} props.setShow
/**
 *
 * @param {Object} props
 * @param {Account} props.parentObject
 * @param {Name} props.parentObject.name
 * @param {Address[]} props.parentObject.addresses
 * @param {number} props.amount
 * @param {string} props.currency - ISO-3 Code
 * @param {function} props.getSecret
 * @param {function} props.refreshAccount
 * @param {boolean} [props.confirmPayment = false]
 * @returns {JSX.Element}
 * @constructor
 */
const MobileStripeUpdateOrderPaymentMethod = props => {
	const elements = useElements()
	const stripe = useStripe()
	const [error, setError] = useState(null)
	const [cardComplete, setCardComplete] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [savePaymentMethod, setSavePaymentMethod] = useState(true)
	const [newPaymentMethod, setNewPaymentMethod] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState(null)
	const [billingDetails, setBillingDetails] = useState({name: props.parentObject ? props.isOrganization ?
			props.parentObject.name.display :	props.parentObject.contact.name.given : null})
	const [billingAddress, setBillingAddress] = useState(props.parentObject ?
		props.parentObject.addresses.asArray.length > 0 ?
			props.parentObject.addresses.asArray.find(address => address.isBilling) ||  new Address({id: -1}) :
			new Address({id: -1}) : new Address({id: -1}))
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		try {
			const ok = props.isOrganization ? props.parentObject.name.display : props.parentObject.contact.name.given
			setBillingDetails({name: ok ? ok : null})
		} catch (e) {
			setBillingDetails({name: null})
		}
	}, [props.parentObject, props.isOrganization])
	useEffect(() => {
		try {
			const ok = props.parentObject.addresses.asArray.length > 0
			setBillingAddress(ok ? props.parentObject.addresses.asArray.find(address => address.isBilling) ||
				new Address({id: -1}) : new Address({id: -1}))
		} catch (e) {
			setBillingAddress(new Address({id: -1}))
		}
	}, [props.parentObject])
	const onSubmit = async event => {
		console.log("MobileStripeUpdateOrderPaymentMethod handle submit")
		event.preventDefault()
		try {
			if (!stripe || !elements) {
				console.log("Stripe.js has not yet loaded")
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return
			}
			let paymentMethod = document.getElementById("payment-method-input").getAttribute("data-id")
			console.log("paymentMethod", paymentMethod)
			if (paymentMethod === "-1" && !error && cardComplete) {
				paymentMethod = await submitNewPaymentMethod(stripe, elements, setProcessing, billingAddress,	billingDetails)
			}
			if (!paymentMethod || paymentMethod === "-1") {return}
			const secret = await props.getSecret(paymentMethod)
			console.log("secret", secret)
			if (!secret) {
				setProcessing(false)
				return
			} else if (!props.confirmPayment || secret.clientSecret === "success") {
				props.refreshAccount().then()
				props.onSuccess()
				return
			}
			const data = {
				payment_method: secret.paymentMethod,
				receipt_email: props.isOrganization ?
					props.parentObject.contactInfo.emails.primary.address :
					props.parentObject.contact.contactInfo.emails.primary.address
			}
			console.log("data", data)
			if (savePaymentMethod) {data.setup_future_usage = "off_session"}
			showLoader()
			setProcessing(true)
			console.log("stripe", stripe)
			const result = await stripe.confirmCardPayment(secret.clientSecret, data)
			console.log("result", result)
			hideLoader()
			setProcessing(false)
			if (result.error) {
				console.log("result.error")
				if (result.error.code === "payment_intent_unexpected_state") {
					console.log("result.error.code === \"payment_intent_unexpected_state\"")
					if (result.error.payment_intent.status === "succeeded") {
						console.log("result.error.payment_intent.status === \"succeeded\"")
						props.refreshAccount().then()
						// props.setShow(false)
						return
					}
				}
				newMessage("paymentError", translate(DICTIONARY.PAYMENT_DECLINED.X),
					result.error.message + translate(DICTIONARY.PAYMENT_DECLINED_MESSAGE.X),
					"w3-pale-yellow", ICONS.CREDIT_CARD)
			} else if (result.paymentIntent.status === 'succeeded') {
				console.log("result.paymentIntent.status === 'succeeded'")
				// fadingMessage("paymentSuccess", "Success", "Your payment has been processed successfully.",
				// 	"w3-pale-blue", ICONS.CREDIT_CARD)
				props.refreshAccount().then()
				// props.setShow(false)
				props.onSuccess()
			} else if (result.paymentIntent.status === 'requires_capture') {
				console.log("result.paymentIntent.status === 'requires_capture'")
				// fadingMessage("paymentSuccess", "Success", "Your funds are now on hold and will be captured once " +
				// 	"the provider confirms your order.", "w3-pale-blue", ICONS.CREDIT_CARD)
				props.refreshAccount().then()
				// props.setShow(false)
				props.onSuccess()
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = newPaymentMethod ?
		<MobileInputCreditCard setCardComplete={setCardComplete} setError={setError} setBillingDetails={setBillingDetails}
			setBillingAddress={setBillingAddress} setSavePaymentMethod={props.hideSaveOption ? null : setSavePaymentMethod}
			parentObject={props.parentObject} className={"paralian-theme-level-1"} form={"direct-cc-pm-update-form"}/> : <></>
	const content_ = <div>
		<div style={{padding: "2vh"}}>
			<MobilePaymentMethodsDropdown id={"payment-method"} setPaymentMethod={setPaymentMethod} defaultValue={paymentMethod}
				setNewPaymentMethod={setNewPaymentMethod} newPaymentMethod={newPaymentMethod} showLabel={true}
				exclude={props.excludedPaymentMethod}	form={"direct-cc-pm-update-form"}	parentObject={props.parentObject}
				isOrganization={props.isOrganization} topRadius={"1vh"} bottomRadius={"1vh"}/>
		</div>
		{content}
		<div className={"w3-center"} style={{width: "100%"}}>
			<div style={{padding: "2vh"}}>
				<MobileButtonGeneric id={"submit"} type={"submit"} form={"direct-cc-pm-update-form"} padding={"2vh"}
					text={{content: translate(DICTIONARY.SUBMIT.X)}} style={{width: "100%", borderRadius: "1vh"}}
					disabled={processing || disabled}	className={"paralian-theme-level-1"}/>
			</div>
		</div>
	</div>

	return <MobileInputForm id={"direct-cc-pm-update"} content={content_} className={" "} hideButton={true}
		disabled={processing || disabled} onSubmit={onSubmit}/>
}

export default MobileStripeUpdateOrderPaymentMethod