import React, {useState} from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import MobileOrderAdjustmentsCard from "../../../../../orders/MobileOrderAdjustmentsCard"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
import Modal from "../../../../../globals/components/Modal"
import MobileAdjustRespondAcceptModal from "./MobileAdjustRespondAcceptModal"
/**
 *
 * @param {Object} props
 * @param {function} props.setShowAdjustRespondModal
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {IndexedArray} props.services
 * @param {boolean} props.showAdjustRespondModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const MobileAdjustRespondModal = props => {
	const [disableButtons, setDisableButtons] = useState(false)
	const [showAdjustRespondAcceptModal, setShowAdjustRespondAcceptModal] = useState(false)
	return props.showAdjustRespondModal ? <Modal title={translate(DICTIONARY.RESPOND_TO_PROPOSED_CHANGES.X)} icon={ICONS.REPLY}
		onClick={() => props.setShowAdjustRespondModal(false)} id={`order-${props.order.id}-adjust-modal`} padding={"0vh"}
		body={<div style={{padding: "0vh 2vh"}}>
			{showAdjustRespondAcceptModal ? <MobileAdjustRespondAcceptModal order={props.order} account={props.account} currency={props.currency}
			setShowAdjustRespondModal={props.setShowAdjustRespondModal} refreshAccount={props.refreshAccount}
			showAdjustRespondAcceptModal={showAdjustRespondAcceptModal} refreshOrders={props.refreshOrders}
			setShowAdjustRespondAcceptModal={setShowAdjustRespondAcceptModal} services={props.services}
			organization={props.organization}/> : <></>}
		<MobileOrderAdjustmentsCard order={props.order}	indexedServices={props.services}/>
		<MobileButtonGeneric id={"submit-adjust-respond-accept"} type={"button"}
			className={"paralian-theme-level-1-flipped"} disabled={disableButtons}
		 	padding={"1vh"} style={{width: "100%", margin: "1vh 0vh", borderRadius: "1vh"}}
			left={{icon: ICONS.CHECK, label: translate(DICTIONARY.ACCEPT_CHANGES.X)}}
			text={{content: translate(DICTIONARY.ACCEPT_CHANGES.X)}}
		 	onClick={async () => {
				try {
					if (props.order.adjustments.items.length > 0) {
						setShowAdjustRespondAcceptModal(true)
					} else {
						const confirmed = window.confirm(translate(DICTIONARY.ACCEPT_CHANGES_CONFIRM.X))
						if (confirmed) {
							setDisableButtons(true)
							const response = await editOrder(props.order.id, ORDER_BUTTON.ADJUST_RESPOND_ACCEPT)
							// console.log(response)
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								props.refreshOrders()
								props.setShowAdjustRespondModal(false)
								fadingMessage("reject-changes-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
							}
							setDisableButtons(false)
						}
					}
				} catch (e) {
					console.log(e)
					setDisableButtons(false)
				}}}/>
		<MobileButtonGeneric id={"submit-adjust-respond-reject"} type={"button"} className={"paralian-theme-level-1-flipped"}
			padding={"1vh"} style={{width: "100%", margin: "1vh 0vh", borderRadius: "1vh"}} text={{content: translate(DICTIONARY.DECLINE_CHANGES.X)}}
			disabled={disableButtons} left={{icon: ICONS.BAN, label: translate(DICTIONARY.DECLINE_CHANGES.X)}}
			onClick={async () => {
			try {
				const confirmed = window.confirm(translate(DICTIONARY.DECLINE_CHANGES_CONFIRM.X))
				if (confirmed) {
					setDisableButtons(true)
					const response = await editOrder(props.order.id, ORDER_BUTTON.ADJUST_RESPOND_REJECT)
					// console.log(response)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.refreshOrders()
						props.setShowAdjustRespondModal(false)
						fadingMessage("reject-changes-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
					}
					setDisableButtons(false)
				}

			} catch (e) {
				console.log(e)
				setDisableButtons(false)
			}
	}}/></div>}/> :<></>
}

export default MobileAdjustRespondModal