import React, {useRef} from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {
	ORDER_BUTTON,
	ORDER_CHARGE_STATE,
	SERVER_RESPONSE_STATE
} from "../../../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import {Elements} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js/pure"
import Modal from "../../../../../globals/components/Modal"
import MobileStripeUpdateOrderPaymentMethod from "../../../payment/MobileStripeUpdateOrderPaymentMethod"
import StripeElementWrapper from "../../../payment/StripeElementWrapper"

/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.provider
 * @param {string} props.order.provider.connectId
 * @param {IndexedArray} props.services
 * @param {Organization} props.organization
 * @param {Account} props.account
 * @param {Currency} props.currency
 * @param {boolean} props.showChangePaymentMethodModal
 * @param {function} props.refreshOrders
 * @param {function} props.refreshAccount
 * @param {function} props.setShowChangePaymentMethodModal
 * @return {JSX.Element}
 * @constructor
 */
const ChangePaymentMethodModal = props => {
	const stripePromise = useRef(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
	const getSecret = async paymentMethod => {
		try {
			const response = await editOrder(props.order.id, props.order.buttons.pay ?
					ORDER_BUTTON.PAY : ORDER_BUTTON.CHANGE_PAYMENT_METHOD,
				{paymentMethod: paymentMethod})
			console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				return response.payload
			}
			return null
		} catch (e) {
			console.log(e)
			return null
		}
	}
	// TODO: set confirmPayment to none only in case of recurrences
	// excludedPaymentMethod={props.order.buttons.pay ? null : props.order.paymentMethod}
	// confirmPayment={props.order.buttons.pay && order.charge !== ORDER_CHARGE_STATE.HELD}
	const body = !props.showChangePaymentMethodModal ? <></> :
		<div style={{margin: "2vh", padding: "2vh 0vh", borderRadius: "1vh"}} className={"paralian-theme-level-2"}>
			<StripeElementWrapper element={
				<MobileStripeUpdateOrderPaymentMethod parentObject={props.account} currency={props.currency.code}
					confirmPayment={true} hideSaveOption={false}
					onSuccess={() => {
						props.refreshOrders()
						props.setShowChangePaymentMethodModal(false)
						fadingMessage("payment-method-update-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
				}} excludedPaymentMethod={null}
					refreshAccount={props.refreshAccount} isOrganization={false} getSecret={getSecret}/>
			} connectId={props.order.provider.connectId}/>
		</div>
	return props.showChangePaymentMethodModal ?
		<Modal id={`order-${props.order.id}-payment-method-change-modal`} padding={"0vh"}
			title={translate(DICTIONARY.PAYMENT_METHOD.X)} icon={ICONS.CREDIT_CARD}
			onClick={() => props.setShowChangePaymentMethodModal(false)} body={body}/> : <></>
}

export default ChangePaymentMethodModal