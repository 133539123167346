import React, {useMemo, useState} from "react"
import {DICTIONARY, TOOLS_DATA, ICONS} from "../../globals/constants/client/constants"
import {getMarinaIndex, getVesselIndex, getVesselOwnership, translate} from "../../globals/functions/client/localFunctions"
import ImageFramePlayer from "../../tools/ImageFramePlayer"
import TextForecast from "../../tools/TextForecast"
import MobileZoomScrollIcons from "../shared/input/scroll/MobileZoomScrollIcons"
import Modal from "../../globals/components/Modal"
import Vessel from "../../globals/classes/shared/Vessel"
import Organization from "../../globals/classes/shared/Organization"
import MobileFontAwesome from "./MobileFontAwesome"

const MobileTools = props => {
	const [tool, setTool] = useState(null)
	const [showScroll, setShowScroll] = useState(false)
	const TOOLS = Object.freeze({
		/*
		weatherMap: {
			id: "weatherMap",
			icon: ICONS.GLOBE,
			onClick: () => setTool("weatherMap"),
			text: DICTIONARY.WEATHER_MAP.X,
			initialConditions: {
				iconFontSize: "20.288vh",
				opacity: 0.989989,
				textFontSize: "3.9532vh",
				textWidth: "20vh"
			}
		},
		 */
		aisMap: {
			id: "aisMap",
			icon: ICONS.LOCATION_ARROW,
			onClick: () => setTool("aisMap"),
			text: DICTIONARY.AIS_MAP.X,
			initialConditions: {
				iconFontSize: "20.288vhh",
				opacity: 0.989989,
				textFontSize: "3.9532vh",
				textWidth: "20vh"
			}
		},
		windMap: {
			id: "windMap",
			icon: ICONS.WIND,
			onClick: () => setTool("windMap"),
			text: DICTIONARY.WIND_MAP.X,
			initialConditions: {
				iconFontSize: "3.885vh",
				opacity: 0.289624,
				textFontSize: "1.43275vh",
				textWidth: "3.8vh"
			}
		},
		waveMap: {
			id: "waveMap",
			icon: ICONS.WATER,
			onClick: () => setTool("waveMap"),
			text: DICTIONARY.WAVE_MAP.X,
			initialConditions: {
				iconFontSize: "3.885vh",
				opacity: 0.289624,
				textFontSize: "1.43275vh",
				textWidth: "3.8vh"
			}
		},
		/*
		chart: {
			id: "chart",
			icon: ICONS.MAP_MARKED_ALT,
			onClick: () => setTool("chart"),
			text: DICTIONARY.CHART.X,
			initialConditions: {
				iconFontSize: "3.885vh",
				opacity: 0.289624,
				textFontSize: "1.43275vh",
				textWidth: "3.8vh"
			}
		},
		 */
		textForecast: {
			id: "textForecast",
			icon: ICONS.FILE_ALT,
			onClick: () => setTool("textForecast"),
			text: DICTIONARY.TEXT_FORECAST.X,
			initialConditions: {
				iconFontSize: "3.885vh",
				opacity: 0.289624,
				textFontSize: "1.43275vh",
				textWidth: "3.8vh"
			}
		}
	})
	const vessel = useMemo(() => {
		const vesselIndex = getVesselIndex()
		return new Vessel(getVesselOwnership() === "customer" ?
			props.vessels.customer[vesselIndex] : props.vessels.owned[vesselIndex])
	}, [props.vessels])
	const marina = useMemo(() => new Organization(props.marinas[getMarinaIndex()]), [props.marinas])
	// console.log(location)
	let content, location // , window
	if (vessel.ais.coordinates.latitude) {
		location = vessel.ais.coordinates
	} else if (props.organization.coordinates.latitude) {
		location = props.organization.coordinates
	} else if (marina.coordinates.latitude) {
		location = marina.coordinates
	} else {
		location = {latitude: 18.43, longitude: -66.2}
	}
	switch (tool) {
		case "weatherMap":
			// window = PAGE_STATES[levelOne].MAIN.TOOLS.WEATHER_MAP.X
			// console.log(location)
			content =
				<div style={{margin: "2vh", overflowY:"hidden"}}>
					<iframe src={`https://digital.weather.gov/mobile/index.php`} referrerPolicy={"no-referrer"}
						style={{width: "100%", height:"60vh", border: "none"}} title={"weatherMap"}
						// https://nowcoast.noaa.gov/mariner.html
						// src={`https://tidesandcurrents.noaa.gov/map/index.shtml?lat=${store.getState().currentCoordinates.latitude}&lng=${store.getState().currentCoordinates.longitude}&zoom=8&type=PreliminaryData`}
					/>
				</div>
			break
		case "aisMap":
			// window = PAGE_STATES[levelOne].MAIN.TOOLS.AIS_MAP.X
			content =
				<div style={{margin: "2vh", overflowY: "hidden"}}>
					<iframe referrerPolicy={"no-referrer"} title={"aisMap"} style={{width: "100%", height: "60vh", border: "none"}}
						src={
							vessel.ais.mmsi ?
								`https://www.marinetraffic.com/en/ais/embed/mmsi:${vessel.ais.mmsi}/zoom:12` :
								`https://www.marinetraffic.com/en/ais/embed/centerx:${location.longitude}/centery:${location.latitude}/zoom:12`
						}/>
				</div>
			break
		case "windMap":
			// window = PAGE_STATES[levelOne].MAIN.TOOLS.WIND_MAP.X
			content = <ImageFramePlayer {...TOOLS_DATA.WIND_FRAME_DATA}/>
			break
		case "waveMap":
			// window = PAGE_STATES[levelOne].MAIN.TOOLS.WAVE_MAP.X
			content = <ImageFramePlayer {...TOOLS_DATA.WAVE_FRAME_DATA}/>
			break
		case "chart":
			// window = PAGE_STATES[levelOne].MAIN.TOOLS.CHART.X
			content =
				<div style={{margin: "2vh", overflowY:"hidden"}}>
					<iframe referrerPolicy={"no-referrer"} title={"chart"} style={{width: "100%", height:"60vh", border: "none"}}
						src={"https://webapp.navionics.com/"}/>
				</div>
			break
		case "textForecast":
			// window = PAGE_STATES[levelOne].MAIN.TOOLS.TEXT_FORECAST.X
			content = <TextForecast location={location}/>
			break
		default:
			content = <></>
	}
	const modal = tool ? <Modal id={"tools-modal"} icon={TOOLS[tool].icon} title={translate(TOOLS[tool].text)}
		onClick={() => setTool(false)} body={content} padding={"0vh"}/> : <></>
	return <>
		{modal}
		<MobileZoomScrollIcons items={Object.values(TOOLS)} scroll={8} setShowScroll={setShowScroll}/>
		{showScroll ? <div className={"w3-display-bottommiddle"} style={{marginBottom: "22.5vh", fontSize: "5vh", opacity: "0.6"}}>
			<MobileFontAwesome icon={ICONS.SCROLL}/>
		</div> : <></>}
	</>
}

export default MobileTools